import { useIonModal } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Language, Status } from '../../../../constants/constants';
import useToast from '../../../../hooks/useToast';
import { editPerson, EditPersonInput, Person } from '../../peopleApi';
import EditPersonDialog from './EditPersonDialog';

const placeholderPerson: Person = {
  userId: '-1',
  firstName: '',
  lastName: '',
  email: '',
  language: Language.ENGLISH,
  status: Status.ACTIVE,
  isRegistered: false,
  groups: [],
};

const useEditPersonDialog = (refreshData: () => void) => {
  const [currentPersonToEdit, setCurrentPersonToEdit] = useState<Person>(placeholderPerson);
  const { t } = useTranslation();
  const [presentToast] = useToast();

  const dismissModal = () => {
    dismiss();
    setCurrentPersonToEdit(placeholderPerson);
  };
  const savePerson = async (updatedPerson: Person) => {
    sendEditedPersonToServer(updatedPerson).catch(() => console.log('Failed to edit person.'));
    dismissModal();
  };
  const [present, dismiss] = useIonModal(EditPersonDialog, {
    dismiss: dismissModal,
    person: currentPersonToEdit,
    refreshData,
    savePerson,
  });

  useEffect(() => {
    if (currentPersonToEdit !== placeholderPerson) {
      present({
        onWillDismiss: dismissModal,
      });
    }
  }, [currentPersonToEdit]);

  const sendEditedPersonToServer = async ({ userId, firstName, lastName, email, language, status }: Person) => {
    try {
      const editedPerson: EditPersonInput = {
        userId,
        firstName,
        lastName,
        email,
        language,
        status,
      };
      const updatedPerson = await editPerson(editedPerson);
      if (updatedPerson) {
        refreshData();
        setTimeout(() => {
          presentToast(t('feedback.person.edited'), 5000, 'bottom', 'dark');
        }, 1000);
      }
    } catch (error) {
      console.error(error);
      presentToast('An error occurred when trying to edit the user. Please try again.', 6000, 'top', 'error');
    }
  };

  return {
    setCurrentPersonToEdit,
  };
};

export default useEditPersonDialog;
