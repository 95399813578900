import { generateClient, GraphQLResult } from 'aws-amplify/api';
import { getIDTokenAsString, isCurrentUserAuthenticated } from '@otuvy/auth';

export enum GraphqlCustomErrorType {
  RequestTooLarge,
  ResponseTooLarge,
  Timeout,
}

export class GraphqlCustomError extends Error {
  errorType: GraphqlCustomErrorType;

  constructor(errorType: GraphqlCustomErrorType, message: any) {
    super(message);
    this.name = this.constructor.name;
    this.errorType = errorType;
  }
}

const client = generateClient();

export const graphql = async <T>(query: string, variables?: object, targetLanguage?: string): Promise<T> => {
  try {
    const isUserAuthenticated: boolean = await isCurrentUserAuthenticated();
    if (!isUserAuthenticated) {
      throw new Error('No authenticated user');
    }

    const idToken: string | undefined = await getIDTokenAsString();
    if (!idToken) {
      throw new Error('No ID Token');
    }

    targetLanguage = targetLanguage ?? 'en';

    const result = (await client.graphql({ query, variables }, { id: idToken, targetLanguage })) as GraphQLResult;

    if (!result.data) {
      throw new Error('No data returned from query');
    }
    const data = result.data as any;
    const keys = Object.keys(data);
    return data[keys[0]]; // This only works if there is only one query/mutation included in the query
  } catch (response: any) {
    if (response && response.errors) {
      if (response.errors.length) {
        response.errors.forEach((error: any, index: number) => {
          console.warn('Handling GraphQL error', index, error);
          //TODO: handle different errors differently on the front end.  Possibly by taking an errorHandler mapping (errorType -> callback)
          switch (error.errorType) {
            case 'ValidationError':
              //Validating the data encountered an error
              console.warn('Encountered a validation error', error);
              break;
            case 'PreemptiveTimeoutError':
              //We cut the call short due to taking too long to process
              console.warn(
                'Encountered a PreemptiveTimeoutError error (we cut the call short due to taking too long to process)',
                error
              );
              break;
            case 'InactiveUserError':
              //calling user is inactive
              console.warn('Encountered an InactiveUserError error', error);
              break;
            case 'InactiveOrgError':
              //calling organization is inactive
              console.warn('Encountered an InactiveOrgError error', error);
              break;
            case 'Lambda:IllegalArgument':
              if (error.message?.includes('Request must be smaller')) {
                throw new GraphqlCustomError(GraphqlCustomErrorType.RequestTooLarge, 'Request was too large');
              }
              console.warn('Encountered an IllegalArgument error', error);
              break;
            case 'Runtime.ExitError':
              if (error.message?.includes('Runtime exited with error: signal: killed')) {
                console.warn(
                  'Encountered a Runtime.ExitError error in which the runtime exited with a kill signal. This could mean that the Lambda ran out of memory.',
                  error
                );
              } else {
                console.warn('Encountered a Runtime.ExitError error', error);
              }
              break;
            case 'Function.ResponseSizeTooLarge':
              throw new GraphqlCustomError(GraphqlCustomErrorType.ResponseTooLarge, 'Response was too large');
            case 'Unhandled':
              if (error.message?.includes('Task timed out')) {
                throw new GraphqlCustomError(GraphqlCustomErrorType.Timeout, 'The GraphQL call timed out');
              } else {
                console.warn('Encountered an Unhandled error ("Unhandled" is the actual value in errorType)', error);
              }
              break;
            case 'Error':
              //Check the Lambda logs to determine what these are
              console.warn('Encountered a generic error.  Please check the server logs', error);
              break;
            default:
              console.warn(`Encountered an unhandled error type [${index}]`, error);
              break;
          }
        });
      } else {
        console.error('Errors in GraphQL query (unknown length)', response.errors);
      }
    } else {
      console.error('Errors in GraphQL query\nquery:', query, '\nvariables:', variables, '\nerrors:', response.errors);
    }
    throw new Error('GraphQL query failed');
  }
};

export async function streamToString(stream: ReadableStream | null): Promise<string> {
  if (!stream) {
    return '';
  }
  const reader = stream.getReader();
  const decoder = new TextDecoder();
  let result = '';
  while (true) {
    const { done, value } = await reader.read();
    if (done) {
      break;
    }
    result += decoder.decode(value, { stream: true });
  }
  return result;
}
