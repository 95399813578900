import {
  useIonAlert,
  IonPopover,
  IonHeader,
  IonLabel,
  IonSearchbar,
  IonContent,
  IonItem,
  IonText,
  IonFooter,
  IonButton,
} from '@ionic/react';
import { useState, useRef } from 'react';
import { Person } from '../../../people/peopleApi';

interface IntegrationsLocationsGridDefaultOwnerPickerProps {
  trigger: string;
  people: Person[];
  defaultListOwner: string | undefined;
  onApplyHandler: (personId: string) => void;
}

const IntegrationsLocationsGridDefaultOwnerPicker: React.FC<IntegrationsLocationsGridDefaultOwnerPickerProps> = ({
  trigger,
  people,
  defaultListOwner,
  onApplyHandler,
}) => {
  const [selectedPersonId, setSelectedPersonId] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>('');

  const [presentAlert] = useIonAlert();

  const popoverRef = useRef<HTMLIonPopoverElement>(null);

  //TODO: Wrap this in a useMemo to prevent it from being recalculated on every render
  //TODO: Also do the same for the PersonPickerSearch component
  const filteredPeople = people?.filter((person) => {
    const fullName = `${person.firstName} ${person.lastName}`;
    return fullName.toLowerCase().includes(searchQuery.toLowerCase());
  });

  const onItemClickedHandler = (personId: string) => {
    // TODO: Refactor to avoid if else statement
    if (selectedPersonId === personId) {
      setSelectedPersonId(null);
    } else {
      setSelectedPersonId(personId);
    }
  };

  // Not a big fan of this, but it's a quick fix for now
  // This is to make sure the default owner is always at the top of the list
  // I tried doing this in the return JSX but it was causing issues with the popover making the scrollbar appear
  // Tried multiple things but no luck
  // Things i've tried:
  // - Adding the ion-item with the default owner prop to the header of the popover
  // - Adding the ion-item with the default owner prop to the top of the filteredPeople array
  // - Wrapping all the items in the ion-content with an ion-list
  // - Forcing a fix width on the popover to avoid the overflow that was causing the scrollbar to appear
  // - Multiple CSS fixes to try to avoid the overflow when adding an item outside of the filteredPeople array
  // This might probably be cause by some wierd shadow dom behavior...
  if (defaultListOwner) {
    const defaultOwner = people?.find((person) => person.userId === defaultListOwner);
    filteredPeople?.unshift(defaultOwner as Person);
  }

  return (
    <IonPopover
      ref={popoverRef}
      className="ion-select-search__popover"
      trigger={trigger}
      showBackdrop={false}
      side="right"
      style={{ '--max-height': '400px' }}
    >
      <IonHeader className="ion-no-border">
        <div className="ion-text-center" style={{ padding: '8px' }}>
          <IonLabel color="medium">Default List Owner</IonLabel>
        </div>
        <IonSearchbar
          mode="ios"
          className="ion-select-search__popover-searchbar"
          searchIcon={undefined}
          value={searchQuery}
          onIonChange={(e) => setSearchQuery(e.detail.value!)}
        />
      </IonHeader>
      <IonContent className="ion-select-search__popover-content" style={{ maxHeight: '200px' }}>
        {/* <div> */}
        {filteredPeople.map((person) => (
          <IonItem
            key={person.userId}
            lines="none"
            style={{
              '--background': selectedPersonId === person.userId ? '#B3D3C7' : 'var(--ion-color-light-tint)',
              '--color': selectedPersonId === person.userId ? 'var(--ion-color-dark)' : 'var(--ion-color-dark-tint)',
              '--border-radius': '8px',
              margin: '8px',
              cursor: 'pointer',
            }}
            onClick={() => {
              onItemClickedHandler(person.userId);
            }}
          >
            <IonLabel>
              {person.userId === defaultListOwner ? (
                <>
                  Org. Default{' '}
                  <IonText color="light">
                    {person.firstName} {person.lastName}
                  </IonText>
                </>
              ) : (
                <>
                  {person.firstName} {person.lastName}
                </>
              )}
            </IonLabel>
          </IonItem>
        ))}
        {/* </div> */}
      </IonContent>
      <IonFooter className="ion-no-border" style={{ borderTop: '1px solid var(--ion-color-light)' }}>
        <div className="d-flex ion-justify-content-center">
          <IonButton
            fill="clear"
            onClick={() => {
              presentAlert({
                header: 'Default List Owner Updated',
                message: 'Are you sure you want to update the default list owner for the selected locations?',
                buttons: [
                  {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'alert-button-cancel',
                  },
                  {
                    text: 'Update',
                    handler: () => {
                      onApplyHandler(selectedPersonId!);
                      popoverRef.current?.dismiss();
                    },
                  },
                ],
              });
            }}
            disabled={selectedPersonId !== null ? false : true}
          >
            <IonText className="font-18 font-weight-600">Apply</IonText>
          </IonButton>
        </div>
      </IonFooter>
    </IonPopover>
  );
};

export default IntegrationsLocationsGridDefaultOwnerPicker;
