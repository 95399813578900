import { graphql } from '../../utils/api/apiUtils';

export interface Organization {
  organization_id: string;
  name: string;
  logoImage: string | null;
  isTest: boolean;
  isPaid: boolean;
  stripeCustomerId: string | null;
  onboardingComplete: boolean | null;
  phoneNumber: string | null;
  autoArchiveThreshold: number | null;
}

export const getOrganization = async (): Promise<Organization> => {
  const query = `
    query getOrganizationForUserQuery {
      getOrganizationForUser {
        organization_id,
        name,
        logoImage,
        isTest,
        isPaid,
        stripeCustomerId,
        onboardingComplete,
        phoneNumber,
        autoArchiveThreshold
      }
    }
  `;

  const organization = await graphql<Organization>(query);
  return organization;
};

export interface UpdateOrganizationInfoProps {
  organization_id: string;
  name: string;
  phoneNumber: string;
  numEmployees: number;
  serviceArea: string;
  industry: string;
}

export const updateOrganizationInfo = async (props: UpdateOrganizationInfoProps): Promise<Organization> => {
  const updateOrganizationInfoMutation = `
    mutation updateOrganizationInfoMutation(
        $name: String!
        $phoneNumber: String!
        $numEmployees: Int!
        $serviceArea: String!
        $industry: String!
        $organization_id: ID!
    ){
      updateOrganizationInfo(
        input:{
            name: $name
            phoneNumber: $phoneNumber
            numEmployees: $numEmployees
            serviceArea: $serviceArea
            industry: $industry
            organization_id: $organization_id
          }
      ) 
        {
          organization_id,
          name,
          onboardingComplete,
          phoneNumber     
      }        
    }
  `;

  const organization = await graphql<Organization>(updateOrganizationInfoMutation, props);
  return organization;
};

export interface UpdateOrganizationAutoArchiveThresholdProps {
  organization_id: string | undefined;
  autoArchiveThreshold: number;
}

export const updateOrganizationAutoArchiveThreshold = async (
  props: UpdateOrganizationAutoArchiveThresholdProps
): Promise<Organization> => {
  const updateOrganizationAutoArchiveThresholdMutation = `
    mutation updateOrganization(
        $organization_id: ID!
        $autoArchiveThreshold: Int!
    ){
      updateOrganization(
        input:{
            organization_id: $organization_id
            autoArchiveThreshold: $autoArchiveThreshold
          }
      ) 
        {
          organization_id,
          name,
          autoArchiveThreshold     
      }        
    }
  `;

  const organization = await graphql<Organization>(updateOrganizationAutoArchiveThresholdMutation, props);
  return organization;
};
