import { STRIPE_API_BASE_URL } from '../../aws-exports';
import { getIDTokenAsString } from '@otuvy/auth';

export const getStripeConsoleSession = (checkoutSessionId: string | null) => {
  // TODO: Should we check for null and reject?
  var key = encodeURIComponent('session_id');
  var val = encodeURIComponent(checkoutSessionId!);
  const param = key + '=' + val;
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    body: param,
  };

  const url = STRIPE_API_BASE_URL + 'create-portal-session';
  return fetch(url, requestOptions).then((response) => response.json());
};

export const getStripeCompletedCheckoutSession = (checkoutSessionId: string | null) => {
  // TODO: Should we check for null and reject?

  var key = encodeURIComponent('session_id');
  var val = encodeURIComponent(checkoutSessionId!);
  const param = key + '=' + val;
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    body: param,
  };

  const url = STRIPE_API_BASE_URL + 'session';
  return fetch(url, requestOptions).then((response) => response.json());
};

export const createPortalSession = async (customerId: string | undefined | null) => {
  // The link is just broken if there's no customer ID.
  if (!customerId) return;

  const idToken: string | undefined = await getIDTokenAsString();
  if (!idToken) {
    throw new Error('No ID Token');
  }

  var key = encodeURIComponent('customer_id');
  var val = encodeURIComponent(customerId!);
  const param = key + '=' + val;
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      Id: idToken,
    },
    body: param,
  };

  const url = STRIPE_API_BASE_URL + 'create-portal-session';
  return fetch(url, requestOptions).then((response) => response.json());
};

export const getStripeSubscription = (subscriptionId: string | null) => {
  var key = encodeURIComponent('subscription');
  var val = encodeURIComponent(subscriptionId!);
  const param = key + '=' + val;
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    body: param,
  };

  const url = STRIPE_API_BASE_URL + 'subscription';
  return fetch(url, requestOptions).then((response) => response.json());
};

// export const updateStripeSubscription = (subscriptionId: string | null, quantityToAdd: number) => {
//   var key1 = encodeURIComponent('subscription');
//   var val1 = encodeURIComponent(subscriptionId!);
//   const param1 = key1 + '=' + val1;
//   var key2 = encodeURIComponent('quantityToAdd');
//   var val2 = encodeURIComponent(quantityToAdd!);
//   const param2 = key2 + '=' + val2;
//   const params = param1 + '&' + param2;
//   const requestOptions = {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
//     },
//     body: params,
//   };

//   const url = STRIPE_API_BASE_URL + 'subscription-item';
//   return fetch(url, requestOptions).then((response) => response.json());
// };

export const updateStripeSubscription = (customerId: string | null) => {
  if (customerId === null) return;

  var key1 = encodeURIComponent('customerId');
  var val1 = encodeURIComponent(customerId!);
  const params = key1 + '=' + val1;

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    body: params,
  };

  const url = STRIPE_API_BASE_URL + 'subscription-item';
  return fetch(url, requestOptions).then((response) => response.json());
};
