import './ListImportInstructionsPanel.css';
import { IonButton, IonCol, IonGrid, IonIcon, IonImg, IonRow } from '@ionic/react';
import { documentOutline, arrowForwardOutline, cloudUploadOutline } from 'ionicons/icons';

import xlsExample from '../../../../assets/images/xls-example.png';
import { Trans, useTranslation } from 'react-i18next';

// import './PeopleImportInstructionPanel.css';

const ListImportInstructionPanel: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="list-import-instructions-panel" style={{ marginTop: '2rem' }}>
      <div className="font-24 font-weight-600 ion-margin-bottom">{t('page.listImport.panel.instructions.header')}</div>
      <IonGrid className="ion-padding-start ion-padding-top">
        {/* Step One */}
        <IonRow style={{ marginBottom: '1.75rem' }}>
          <IonCol size="12" sizeXl="4" className="d-flex flex-direction-column">
            <div className="ion-margin-bottom font-20">
              <Trans t={t} i18nKey={'page.listImport.panel.instructions.instruction1'} />
            </div>
            <div style={{ maxWidth: '75%', alignSelf: 'end' }}>
              <Trans t={t} i18nKey={'page.listImport.panel.instructions.instruction1.sub'} />
            </div>
          </IonCol>
          <IonCol size="0" sizeXl="1" className="d-flex ion-justify-content-center ion-align-items-center">
            <IonIcon className="ion-margin-end ion-margin-start font-20" icon={arrowForwardOutline} />
          </IonCol>
          <IonCol size="12" sizeXl="7" className="d-flex ion-justify-content-center ion-align-items-center">
            <IonButton
              download="Import List Template.xlsx"
              href="https://fonttest-3241241e85c74926bbae69b5928898b3.s3.us-west-2.amazonaws.com/templates/Import_List_Template.xlsx"
              size="large"
              className="font-24"
              style={{ flex: '1' }}
            >
              {t('button.download.template')} <IonIcon slot="end" icon={documentOutline} />
            </IonButton>
          </IonCol>
        </IonRow>

        {/* Step Two */}
        <IonRow style={{ marginBottom: '1.75rem' }}>
          <IonCol
            size="12"
            sizeXl="6"
            className="d-flex flex-direction-column ion-padding-vertical ion-justify-content-center"
          >
            <div className="ion-margin-bottom font-20">
              <Trans t={t} i18nKey={'page.listImport.panel.instructions.instruction2'} />
            </div>

            {/* Bullet List */}
            <div>
              <IonGrid>
                <IonRow>
                  <IonCol size="5" className="font-weight-500 font-16 ion-text-end ion-margin-end">
                    <li>{t('page.listImport.panel.instructions.instruction2.sub1.label')}</li>
                  </IonCol>
                  <IonCol className="font-16 font-weight-400" style={{ lineHeight: '22px' }}>
                    {t('page.listImport.panel.instructions.instruction2.sub1.description')}
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="5" className="font-weight-500 font-16 ion-text-end ion-margin-end">
                    <li>{t('page.listImport.panel.instructions.instruction2.sub2.label')}</li>
                  </IonCol>
                  <IonCol className="font-16 font-weight-400" style={{ lineHeight: '22px' }}>
                    {t('page.listImport.panel.instructions.instruction2.sub2.description')}
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="5" className="font-weight-500 font-16 ion-text-end ion-margin-end">
                    <li>{t('page.listImport.panel.instructions.instruction2.sub3.label')}</li>
                  </IonCol>
                  <IonCol className="font-16 font-weight-400" style={{ lineHeight: '22px' }}>
                    {t('page.listImport.panel.instructions.instruction2.sub3.description')}
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="5" className="font-weight-500 font-16 ion-text-end ion-margin-end">
                    <li>{t('page.listImport.panel.instructions.instruction2.sub4.label')}</li>
                  </IonCol>
                  <IonCol className="font-16 font-weight-400" style={{ lineHeight: '22px' }}>
                    {t('page.listImport.panel.instructions.instruction2.sub4.description')}
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
          </IonCol>

          <IonCol sizeXl="6" className="d-flex ion-justify-content-center ion-align-items-center">
            <IonImg src={xlsExample} style={{ flex: '1' }} />
          </IonCol>
        </IonRow>

        {/* Step Three */}
        <IonRow>
          <div className="font-20 d-flex ion-align-items-center">
            <span className="ion-margin-end">
              <Trans t={t} i18nKey={'page.listImport.panel.instructions.instruction3'} />
            </span>
            <IonIcon
              style={{ '--ionicon-stroke-width': '42px' }}
              className="font-24"
              slot="end"
              icon={cloudUploadOutline}
            />
          </div>
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default ListImportInstructionPanel;
