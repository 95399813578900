// components/EmployeeSelector.tsx
import StepsLabel from './StepsLabel';
import EmployeeSelectorDropdown from './EmployeeSelectorDropdown';
import { Person } from '../../people/peopleApi';
// import EmployeeSelectorChips from './EmployeeSelectorChips';

interface EmployeeSelectorProps {
  people: Person[];
}

const EmployeeSelector: React.FC<EmployeeSelectorProps> = ({ people }) => {
  return (
    <>
      {/* Section Header */}
      <StepsLabel stepTitle="2. Select Employee" StepSubtitle="Who's hours should we include?" />

      {/* Employee Selector dropdown */}
      <EmployeeSelectorDropdown people={people} />

      {/* Selected users chips */}
      {/* <EmployeeSelectorChips people={people ?? []} /> */}
    </>
  );
};

export default EmployeeSelector;
