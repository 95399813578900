import { useTranslation } from 'react-i18next';
import Page from '../../../components/Page';
import IntegrationsConnectPanel from '../../../features/integrations/qm/connect/IntegrationsConnectPanel';

const IntegrationsConnectPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Page title={t('title.integrations.qm')}>
      <IntegrationsConnectPanel />
    </Page>
  );
};

export default IntegrationsConnectPage;
