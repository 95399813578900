import { IonItem } from '@ionic/react';
import React from 'react';
import './PeoplePage.css';
import Page from '../components/Page';
import { useTranslation } from 'react-i18next';
import { EnvironmentConfig, getFlag } from '../utils/environmentUtils';

interface ListPageProps {
  children: any;
  tab: string;
}

const ListsPage: React.FC<ListPageProps> = ({ children, tab }) => {
  const { t } = useTranslation();

  const title = t(`title.lists`);

  return (
    <Page title={title}>
      {getFlag(EnvironmentConfig.LIST_IMPORT) ? (
        <div className="d-flex ion-justify-content-start ion-margin-bottom">
          <IonItem
            class={`ion-no-padding ion-text-center people-page ${tab === 'view' ? 'item-active' : ''}`}
            routerLink="/lists/view"
            routerDirection="none"
          >
            {t('navigation.view')}
          </IonItem>
          <IonItem
            class={`ion-no-padding ion-text-center people-page ${tab === 'import' ? 'item-active' : ''}`}
            routerLink="/lists/import"
            routerDirection="none"
          >
            {t('navigation.import')} (Beta)
          </IonItem>
        </div>
      ) : null}
      {children}
    </Page>
  );
};

export default ListsPage;
