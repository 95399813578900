import { IonSelect, IonSelectOption } from '@ionic/react';
import { Person } from '../../people/peopleApi';

interface PersonPickerProps {
  people: Person[];
  placeHolderText: string;
  setResult: (value: string) => void;
  selectedPersonId?: string;
  requireRegistration?: boolean;
}

const PersonPicker: React.FC<PersonPickerProps> = ({
  people,
  placeHolderText,
  setResult,
  selectedPersonId,
  requireRegistration,
}) => {
  return (
    <>
      <IonSelect
        interface="popover"
        placeholder={placeHolderText ? placeHolderText : 'Select a person'}
        onIonChange={(e) => setResult(e.target.value)}
        value={selectedPersonId ? selectedPersonId : ''}
      >
        {people?.map(
          (person: Person) =>
            (person.isRegistered || !requireRegistration) && (
              <IonSelectOption key={person.userId} value={person.userId}>
                {person.firstName} {person.lastName}
              </IonSelectOption>
            )
        )}
      </IonSelect>
    </>
  );
};

export default PersonPicker;
