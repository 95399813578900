import {
  IonAvatar,
  IonButtons,
  IonContent,
  IonHeader,
  IonImg,
  IonItem,
  IonMenuButton,
  IonPopover,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import AllBanners from '../../components/banner/AllBanners';
import { URL_PATH_PREFIX } from '../../constants/constants';
import UserMenu from '../user/components/UserMenu/UserMenu';
import './Toolbar.css';
import { graphql } from '../../utils/api/apiUtils';
import { useEffect } from 'react';
import { atom, useRecoilState } from 'recoil';
import { organizationState } from '../organization/useFetchOrganization';

interface PageHeaderProps {
  title?: string;
}

const getUserProfilePhoto = async (): Promise<any> => {
  const query = `
    query getProfilePhoto {
      getCurrentUser {
    profilePhoto
  }
}
    `;
  const profilePhoto = await graphql<any>(query);
  return profilePhoto.profilePhoto;
};

const getUserLanguage = async (): Promise<any> => {
  const query = `
    query getUserLanguage {
      getCurrentUser {
    language
  }
}
    `;
  const language = await graphql<any>(query);
  return language.language;
};

export const currentUserProfilePhoto = atom<string>({
  key: 'currentUserProfilePhoto',
  default: '',
});

export const currentUserLanguage = atom<string>({
  key: 'currentUserLanguage',
  default: 'en',
});

const Toolbar: React.FC<PageHeaderProps> = ({ title }) => {
  const [profilePhoto, setProfilePhoto] = useRecoilState(currentUserProfilePhoto);
  const [userLanguage, setUserLanguage] = useRecoilState(currentUserLanguage);

  const [organization] = useRecoilState(organizationState);
  const isLocalhost = window.location.hostname === 'localhost';

  useEffect(() => {
    getUserProfilePhoto().then((photo) => {
      setProfilePhoto(photo);
    });

    getUserLanguage().then((language) => {
      setUserLanguage(language);
    });
  }, []);

  return (
    <IonHeader>
      <AllBanners />
      <IonToolbar color="dark" class="ion-no-padding">
        <IonButtons slot="start">
          <IonMenuButton />
        </IonButtons>
        <div className="d-flex ion-justify-content-center">
          <IonItem color="transparent" lines="none" class="ion-no-padding">
            <IonImg
              className="logo"
              src={'./' + (URL_PATH_PREFIX === '' ? '' : URL_PATH_PREFIX + '/') + 'assets/img/logo.png'}
            />
          </IonItem>
          <IonTitle class="ion-no-padding">Admin</IonTitle>
        </div>
        {(isLocalhost || organization?.isTest) && (
          <div slot="end" style={{ marginRight: '1em' }}>
            {organization?.name}
          </div>
        )}
        <div slot="end">
          <IonItem color="transparent" lines="none" class="ion-no-padding">
            <IonAvatar class="cursor-pointer">
              {profilePhoto ? (
                <IonImg id="user-menu-trigger" src={profilePhoto} />
              ) : (
                <IonImg
                  id="user-menu-trigger"
                  src={'./' + (URL_PATH_PREFIX === '' ? '' : URL_PATH_PREFIX + '/') + 'assets/img/profile.svg'}
                  alt="profile avatar"
                />
              )}
            </IonAvatar>
          </IonItem>
          <IonPopover trigger="user-menu-trigger" showBackdrop={false} alignment="start">
            <IonContent class="ion-padding">
              <UserMenu />
            </IonContent>
          </IonPopover>
        </div>
      </IonToolbar>
    </IonHeader>
  );
};

export default Toolbar;
