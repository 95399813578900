import { IonIcon, IonLabel } from '@ionic/react';
import { checkmarkCircleOutline } from 'ionicons/icons';
import { Fragment } from 'react';

export interface Step {
  name: string;
  isCompleted: boolean;
}

interface StepProgressTrackerProps {
  steps: Step[];
}

const StepProgressTracker: React.FC<StepProgressTrackerProps> = ({ steps }) => {
  return (
    <div className="d-flex ion-justify-content-end ion-align-items-start" style={{ margin: '2rem 0' }}>
      {steps.map(({ name, isCompleted }, index) => (
        <Fragment key={`step_fragment_${index}`}>
          {/* Explicit Fragment dather than implcit Fragment <> so that we have have a key property on it */}
          {index !== 0 && (
            <div
              key={`"dash_before_step_${index}`}
              style={{ height: '1px', width: '4rem', backgroundColor: 'var(--ion-color-medium)', marginTop: '1rem' }}
            ></div>
          )}
          <div key={`integration_step-${index}`} className="d-flex flex-direction-column ion-align-items-center">
            <IonIcon
              size="large"
              color="primary"
              style={{ opacity: isCompleted ? '1' : '0.1', transition: 'opacity 0.5s ease' }}
              icon={checkmarkCircleOutline}
            />
            <IonLabel>{name}</IonLabel>
          </div>
        </Fragment>
      ))}
    </div>
  );
};

export default StepProgressTracker;
