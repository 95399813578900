import { useState } from 'react';
import { IonImg } from '@ionic/react';
import { useTranslation } from 'react-i18next';

import appLogo from '../../../../assets/images/qm-logo.png';
import DefaultListOwnerPanel from '../general/DefaultListOwnerPanel';
import QmIntegrationInstructions from '../general/QmIntegrationInstructions';
import QmLoginForm from './QmLoginForm';
import ConnectAccountButton from './ConnectAccountButton';
import StepHeader from '../../../../components/progress/StepHeader';
import StepProgressTracker from '../../../../components/progress/StepProgressTracker';

const IntegrationsConnectPanel: React.FC = () => {
  const [qmAuthToken, setQmAuthToken] = useState<string>('');
  const [defaultListOwner, setDefaultListOwner] = useState<string | undefined>();
  const [linkSuccessMessage, setLinkSuccessMessage] = useState<string | undefined>();

  const { t } = useTranslation();

  return (
    <div className="ion-padding" style={{ maxWidth: '800px' }}>
      {/* Integrations Link View Header */}
      <div>
        <div className="d-flex ion-align-items-center">
          <h1 className="ion-no-margin font-24 font-weight-400">Connect your organization’s</h1>
          {/* TODO: Reach out to Buck to find out if thre is a better logo we could use for this */}
          <IonImg src={appLogo} />
          <h1 className="ion-no-margin font-24 font-weight-400">account.</h1>
        </div>
        <QmIntegrationInstructions />
      </div>

      <StepProgressTracker
        steps={[
          { name: 'Login', isCompleted: !!qmAuthToken },
          { name: 'Choose', isCompleted: !!defaultListOwner },
          { name: 'Connect', isCompleted: !!linkSuccessMessage },
        ]}
      />

      {/* Step 1. Log into Otuvy QM */}
      <div style={{ marginBottom: '2.5rem' }}>
        <StepHeader
          header={
            <>
              <h1 className="ion-no-margin">1. Login to</h1>
              <IonImg src={appLogo} />
            </>
          }
          isCompleted={!!qmAuthToken}
          spaceBetween={false}
        />
        <QmLoginForm qmAuthToken={qmAuthToken} setQmAuthToken={setQmAuthToken} />
      </div>

      {/* Step 2. Choose list owner */}
      <div style={{ marginBottom: '2.5rem' }}>
        <StepHeader header="2. Choose" isCompleted={!!defaultListOwner} />
        <DefaultListOwnerPanel defaultListOwner={defaultListOwner} onDefaultListOwnerChange={setDefaultListOwner} />
      </div>

      {/* Step 3. Connect */}
      <div style={{ marginBottom: '2.5rem' }}>
        <StepHeader header="3. Connect" isCompleted={!!linkSuccessMessage} />
        {/* Connection Note */}
        <div style={{ marginLeft: '2rem' }}>
          <p className="font-20">
            <span className="font-weight-600">Note:</span> This will notify all users in the top level of the Otuvy QM
            SP Org Chart.
          </p>
        </div>
        <ConnectAccountButton
          qmAuthToken={qmAuthToken}
          defaultListOwner={defaultListOwner}
          linkSuccessMessage={linkSuccessMessage}
          setLinkSuccessMessage={setLinkSuccessMessage}
        />
      </div>
    </div>
  );
};

export default IntegrationsConnectPanel;
