import { Language } from '../../constants/constants';
import { PersonRow } from './components/PeopleImportGrid/PeopleImportGrid';

export const changeGridCellText = (flexGrid: any, gridEvents: any) => {
  let col = flexGrid.columns[gridEvents.col];
  if (gridEvents.row > 0 && col.binding === 'status') {
    let cellData = flexGrid.getCellData(gridEvents.row, gridEvents.col);
    if (cellData === 1) {
      gridEvents.cell.innerText = 'active';
    }
    if (cellData === 2) {
      gridEvents.cell.innerText = 'inactive';
    }
  }
  if (gridEvents.row > 0 && col.binding === 'role') {
    let cellData = flexGrid.getCellData(gridEvents.row, gridEvents.col);
    if (cellData === 1) {
      gridEvents.cell.innerText = 'employee';
    }
    if (cellData === 3) {
      gridEvents.cell.innerText = 'admin';
    }
    if (cellData === 4) {
      gridEvents.cell.innerText = 'super admin';
    }
  }
};

export const isImportRowEmpty = (item: PersonRow): boolean => {
  return !item.firstName && !item.lastName && !item.email && !item.language;
};

export const mapLanguage = (lang: string | undefined): Language | undefined => {
  const l = lang?.toLowerCase();
  if (l === 'en' || l === 'english') {
    return Language.ENGLISH;
  } else if (l === 'es' || l === 'spanish') {
    return Language.ESPAÑOL;
  } else if (l) {
    return Language.INVALID;
  }
  return undefined;
};
