import {
  IonHeader,
  IonTitle,
  IonIcon,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonDatetimeButton,
  IonPopover,
  IonDatetime,
  IonFooter,
  IonButton,
  IonNote,
} from '@ionic/react';
import { format, formatISO, isAfter, isBefore } from 'date-fns';
import { closeOutline } from 'ionicons/icons';
import { useRecoilState, useRecoilValue } from 'recoil';
import { dateRangeState, userShiftDataState } from '../recoil/atoms';
import { useState } from 'react';
import { deleteWorklog, editWorkLog } from '../api/TimeTrackingAPI';
import { DetailedShiftData, WorkLog } from '../../../utils/dataTransformers';
import { checkForWorkLogOverlap } from '../utils/timeTrackingUtils';
import CustomTimePicker from './CustomTimePicker';

interface EditWorkLogDialogProps {
  dismiss: () => void;
  workLogRecord: DetailedShiftData;
}

const EditWorkLogRecordDiaglog: React.FC<EditWorkLogDialogProps> = ({ dismiss, workLogRecord }) => {
  const [startDate, setStartDate] = useState<Date>(() => {
    return workLogRecord.startDate ? workLogRecord.startDate : new Date();
  });
  const [startDateError, setDateError] = useState<string | null>(null);

  const [endDate, setEndDate] = useState<Date | null>(() => {
    return workLogRecord.endDate ? workLogRecord.endDate : null;
  });
  const [endDateError, setEndDateError] = useState<string | null>(null);

  const dateRange = useRecoilValue(dateRangeState);
  const [userShiftData, setUserShiftData] = useRecoilState(userShiftDataState);

  const onStartDateChange = (e: any) => {
    const newStartDate = new Date(e);

    if (endDate && isAfter(newStartDate, endDate)) {
      setDateError('Start time cannot be after end time');
      setStartDate(new Date(newStartDate));
      return;
    }

    if (startDateError !== 'Invalid time value') setDateError(null);
    if (endDateError !== 'Invalid time value') setEndDateError(null);
    setStartDate(new Date(newStartDate));
  };

  const onEndDateChange = (e: any) => {
    const newEndDate = new Date(e);

    if (startDate && isBefore(newEndDate, startDate)) {
      setEndDateError('End time cannot be before start time');
      setEndDate(new Date(newEndDate));
      return;
    }

    if (startDateError !== 'Invalid time value') setDateError(null);
    if (endDateError !== 'Invalid time value') setEndDateError(null);
    setEndDate(new Date(newEndDate));
  };

  const onSaveClickHandler = async () => {
    const hasWorkLogOverlap = checkForWorkLogOverlap(
      startDate,
      endDate,
      userShiftData,
      workLogRecord.userId!,
      workLogRecord.workLogId
    );

    if (hasWorkLogOverlap) {
      setDateError('Start time and end time overlap with existing work logs');
      setEndDateError('Start time and end time overlap with existing work logs');
      return;
    }

    if (startDate) {
      if (isAfter(startDate, new Date())) {
        setDateError('Time cannot be in the future');
        return;
      }
    }

    if (endDate) {
      if (isAfter(endDate, new Date())) {
        setEndDateError('Time cannot be in the future');
        return;
      }
    }

    console.log(`Saving edit of record ${workLogRecord.workLogId}`);

    await editWorkLog(workLogRecord.workLogId, startDate, endDate);

    setUserShiftData((currentState) =>
      currentState.map((userShift) => {
        return {
          ...userShift,
          workLogs: userShift.workLogs.map((item: WorkLog) => {
            if (item.workLogId === workLogRecord.workLogId) {
              return {
                ...item,
                punchIn: {
                  ...item.punchIn,
                  punchTime: startDate,
                },
                punchOut: {
                  ...item.punchOut,
                  punchTime: endDate,
                },
              };
            }

            return item;
          }),
        };
      })
    );

    dismiss();
  };

  const onDeleteClickHandler = () => {
    deleteWorklog(workLogRecord.workLogId).then(() => {
      setUserShiftData((currentState) => {
        return currentState.map((userShift) => ({
          ...userShift,
          workLogs: userShift.workLogs.filter((item) => item.workLogId !== workLogRecord.workLogId),
        }));
      });

      dismiss();
    });
  };

  const onStartDateChangeEventHandler = (value: string | null) => {
    if (!value) return;
    const [hours, minutes] = value.split(':');

    setStartDate((prevStartTime) => {
      const newStartTime = new Date(prevStartTime);
      newStartTime.setHours(Number(hours));
      newStartTime.setMinutes(Number(minutes));
      return newStartTime;
    });
    setDateError(null);
  };

  const onEndDateChangeEventHandler = (value: string | null) => {
    if (!value) return;
    const [hours, minutes] = value.split(':');

    if (!endDate) return;

    setEndDate((prevEndTime) => {
      const newEndTime = new Date(prevEndTime!);
      newEndTime.setHours(Number(hours));
      newEndTime.setMinutes(Number(minutes));
      return newEndTime;
    });
    setEndDateError(null);
  };

  if (!workLogRecord) return null;
  return (
    <div className="ion-padding">
      <IonHeader class="ion-no-border d-flex ion-justify-content-between">
        <IonTitle>Edit Time Record</IonTitle>
        <IonIcon class="clickable ion-padding-end" icon={closeOutline} size="large" onClick={dismiss} />
      </IonHeader>
      <div className="custom-form">
        <IonList>
          {/* User name */}
          <IonItem lines="none" className="disable-pointer-events">
            <IonLabel position="stacked">User Name:</IonLabel>
            <IonInput style={{ pointerEvents: 'none' }} value={workLogRecord.fullName} />
          </IonItem>

          {/* Start Date / might add time */}
          <IonItem lines="none" className={`${startDateError ? 'ion-invalid' : ''}`}>
            <IonLabel position="stacked">Start Date:</IonLabel>
            <div className="d-flex ion-align-items-center" style={{ marginTop: '8px' }}>
              <IonDatetimeButton datetime="edit-start-date-datetime"></IonDatetimeButton>
              <CustomTimePicker
                value={format(startDate, 'HH:mm')}
                onChange={onStartDateChangeEventHandler}
                onError={(error) => setDateError(error)}
              />
            </div>
            <IonNote slot="error">{startDateError}</IonNote>

            <IonPopover keepContentsMounted={true}>
              <IonDatetime
                presentation="date"
                id="edit-start-date-datetime"
                mode="ios"
                className="time-tracking"
                value={formatISO(startDate)}
                onIonChange={(e) => onStartDateChange(e.detail.value)}
                min={formatISO(dateRange[0]!)}
                max={formatISO(dateRange[1]!)}
              ></IonDatetime>
            </IonPopover>
          </IonItem>

          {/* end Date / might add time */}
          <IonItem lines="none" className={`${endDateError ? 'ion-invalid' : ''}`}>
            <IonLabel position="stacked">End Date:</IonLabel>
            <div className="d-flex ion-align-items-center" style={{ marginTop: '8px' }}>
              <IonDatetimeButton datetime="edit-end-date-datetime">
                {!endDate && <div slot="date-target">Select a Date</div>}
                {endDate && <div slot="date-target">{format(endDate, 'MM/dd/yyyy')}</div>}
              </IonDatetimeButton>
              <CustomTimePicker
                value={endDate ? format(endDate, 'HH:mm') : ''}
                onChange={onEndDateChangeEventHandler}
                onError={(error) => setEndDateError(error)}
              />
            </div>
            <IonNote slot="error">{endDateError}</IonNote>

            <IonPopover keepContentsMounted={true}>
              <IonDatetime
                presentation="date"
                id="edit-end-date-datetime"
                mode="ios"
                className="time-tracking"
                value={endDate ? formatISO(endDate) : undefined}
                onIonChange={(e) => onEndDateChange(e.detail.value)}
                min={formatISO(dateRange[0]!)}
                max={formatISO(dateRange[1]!)}
              ></IonDatetime>
            </IonPopover>
          </IonItem>
          {/* TODO: Add a total time for the shift */}
        </IonList>
      </div>
      <IonFooter class="ion-no-border">
        <div className="d-flex ion-justify-content-end ion-align-items-center ion-padding">
          <IonButton onClick={onDeleteClickHandler}>Delete</IonButton>
          <IonButton disabled={startDateError !== null || endDateError !== null} onClick={onSaveClickHandler}>
            Save
          </IonButton>
        </div>
      </IonFooter>
    </div>
  );
};

export default EditWorkLogRecordDiaglog;
