import { useEffect } from 'react';
import { atom, useSetRecoilState } from 'recoil';
import { getGovernors, OrganizationGovernors } from '../usageApi';
import { agreementState } from '../../../App';

export const governorsState = atom<OrganizationGovernors | undefined>({
  key: 'governors',
  default: undefined,
});

const useFetchGovernors = () => {
  const setGovernors = useSetRecoilState(governorsState);
  const setAgreementState = useSetRecoilState(agreementState);

  useEffect(() => {
    getGovernors().then((governors: OrganizationGovernors) => {
      setAgreementState(governors.agreementState);
      setGovernors(governors);
    });
  }, []);
};

export default useFetchGovernors;
