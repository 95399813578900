import React, { useEffect, useReducer, useState } from 'react';
import './PeopleImportGrid.css';
import { useRecoilValue } from 'recoil';
import usePeopleValidations from '../../hooks/usePeopleValidations';
import { writeAccessRestrictedState } from '../../../../App';
import { IonButton } from '@ionic/react';
import { isImportRowEmpty } from '../../peopleGridUtils';
import { v4 as uuid } from 'uuid';
import useImportPeople from './useImportPeople';

import { FlexGrid, CellRange, KeyAction } from '@grapecity/wijmo.grid';
import { DataChangeAction } from '@grapecity/wijmo.grid.immutable';
import * as wjcGrid from '@grapecity/wijmo.react.grid';
import { ImmutabilityProvider } from '@grapecity/wijmo.react.grid.immutable';
import { useTranslation } from 'react-i18next';
import { Person, getAllPeople } from '../../peopleApi';
import useGraphqlQuery from '../../../../hooks/useGraphqlQuery';

export interface PersonRow {
  rowId?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  language?: string;
}

enum ActionType {
  Add = 'ADD',
  Edit = 'EDIT',
  Remove = 'REMOVE',
  Reset = 'RESET',
}

type Action = {
  type: ActionType;
  payload: any;
};

const reducer = (state: PersonRow[], action: Action): PersonRow[] => {
  switch (action.type) {
    case ActionType.Add:
      const newItem: PersonRow = {
        rowId: uuid(),
        ...action.payload.newItem,
      };
      if (state.length === 0) {
        if (!newItem.firstName) {
          newItem.firstName = '';
        }
        return [newItem];
      } else {
        return [...state, newItem];
      }
    case ActionType.Edit:
      if (state) {
        const newState = [
          ...state.slice(0, action.payload.itemIndex),
          action.payload.newItem,
          ...state.slice(action.payload.itemIndex + 1),
        ];
        return newState;
      } else {
        return state;
      }
    case ActionType.Remove:
      if (state) {
        action.payload.setErrorCells((prevState: string[]) => {
          return prevState.filter((errorCell) => !errorCell.startsWith(action.payload.e.oldItem.rowId));
        });
        const newState = [
          ...state.slice(0, action.payload.e.itemIndex),
          ...state.slice(action.payload.e.itemIndex + 1),
        ];
        return newState;
      }
      return state;
    case ActionType.Reset:
      action.payload.setErrorCells([]);
      return [];
    default:
      return state;
  }
};

const PeopleImportGrid: React.FC = () => {
  const isWriteAccessRestricted = useRecoilValue(writeAccessRestrictedState);

  const [gridState, dispatch] = useReducer(reducer, []);
  const [errorCells, setErrorCells] = useState<string[]>([]); //The only functional purpose of this variable is to prevent submitting when there are any errors.  It does not actually have any functional use other than that

  const [areTopActionsVisible, setAreTopActionsVisible] = useState(false);
  const topActionsContainerRef = React.useRef<HTMLDivElement>(null);
  const bottomActionsContainerRef = React.useRef<HTMLDivElement>(null);
  // This effect is used to determine if the bottom actions should be visible or not
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setAreTopActionsVisible(!entry.isIntersecting);
      },
      { threshold: 0.5, rootMargin: '0px' }
    );

    if (topActionsContainerRef.current) {
      observer.observe(topActionsContainerRef.current);
    }

    return () => {
      if (topActionsContainerRef.current) {
        observer.unobserve(topActionsContainerRef.current);
      }
    };
  }, []);

  const { data } = useGraphqlQuery(getAllPeople);

  const { validateImportGridCell: getErrorHandler } = usePeopleValidations(setErrorCells);
  const { t } = useTranslation();

  //We are always recalculating "importState" rather than calculating it on import since the number of valid import records controls/affects several UI elements
  const importState: PersonRow[] = gridState
    .filter((item) => {
      /*
    TODO: There are other criteria in addition to being empty when a row should not be included in import
    For example, when a cell in that row is invalid we should nto count it as a valid upload row
    */
      const isRowEmpty = isImportRowEmpty(item);
      return !isRowEmpty;
    })
    .map((r) => {
      if (r.rowId) return r;
      return {
        ...r,
        rowId: uuid(),
      };
    });

  const addState: PersonRow[] = importState
    .filter((item) => {
      if (!item || !item.email) return false;
      if (data) {
        const matchingUsers = (data as Person[]).filter((p) => {
          return p.email && p.email === item.email;
        });
        return !matchingUsers || matchingUsers.length === 0;
      }
      return false;
    })
    .map((r) => {
      if (r.rowId) return r;
      return {
        ...r,
        rowId: uuid(),
      };
    });

  const updateState: PersonRow[] = importState
    .filter((item) => {
      if (!item || !item.email) return false;
      if (data) {
        const matchingUsers = (data as Person[]).filter((p) => {
          if (!p) return false;
          if (p.email && p.email === item.email) {
            const firstMatches = p.firstName === item.firstName;
            const lastMatches = p.lastName === item.lastName;
            const languageMatches = p.language === item.language; //TODO: improve language matching (es === spanish)
            return !(firstMatches && lastMatches && languageMatches);
          }
          return false;
        });
        if (!matchingUsers || matchingUsers.length === 0) return false;

        return true;
      }
      return false;
    })
    .map((r) => {
      if (r.rowId) return r;
      return {
        ...r,
        rowId: uuid(),
      };
    });

  const resetGrid = () => {
    dispatch({ type: ActionType.Reset, payload: { setErrorCells } });
  };

  const { importPeople } = useImportPeople(importState, resetGrid);

  const triggerDataChange = (flex: FlexGrid) => {
    // Changing the selection down one row and back triggers the dataChanged event
    const originalSelection = flex.selection;
    const { row, col, row2, col2 } = originalSelection;
    flex.select(new CellRange(row + 1, col, row2 + 1, col2), false); // false so it doesn't scroll into view
    flex.select(originalSelection, false);
  };

  const lostFocusHandler = (flex: FlexGrid) => {
    triggerDataChange(flex);
  };

  const gridInitializedHandler = (flex: FlexGrid) => {
    const collectionView = flex.collectionView as any;
    //https://www.grapecity.com/wijmo/api/classes/wijmo.collectionview.html#geterror
    collectionView.getError = getErrorHandler;

    //Make sure nothing in the grid is selected when the page loads (allows user to click the first cell without entering edit mode)
    flex.select(-1, -1);

    // These next to event listeners are allowing us to delete the whole row and not just the data in the row
    let clickedOnRowHeaderWhileEditing = false;
    // set a flag when a rowHeader cell is clicked while a row is in edit state
    flex.rowHeaders.hostElement.addEventListener('click', (e) => {
      if (collectionView.isEditingItem) {
        clickedOnRowHeaderWhileEditing = true;
      }
    });
    // commit any pending edits when either "Backspace" or "Delete" key is pressed when the current row is in edit state.
    flex.hostElement.addEventListener(
      'keydown',
      (e) => {
        if ((e.code === 'Backspace' || e.code === 'Delete') && clickedOnRowHeaderWhileEditing) {
          collectionView.commitEdit();
          clickedOnRowHeaderWhileEditing = false;
        }
      },
      true
    );
  };

  const onDataChanged = (s: any, e: any) => {
    switch (e.action) {
      case DataChangeAction.Add:
        dispatch({ type: ActionType.Add, payload: e });
        break;
      case DataChangeAction.Change:
        dispatch({ type: ActionType.Edit, payload: e });
        break;
      case DataChangeAction.Remove:
        dispatch({ type: ActionType.Remove, payload: { e, setErrorCells } });
        break;
      default:
        throw new Error('Unknown data action');
    }
  };

  return (
    <div>
      <div className="grid-buttons" ref={topActionsContainerRef}>
        <IonButton
          className="btn-reset"
          fill="outline"
          disabled={isWriteAccessRestricted || gridState.length === 0}
          onClick={resetGrid}
        >
          {t('button.clear')}
        </IonButton>

        <div className="import-spacer" />
        <div className="label-update">
          Will be added: {addState.length}
          <br />
          Will be updated: {updateState.length}
        </div>

        <IonButton
          className="btn-import"
          disabled={isWriteAccessRestricted || importState.length === 0 || errorCells.length > 0}
          onClick={importPeople}
        >
          Add/Update Users
        </IonButton>
      </div>
      <wjcGrid.FlexGrid
        initialized={gridInitializedHandler}
        isDisabled={isWriteAccessRestricted}
        lostFocus={lostFocusHandler}
        showErrors={true}
        refreshOnEdit={false}
        validateEdits={false} // In order to not be stuck in edit mode on a cell level you must set validateEdits to false - https://www.grapecity.com/wijmo/api/classes/wijmo_grid.flexgrid.html#validateedits
        allowSorting={false} // Note: Had to disable sorting {allowSorting} because it threw off the error validation display. We can come back to that in the future.
        style={{ height: 'auto' }}
        autoGenerateColumns={false} // https://www.grapecity.com/wijmo/api/classes/wijmo_grid.flexgrid.html#autogeneratecolumns
        allowAddNew={true}
        allowDelete={true}
        allowPinning="SingleColumn"
        keyActionTab={KeyAction.Cycle} // https://www.grapecity.com/wijmo/api/classes/wijmo_grid.flexgrid.html#keyactiontab
      >
        <ImmutabilityProvider itemsSource={gridState} dataChanged={onDataChanged} />
        <wjcGrid.FlexGridColumn
          header={t('grid.column.header.user.firstName')}
          binding="firstName"
          minWidth={200}
          width="*"
          isRequired={false} // https://www.grapecity.com/wijmo/api/classes/wijmo_grid.column.html#isrequired
        ></wjcGrid.FlexGridColumn>
        <wjcGrid.FlexGridColumn
          header={t('grid.column.header.user.lastName')}
          binding="lastName"
          minWidth={200}
          width="*"
          isRequired={false} // https://www.grapecity.com/wijmo/api/classes/wijmo_grid.column.html#isrequired
        ></wjcGrid.FlexGridColumn>
        <wjcGrid.FlexGridColumn
          header={t('grid.column.header.user.email')}
          binding="email"
          minWidth={200}
          width="*"
          isRequired={false} // https://www.grapecity.com/wijmo/api/classes/wijmo_grid.column.html#isrequired
        ></wjcGrid.FlexGridColumn>
        <wjcGrid.FlexGridColumn
          header={t('grid.column.header.user.language')}
          binding="language"
          maxWidth={200}
          isRequired={false} // https://www.grapecity.com/wijmo/api/classes/wijmo_grid.column.html#isrequired
        ></wjcGrid.FlexGridColumn>
      </wjcGrid.FlexGrid>

      {areTopActionsVisible && (
        <div className="grid-buttons" ref={bottomActionsContainerRef}>
          <div className="label-update">
            Will be added: {addState.length}
            <br />
            Will be updated: {updateState.length}
          </div>

          <IonButton
            className="btn-import"
            disabled={isWriteAccessRestricted || importState.length === 0 || errorCells.length > 0}
            onClick={importPeople}
          >
            Add/Update Users
          </IonButton>
        </div>
      )}
    </div>
  );
};

export default PeopleImportGrid;
