import { IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon, IonFooter } from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import { Person } from '../../peopleApi';

interface DeletePersonConfirmationModalProps {
  onDeleteConformation: () => void;
  onDismissConfirmation: () => void;
  person: Person;
}

const DeletePersonConfirmationModal: React.FC<DeletePersonConfirmationModalProps> = ({
  onDeleteConformation,
  onDismissConfirmation,
  person,
}) => {
  return (
    <>
      {/* Header */}
      <IonHeader className="ion-no-border">
        <IonToolbar className="ion-no-border">
          <IonTitle className="font-26" style={{ paddingTop: '2rem' }}>
            Before we delete {person.firstName}...
          </IonTitle>
          <IonButtons style={{ alignSelf: 'start', paddingTop: '0.75rem' }} slot="end">
            {/* Close button */}
            <IonButton onClick={() => onDismissConfirmation()}>
              <IonIcon icon={closeOutline} size="large" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      {/* Content */}
      <div style={{ padding: '1rem 2rem' }}>
        <p className="ion-no-margin">Are you sure you want to delete {person.firstName}?</p>
      </div>

      {/* Footer */}
      <IonFooter className="ion-no-border" style={{ padding: '2rem 1.25rem', paddingTop: '0' }}>
        <IonToolbar>
          <IonButtons slot="start" onClick={() => onDismissConfirmation()}>
            <IonButton className="font-20" color="primary">
              Cancel
            </IonButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton className="font-20" color="danger" onClick={() => onDeleteConformation()}>
              Delete {person.firstName}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </>
  );
};

export default DeletePersonConfirmationModal;
