import { IonLabel } from '@ionic/react';
import PersonPickerSearch from '../../../../components/personPicker/PersonPickerSearch';
import { useTranslation } from 'react-i18next';
import useGetPersonPickerSearchOwnerData from '../../../../components/personPicker/useGetPersonPickerOwnerData';

interface DefaultListOwnerPanelProps {
  defaultListOwner: string | undefined;
  onDefaultListOwnerChange: (personId: string) => void;
}

const DefaultListOwnerPanel: React.FC<DefaultListOwnerPanelProps> = ({
  defaultListOwner,
  onDefaultListOwnerChange,
}) => {
  const { people } = useGetPersonPickerSearchOwnerData();
  const { t } = useTranslation();

  return (
    <>
      {/* List owner picker */}
      <div className="d-flex ion-align-items-center ion-margin-bottom" style={{ gap: '1rem', marginLeft: '2rem' }}>
        <IonLabel className="font-weight-500 font-20">Default List Owner*:</IonLabel>
        <div style={{ width: '241px' }}>
          <PersonPickerSearch
            people={people}
            selectedPersonId={defaultListOwner}
            placeholder={t('placeholder.selectOne')}
            onIonChange={(e) => onDefaultListOwnerChange(e.detail.value)}
          />
        </div>
      </div>

      {/* List owner explanation */}
      <div
        className="ion-padding ion-margin-top"
        style={{
          margin: '0 auto',
          marginLeft: '4rem',
          border: '1.32px solid #F9F9F9',
          borderRadius: '1rem',
          boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
          background: '#F9F9F9',
        }}
      >
        <div className="ion-padding-horizontal">
          <h1 className="font-16">
            * The default list owner will receive all the inspection deficiency lists.
            <br />
            Once connected, an admin can:
          </h1>
          <ol>
            <li>Choose a list owner for each location</li>
            <li>Turn inspection deficiency lists on/off</li>
            <li>Choose settings for new QM locations</li>
          </ol>
          <h1 className="font-16">* This feature is currently in Beta. Watch for exciting new features shortly!</h1>
        </div>
      </div>
    </>
  );
};

export default DefaultListOwnerPanel;
