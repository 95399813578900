import { IonContent } from '@ionic/react';
import Page from '../components/Page';
import ListArchivePanel from '../features/settings/components/ListArchive/ListArchivePanel';

const SettingsPage = () => {
  return (
    <Page title="Settings">
      <IonContent className="ion-padding">
        <ListArchivePanel />
      </IonContent>
    </Page>
  );
};

export default SettingsPage;
