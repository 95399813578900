import React, { FormEvent, useEffect, useRef } from 'react';
import {
  IonItem,
  IonButton,
  IonInput,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonText,
  useIonRouter,
  IonPage,
  IonContent,
} from '@ionic/react';
import logo from '../../assets/images/app-logo.png';
import { useTranslation } from 'react-i18next';
import './OnboardingSignIn.css';
import { useSetRecoilState } from 'recoil';
import { errorAlertMessageKeyState } from '../../error/ErrorAlert';
import { useSignIn } from '@otuvy/auth';

const OnboardingSignIn: React.FC = () => {
  const params = new URLSearchParams(window.location.search);
  const emailAddress: string | null = params.get('u');

  const setErrorMessageKey = useSetRecoilState(errorAlertMessageKeyState);

  const onOtpRequestFailure = async () => {
    // If requesting the OTP fails for any reason, redirect to the main login screen.
    navigation.push('/login', 'forward', 'replace');
  };
  const handlePostSignIn = async () => {
    navigation.push('/register', 'forward', 'replace');
  };

  const {
    email,
    setEmail,
    validateEmail,
    requestOtp,
    requestNewOTP,
    otpConfirmation,
    setOtpConfirmation,
    verifyOtp,
    otpValidationFailed,
  } = useSignIn({
    isAdminPortal: true, //<----------------------make sure this is set correctly for the project TODO: move to env file
    setErrorMessageKey,
    onOtpRequestFailure,
    handlePostSignIn,
  });

  const { t } = useTranslation();
  const emailInputRef = useRef<HTMLIonInputElement | null>(null);
  const navigation = useIonRouter();

  useEffect(() => {
    if (!emailAddress) {
      // No email address, redirect to the normal login screen
      navigation.push('/login', 'forward', 'replace');
    } else {
      if (validateEmail(emailAddress.trim())) {
        setEmail(emailAddress.trim());
      } else {
        // Invalid email address.  Might want to take some other action here.
        navigation.push('/login', 'forward', 'replace');
      }
    }
  }, []);

  // Once the email has been set, automatically send for the OTP.
  useEffect(() => {
    if (email) {
      console.log('Sending an email.');
      handleEmailSubmit();
    }
  }, [email]);

  const handleEmailSubmit = async () => {
    try {
      emailInputRef.current?.getInputElement().then((input) => input.blur()); // This is needed when submitting with the enter key to avoid a potential focus event when returning the app to the foreground
      requestOtp();
    } catch (error) {
      console.error('Failed to submit Email', error);
    }
  };

  const handleOTPSubmit = async (event: MouseEvent | KeyboardEvent | FormEvent | CustomEvent<FocusEvent>) => {
    try {
      event.preventDefault();
      await verifyOtp();
    } catch (error) {
      console.error('Failed to submit OTP request', error);
    }
  };

  return (
    <IonPage>
      <IonContent>
        <IonGrid class="h-100 ion-padding-horizontal ion-align-items-center">
          <IonRow class="h-100 ion-justify-content-center ion-align-items-center">
            <IonCol size="12" sizeMd="10" sizeLg="6">
              <IonRow class="ion-justify-content-center">
                <IonCol size="12">
                  <IonImg src={logo} />
                </IonCol>
              </IonRow>

              <IonRow></IonRow>
              <IonRow class="ion-justify-content-center">
                <IonCol size="12">
                  <IonItem>
                    <form onSubmit={handleEmailSubmit} style={{ width: '100%' }}>
                      <IonLabel style={{ fontSize: '30px' }} class="font-weight-700" position="floating">
                        {t('email')}
                      </IonLabel>
                      <IonInput
                        class="centered-input"
                        ref={emailInputRef}
                        value={email}
                        disabled
                        type={'email'}
                        autofocus={true}
                      />
                    </form>
                  </IonItem>
                </IonCol>
              </IonRow>
              <>
                <IonRow>
                  <IonCol size="12">
                    <div className="success-message">
                      <IonText class="ion-text-center ion-margin-horizontal">
                        <h6>
                          Your payment was successful! Check your email for the login verification code and enter it
                          below.
                        </h6>
                      </IonText>
                    </div>
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol size="12">
                    <IonItem>
                      <form style={{ width: '100%' }} onSubmit={handleOTPSubmit}>
                        <IonLabel style={{ fontSize: '30px' }} class="font-weight-700" position="floating">
                          {t('otpCode')}
                        </IonLabel>
                        <IonInput
                          class="centered-input"
                          placeholder={t('placeholder.otp')}
                          value={otpConfirmation}
                          type={'number'}
                          pattern={'[0-9]*'}
                          autocomplete="one-time-code"
                          onIonChange={(e) => setOtpConfirmation(e.detail.value?.trim() ?? '')}
                          // onIonInput={(e) => setOtpConfirmation(e.detail.value?.toString().trim() ?? '')} //TODO: use this after upgrading to Ionic 7
                        />
                      </form>
                    </IonItem>
                  </IonCol>
                </IonRow>
                {otpValidationFailed ? (
                  <IonRow>
                    <IonCol size="12">
                      <IonText color="danger">
                        <h6>{t('error.signin.incorrectOTP')}</h6>
                      </IonText>
                    </IonCol>
                  </IonRow>
                ) : null}
                <IonRow class="ion-margin-vertical">
                  <IonCol size="12">
                    <IonButton
                      className="onboard-button"
                      color="primary"
                      disabled={!otpConfirmation}
                      expand="block"
                      onClick={verifyOtp}
                    >
                      {t('submit')}
                    </IonButton>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="12" class="ion-text-center">
                    <a href="#" onClick={(e) => requestNewOTP(e)}>
                      {t('newCodeRequest')}
                    </a>
                  </IonCol>
                </IonRow>
              </>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default OnboardingSignIn;
