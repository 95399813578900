import { IonLoading } from '@ionic/react';

import { t } from 'i18next';

const LoadingMessage: React.FC = () => {
  return (
    <>
      <IonLoading isOpen={true} message={t('requestToWait')} />
    </>
  );
};

export default LoadingMessage;
