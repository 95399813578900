import React from 'react';
import { createRoot } from 'react-dom/client';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { RecoilRoot } from 'recoil';
import SecureApp from './SecureApp';
import './i18n';
import { createBrowserHistory } from 'history';
import ErrorAlert from './error/ErrorAlert';
import NotificationModal from './error/NotificationModal';
import PrintContent from './components/PrintContent';
import captureConsole from './captureConsole';
import { EnvironmentConfig, getFlag } from './utils/environmentUtils';

if (getFlag(EnvironmentConfig.CAPTURE_LOGS)) captureConsole();

const history = createBrowserHistory();
const container = document.getElementById('root');

if (container) {
  const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
  if (path) {
    history.replace(path);
  }

  const root = createRoot(container!);
  root.render(
    <RecoilRoot>
      <ErrorAlert />
      <NotificationModal />
      <PrintContent />
      <div className="print-hidden">
        <SecureApp />
      </div>
    </RecoilRoot>
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://cra.link/PWA
  serviceWorkerRegistration.unregister();

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
}
