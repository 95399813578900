import {
  IonItem,
  IonLabel,
  IonIcon,
  IonPopover,
  IonHeader,
  IonSearchbar,
  IonContent,
  IonList,
  IonFooter,
  IonButton,
  IonText,
} from '@ionic/react';
import { chevronDownOutline } from 'ionicons/icons';
import { useState, useRef, useMemo } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { dateRangeState, selectedEmployeesState, showReportState, userShiftDataState } from '../recoil/atoms';
import { Person } from '../../people/peopleApi';
import { getWorkLogsForCurrentUser } from '../api/TimeTrackingAPI';

interface EmployeeSelectorDropdownProps {
  people: Person[];
}

const EmployeeSelectorDropdown: React.FC<EmployeeSelectorDropdownProps> = ({ people }) => {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const setUserShiftData = useSetRecoilState(userShiftDataState);
  const [selectedEmployees, setSelectedEmployees] = useRecoilState(selectedEmployeesState);
  const [temporarySelection, setTemporarySelection] = useState<string[]>([]);
  const dateRange = useRecoilValue(dateRangeState);
  const [showReport, setShowReport] = useRecoilState(showReportState);

  // TODO: Favorite user feature
  // const [favorites, setFavorites] = useState<{ [key: string]: boolean }>({});

  const popoverRef = useRef<HTMLIonPopoverElement>(null);

  const myfilteredEmployees = (() => {
    const users = [...people];
    const selectedUsers = users.filter((user) => temporarySelection.includes(user.userId));
    const unselectedUsers = users.filter((user) => !temporarySelection.includes(user.userId));
    return [...selectedUsers, ...unselectedUsers];
  })();

  const onItemClickedHandler = (personId: string) => {
    setTemporarySelection((prev) =>
      prev.includes(personId) ? prev.filter((id) => id !== personId) : [...prev, personId]
    );
  };

  // TODO: Favorite user feature
  // const handleToggleFavorite = (personId: string) => {
  //   setFavorites((prevFavorites) => ({
  //     ...prevFavorites,
  //     [personId]: !prevFavorites[personId],
  //   }));
  // };

  // TODO: we need to call the endpoint and pass in a date Range, and an array of employees
  const onApplyHandler = async () => {
    try {
      setSelectedEmployees(() => {
        return temporarySelection;
      });
      setShowReport(true);
    } catch (error) {
    } finally {
      if (popoverRef.current) {
        await popoverRef.current.dismiss(); // Close the popover
      }
    }
  };

  const onSelectAllHandler = () => {
    if (temporarySelection.length === myfilteredEmployees.length) {
      setTemporarySelection([]);
      return;
    }

    setTemporarySelection(myfilteredEmployees.map((person) => person.userId));
  };
  // TODO: clean up filteredPeople may not need it anymore
  const filteredPeople = useMemo(() => {
    if (!myfilteredEmployees) return [];
    const lowerCaseQuery = searchQuery.toLowerCase();
    return myfilteredEmployees.filter((person) => {
      const fullName = `${person.firstName} ${person.lastName}`.toLowerCase();
      // const fullName = person.firstName.toLowerCase();
      return fullName.includes(lowerCaseQuery);
    });
  }, [myfilteredEmployees, searchQuery]);

  return (
    <>
      <IonItem
        lines="none"
        className="ion-margin-start"
        button
        id="employeeSelector-action-menu-personPicker-owner"
        style={{ maxWidth: '75%', border: '1px solid #000', borderRadius: '.5rem' }}
        disabled={!dateRange[0] || !dateRange[1]}
      >
        <IonLabel>
          {temporarySelection.length === 0 ? 'Select Employees' : `${temporarySelection.length} Employee Selected`}
        </IonLabel>
        <IonIcon slot="end" icon={chevronDownOutline} size="small" />
      </IonItem>
      <IonPopover
        ref={popoverRef}
        size="cover"
        className="ion-select-search__popover"
        showBackdrop={false}
        trigger="employeeSelector-action-menu-personPicker-owner"
        side="bottom"
        style={{ '--max-height': '400px' }}
        onIonPopoverWillPresent={() => {
          setSearchQuery('');
          // setTemporarySelection(selectedEmployees)
        }}
      >
        <IonHeader className="ion-no-border">
          <IonSearchbar
            mode="ios"
            className="ion-select-search__popover-searchbar"
            searchIcon={undefined}
            value={searchQuery}
            onIonChange={(e) => setSearchQuery(e.detail.value!)}
          />
        </IonHeader>
        <IonContent className="ion-select-search__popover-content" style={{ maxHeight: '250px' }}>
          <IonList className="employee-list" mode="ios">
            {searchQuery === '' && (
              <IonItem
                lines="none"
                style={{ '--min-height': '0px', borderBottom: '1px solid var(--ion-color-light)', margin: '.5rem 0px' }}
                className="employee-item ion-text-center clickable"
                onClick={() => onSelectAllHandler()}
              >
                <IonLabel className="ion-no-margin font-weight-500" style={{ paddingBottom: '4px' }} color="primary">
                  {temporarySelection.length === myfilteredEmployees.length ? 'Deselect All' : 'Select All'}
                </IonLabel>
              </IonItem>
            )}
            {filteredPeople.map((person) => (
              <IonItem
                key={person.userId}
                lines="none"
                className={`employee-item clickable ${temporarySelection.includes(person.userId) ? 'selected' : ''}`}
                onClick={() => onItemClickedHandler(person.userId)}
              >
                <IonLabel>{`${person.firstName} ${person.lastName}`}</IonLabel>
                {/* <IonLabel>{person.firstName}</IonLabel>  */}
                {/* TODO: Favorite user feature */}
                {/* <IonIcon
                  slot="end"
                  icon={favorites[person.userId] ? star : starOutline}
                  className={favorites[person.userId] ? 'favorite-selected' : 'favorite-unselected'}
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent the click event from bubbling up to the IonItem
                    handleToggleFavorite(person.userId);
                  }}
                /> */}
              </IonItem>
            ))}
          </IonList>
        </IonContent>
        <IonFooter className="ion-no-border" style={{ borderTop: '1px solid var(--ion-color-light)' }}>
          <div className="d-flex ion-justify-content-center">
            <IonButton fill="clear" onClick={onApplyHandler} disabled={temporarySelection.length === 0}>
              <IonText className="font-18 font-weight-600">Apply</IonText>
            </IonButton>
          </div>
        </IonFooter>
      </IonPopover>
    </>
  );
};

export default EmployeeSelectorDropdown;
