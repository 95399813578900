import { GovernorLevelKey, GOVERNOR_LEVEL_LIMIT } from '../../../../constants/constants';
import Banner, { BannerLevel } from '../../../../components/banner/Banner';
import { useTranslation } from 'react-i18next';
import useGovernor from '../../hooks/useGovernor';
import useStripe from '../../../stripe/useStripe';

const PaymentGovernorBanners: React.FC = () => {
  const { t } = useTranslation();
  const { redirectToStripe } = useStripe();
  const govData = useGovernor(GovernorLevelKey.PAST_DUE);
  if (!govData) return <></>;

  const {
    level,
    daysPastLimit,
    writeAccessRestrictedDate,
    isWriteAccessRestricted,
    writeAccessRestrictionWithin7Days,
    isLocked,
  } = govData;

  const limitExceededBanner = (
    <Banner
      level={writeAccessRestrictionWithin7Days ? BannerLevel.WARN : BannerLevel.INFO}
      message={t('banner.governor.pastDue.limitExceeded', {
        count: daysPastLimit!,
        date: writeAccessRestrictedDate?.toLocaleDateString(),
      })}
      buttonText={t('button.paymentInfo.update')}
      onButtonClick={redirectToStripe}
    />
  );

  const writeAccessRestrictedBanner = (
    <Banner
      level={BannerLevel.DANGER}
      message={t('banner.governor.pastDue.writeAccessRestricted', {
        count: daysPastLimit!,
        interpolation: { escapeValue: false },
      })}
      escapeHtml
      buttonText={t('button.paymentInfo.update')}
      onButtonClick={redirectToStripe}
    />
  );

  const organizationLockedBanner = (
    <Banner
      level={BannerLevel.DANGER}
      message={t('banner.governor.pastDue.accountLocked', {
        count: daysPastLimit!,
        interpolation: { escapeValue: false },
      })}
      escapeHtml
      buttonText={t('button.paymentInfo.update')}
      onButtonClick={redirectToStripe}
    />
  );

  const banner = (): JSX.Element => {
    if (level >= GOVERNOR_LEVEL_LIMIT) {
      if (isLocked) {
        return organizationLockedBanner;
      } else if (isWriteAccessRestricted) {
        return writeAccessRestrictedBanner;
      } else {
        return limitExceededBanner;
      }
    } else {
      return <></>;
    }
  };

  return <>{banner()}</>;
};

export default PaymentGovernorBanners;
