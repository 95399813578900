import { IonButton, IonIcon, IonLabel, useIonLoading } from '@ionic/react';
import { Dispatch, SetStateAction, useState } from 'react';
import useFetchIntegration from '../useFetchIntegration';
import { useTranslation } from 'react-i18next';
import { linkOrgToQm } from '../integrationApi';
import { checkmark } from 'ionicons/icons';

interface ConnectAccountButtonProps {
  qmAuthToken: string;
  defaultListOwner: string | undefined;
  linkSuccessMessage: string | undefined;
  setLinkSuccessMessage: Dispatch<SetStateAction<string | undefined>>;
}

const ConnectAccountButton: React.FC<ConnectAccountButtonProps> = ({
  qmAuthToken,
  defaultListOwner,
  linkSuccessMessage,
  setLinkSuccessMessage,
}) => {
  const [isQMConnectionLoading, setIsQMConnectionLoading] = useState<boolean>(false);
  const [linkErrorMessage, setLinkErrorMessage] = useState<string | undefined>();

  const { fetchIntegration } = useFetchIntegration(false);
  const { t } = useTranslation();
  const [presentLoading, dismissLoading] = useIonLoading();

  const attemptLink = async () => {
    console.log('Attempting to establish link with QM account');

    setLinkErrorMessage(undefined);
    setLinkSuccessMessage(undefined);

    /*
    Really these two error cases *should* never be reached through normal use, 
    but it's good to have them here for debugging
    */
    if (!qmAuthToken) {
      setLinkErrorMessage('Authentication to QM not valid');
      return;
    }
    if (!defaultListOwner) {
      setLinkErrorMessage('No default list owner');
      return;
    }

    try {
      setIsQMConnectionLoading(true);
      const result = await linkOrgToQm(qmAuthToken, defaultListOwner);

      console.log('Result of linking to QM account', result);
      if (result.error) {
        switch (result.error) {
          case 200: //SC_OK
            setLinkSuccessMessage('Successfully linked to QM');
            break;
          case 400: //SC_BAD_REQUEST - generally a missing parameter
            setLinkErrorMessage('We were unable to link at this time. Please try again later.');
            break;
          case 403: //SC_FORBIDDEN
            setLinkErrorMessage('You do not have permission to link to this QM account.');
            break;
          case 409: //CONFLICT - SP already linked
            setLinkErrorMessage('This SP appears to already be connected.');
            break;
          default:
            setLinkErrorMessage('We were unable to link at this time. Please try again later.');
            break;
        }

        return;
      }

      setLinkSuccessMessage('Successfully linked to QM');
      setTimeout(() => {
        presentLoading();
        setTimeout(() => {
          dismissLoading();
          fetchIntegration();
        }, 500);
      }, 1500);
    } catch (error) {
      console.error('Error calling API to link to QM', error);
      setLinkErrorMessage('We were unable to link at this time. Please try again later.');
    } finally {
      setIsQMConnectionLoading(false);
    }

    console.log('Finished attempting to establish link with QM account');
  };

  return (
    <>
      <div className="d-flex ion-justify-content-center">
        <IonButton
          size="small"
          className="font-28"
          style={{
            width: '271px',
            transition: 'opacity 0.5s ease',
            '--background': `rgba(var(--ion-color-primary-rgb), ${qmAuthToken ? '0.7' : '1'})`,
          }}
          disabled={!qmAuthToken || !defaultListOwner || isQMConnectionLoading}
          onClick={attemptLink}
        >
          {isQMConnectionLoading ? 'Connecting...' : linkSuccessMessage ? 'Connected!' : 'Connect'}
          {linkSuccessMessage && <IonIcon icon={checkmark} />}
        </IonButton>
      </div>

      <div className="d-flex ion-justify-content-center">
        {linkErrorMessage && (
          <div>
            <IonLabel color="danger">{linkErrorMessage}</IonLabel>
          </div>
        )}
      </div>
    </>
  );
};

export default ConnectAccountButton;
