// recoil/atoms.ts
import { atom } from 'recoil';
import { UserShiftData } from '../../../utils/dataTransformers';

export const dateRangeState = atom<[Date | null, Date | null]>({
  key: 'dateRangeState',
  default: [null, null],
});


export const selectedEmployeesState = atom<string[]>({
  key: 'selectedEmployeesState',
  default: [],
});

export const showReportState = atom<boolean>({
  key: 'showReportState',
  default: false,
});

export const viewState = atom<string>({
  key: 'viewState',
  default: 'details',
});

export const userShiftDataState = atom<UserShiftData[]>({
  key: 'userShiftDataState',
  default: [],
});
