const QmIntegrationInstructions: React.FC = () => {
  return (
    <div className="ion-padding-horizontal">
      <ul>
        <li>Creates task lists in Otuvy Frontline automatically from an inspection’s deficiencies.</li>
        <li>
          <span className="font-weight-600">Note:</span> If you use Work Orders in Otuvy QM, please make sure you have a
          work order status labeled “Completed.” This will allow the completion of deficiency checklists to
          automatically change work orders to “Completed.”
        </li>
      </ul>
    </div>
  );
};

export default QmIntegrationInstructions;
