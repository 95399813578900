import { useEffect, useState } from 'react';
import Page from '../components/Page';
import { IonButton, IonImg, IonText } from '@ionic/react';
import { STRIPE_API_BASE_URL } from '../aws-exports';

import logo from '../assets/images/app-logo.png';
import { streamToString } from '../utils/api/apiUtils';

/**
 * This page is public to everyone,
 * so we can not make API calls that assume there is an authenticated user.
 */
interface OptOutPageProps {}

enum OptOutState {
  CONFIRM = 'CONFIRM',
  LOADING = 'LOADING',
  OPTED_OUT = 'OPTED_OUT',
  //If they have opted out previously, still just show the page and act as a normal opt out
}

const OptOutPage: React.FC<OptOutPageProps> = () => {
  const params = new URLSearchParams(window.location.search);
  const emailHash: string | null = params.get('optOutFor');

  const [email, setEmail] = useState<string>('');
  const [state, setState] = useState<OptOutState>(OptOutState.CONFIRM);

  function obfuscateEmail(email: string): string {
    const atIndex = email.indexOf('@');
    if (atIndex <= 1) return email; // Not enough characters to obscure

    const emailParts = email.split('@');
    const username = emailParts[0];
    const domainParts = emailParts[1].split('.');
    const domain = domainParts.length === 2 ? domainParts[0] : domainParts.slice(0, domainParts.length - 1).join('.');
    const tld = domainParts[domainParts.length - 1];

    const obscuredUsername =
      username.length > 2
        ? username.charAt(0) + '*'.repeat(username.length - 2) + username.charAt(username.length - 1)
        : username;
    const obscuredDomain =
      domain.length > 3 ? domain.charAt(0) + '*'.repeat(domain.length - 2) + domain.charAt(domain.length - 1) : domain;

    return `${obscuredUsername}@${obscuredDomain}.${tld}`;
  }

  const fetchEmailForHash = async (): Promise<void> => {
    //TODO: a state for this situation as well
    const url = STRIPE_API_BASE_URL + `emailLookupByHash?emailHash=${emailHash}`;

    fetch(url)
      .then((response) => {
        if (!response.ok) {
          //TODO: how do we want to handle this situation?
          setEmail('');
          throw new Error('Network response was not ok');
        }
        return response.body;
      })
      .then((body) => streamToString(body))
      .then((data) => {
        if (data) {
          const body = JSON.parse(data);
          setEmail(obfuscateEmail(body.email));
        }
      })
      .catch((error) => {
        console.error('There was a problem with the fetch operation:', error);
      });
  };

  const unsubscribe = async () => {
    setState(OptOutState.LOADING);
    const url = STRIPE_API_BASE_URL + `emailOptOut?emailHash=${emailHash}`;

    const response: Response = await fetch(url);

    if (response.status !== 200) {
      console.error('Failed to opt out', response);
      setState(OptOutState.CONFIRM);
    } else {
      setState(OptOutState.OPTED_OUT);
    }
  };

  useEffect(() => {
    if (!emailHash) {
      console.log('No email address, redirect to the normal login screen');
      // No email address, redirect to the normal login screen
      window.location.href = '/login';
      return;
    }

    fetchEmailForHash();
  }, [emailHash]);

  return (
    <Page title="">
      <div className="d-flex flex-direction-column ion-justify-content-center ion-align-items-center">
        <IonImg style={{ width: '300px', marginBottom: '64px', marginTop: '64px' }} src={logo} />
        {state === OptOutState.CONFIRM || state === OptOutState.LOADING ? (
          <div className="d-flex flex-direction-column ion-align-items-center ion-text-center">
            <div>
              <h1 style={{ fontSize: 'clamp(1.5rem, 3vw + 1rem, 3rem)' }} className="ion-no-margin">
                Click below to unsubscribe from <b>reporting</b> emails.
              </h1>
              <p style={{ fontSize: 'clamp(1rem, 2vw + .5rem, 1.5rem)' }} className="ion-no-margin">
                Note: You'll have to contact support to start recieving these emails again.
              </p>
            </div>
            <IonText className="ion-margin" color="medium">
              <p style={{ fontSize: 'clamp(1.5rem, 2vw + .5rem, 2.25rem)' }}>{email}</p>
            </IonText>
            <IonButton style={{ fontSize: '22px' }} onClick={unsubscribe} disabled={state === OptOutState.LOADING}>
              Unsubscribe
            </IonButton>
          </div>
        ) : state === OptOutState.OPTED_OUT ? (
          <div className="d-flex flex-direction-column ion-align-items-center ion-text-center">
            <div>
              <h1 style={{ fontSize: 'clamp(1.5rem, 3vw + 1rem, 3rem)' }} className="ion-no-margin">
                You’ve been unsubscribed.
              </h1>
              <p style={{ fontSize: 'clamp(1rem, 2vw + .5rem, 1.5rem)' }} className="ion-no-margin">
                Have a great day!
              </p>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </Page>
  );
};

export default OptOutPage;
