import PeopleImportPanel from '../features/people/components/PeopleImportGrid/PeopleImportPanel';
import PeoplePage from './PeoplePage';

const AddPeoplePage: React.FC = () => {
  return (
    <PeoplePage tab="add">
      <PeopleImportPanel />
    </PeoplePage>
  );
};

export default AddPeoplePage;
