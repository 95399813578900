import { IonAlert, IonicSafeString } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { atom, useRecoilState } from 'recoil';

/**
 * Set to `true` for the default error message, or set to a string to display
 * a custom message. Set to `false` to hide the alert.
 */
export const errorAlertMessageKeyState = atom<string | boolean>({
  key: 'errorAlertMessageKeyState',
  default: false,
});

const ErrorAlert: React.FC = () => {
  const [messageKey, setMessageKey] = useRecoilState(errorAlertMessageKeyState);
  const { t } = useTranslation();

  const hasMessage: boolean = !!messageKey;
  const finalMessageKey: string =
    messageKey === false ? '' : typeof messageKey === 'string' ? messageKey : 'errorAlert.default.message';

  return (
    <IonAlert
      isOpen={hasMessage}
      header={t('errorAlert.default.header')!}
      message={new IonicSafeString(t(finalMessageKey))}
      buttons={[{ text: t('button.acknowledge') }]}
      onWillDismiss={() => setMessageKey(false)}
    />
  );
};

export default ErrorAlert;
