import PeoplePage from './PeoplePage';
import PeopleListGrid from '../features/people/components/PeopleListGrid/PeopleListGrid';
const PeopleListPage: React.FC = () => {
  return (
    <PeoplePage tab="view">
      <PeopleListGrid />
    </PeoplePage>
  );
};

export default PeopleListPage;
