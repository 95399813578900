import { IonToolbar } from '@ionic/react';
import GovernorBanners from '../../features/usage/components/GovernorBanners/GovernorBanners';

const AllBanners: React.FC = () => {
  return (
    <IonToolbar style={{ '--min-height': '0px' }}>
      <GovernorBanners />
    </IonToolbar>
  );
};

export default AllBanners;
