import { useSetRecoilState } from 'recoil';
import { errorAlertMessageKeyState } from './ErrorAlert';

const useErrorMessage = (refreshData?: () => void) => {
  const setErrorAlertMessage = useSetRecoilState(errorAlertMessageKeyState);

  const handleError = (messageKey: string | boolean) => {
    setErrorAlertMessage(messageKey);
    if (refreshData) refreshData();
  };
  return {
    handleError,
  };
};

export default useErrorMessage;
