// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files
const resources = {
  en: {
    translation: {
      "addFirstList": "Tap the \"+\" <br /> to add a list",
      "addFirstTask": "Tap the \"+\" <br /> to add a task",
      "addList": "Add List",
      "addTask": "Add Task",
      "banner.governor.pastDue.accountLocked": "Sorry, your company's fees have been unpaid for {{count}} days, so <b>your account is locked</b>. Users can no longer login to the Otuvy app. Please update your card on file to unlock your account.",
      "banner.governor.pastDue.limitExceeded_zero": "Your account is past due. Please update your payment info or your access will be limited on {{date}}.",
      "banner.governor.pastDue.limitExceeded_one": "Your account is {{count}} day past due. Please update your payment info or your access will be limited on {{date}}.",
      "banner.governor.pastDue.limitExceeded_other": "Your account is {{count}} days past due. Please update your payment info or your access will be limited on {{date}}.",
      "banner.governor.pastDue.writeAccessRestricted": "Sorry, your company's fees have been unpaid for {{count}} days, so <b>access has been limited</b> for you and your employees. Please update your card on file to avoid your account being locked.",
      "button.acknowledge": "OK",
      "button.cancel": "Cancel",
      "button.clear": "Clear/Reset",
      "button.delete": "Delete",
      "button.download.template": "Download Import Template (.xls)",
      "button.edit": "Edit",
      "button.paymentInfo.update": "Update Payment Info",
      "button.resendInvite": "Resend Invite",
      "button.user.activate": "Activate",
      "button.user.add_zero": "Add",
      "button.user.add_one": "Add {{count}} User",
      "button.user.add_other": "Add {{count}} Users",
      "button.user.deactivate": "Deactivate",
      "cancel": "Cancel",
      "complete": "Complete",
      "completed": "Completed",
      "completeTask": "Complete Task",
      "completeTasks": "Complete Tasks",
      "completeList": "Complete List",
      "confirmation.delete.image.confirm": "Delete image",
      "confirmation.delete.image.header": "Delete Image",
      "confirmation.delete.image.message": "You will not be able to recover this image once deleted.",
      "confirmation.delete.list.confirm": "Delete the list",
      "confirmation.delete.list.confirm_one": "Delete the list",
      "confirmation.delete.list.confirm_other": "Delete the lists",
      "confirmation.delete.list.header": "Delete this list?",
      "confirmation.delete.list.header_one": "Delete this list?",
      "confirmation.delete.list.header_other": "Delete these lists?",
      "confirmation.delete.list.message": "All of its tasks (and their pics, notes, etc...) will be deleted as well.",
      "confirmation.delete.list.message_one": "All of its tasks (and their pics, notes, etc...) will be deleted as well.",
      "confirmation.delete.list.message_other": "All of their tasks (and their pics, notes, etc...) will be deleted as well.",
      "confirmation.delete.person.confirm1": "Yes, transfer their lists to me",
      "confirmation.delete.person.confirm2": "NO, delete their lists",
      "confirmation.delete.person.header": "Delete User",
      "confirmation.delete.person.message": "Would you like to keep this user's lists and transfer them to yourself?<br/><br/>(If not, the lists will be deleted.)<br/><br/>(Note: you can go change the list owners first, on the Lists page.)",
      "confirmation.delete.task.confirm": "Delete task",
      "confirmation.delete.task.header": "Delete Task",
      "confirmation.delete.task.message": "Are you sure? (All pics, notes, etc... will be deleted as well.)",
      "confirmation.delete.task.generic.confirm": "Delete",
      "confirmation.delete.task.generic.header": "Confirm Deletion",
      "confirmation.delete.task.generic.message": "Are you sure? (All pics, notes, etc... will be deleted as well.)",
      "contactSupport": "Contact Support",
      "darkMode": "Dark Mode",
      "delete": "Delete",
      "deleteTasks": "Delete Tasks",
      "deselectAll": "Deselect All",
      "done": "Done",
      "duplicate": "Duplicate",
      "duplicateTask": "Duplicate Task",
      "duplicateTasks": "Duplicate Tasks",
      "duplicateList": "Duplicate List",
      "edit": "Edit",
      "email": "Email",
      "enum.status.active": "Active",
      "enum.status.inactive": "Inactive",
      "enum.userRole.admin": "Admin",
      "enum.userRole.employee": "Employee",
      "enum.userRole.super_admin": "Super-Admin",
      "error.email.duplicate": "Email addresses must be unique",
      "error.email.invalid": "Valid email address required",
      "error.generic.required": "{{field}} is required",
      "error.language.unsupported": "Only English and Spanish are currently supported",
      "error.list.listName.required": "No List Name",
      "error.list.listOwner.noMatchingEmail": "\"List Owner\" email doesn’t match any users",
      "error.list.listOwner.required": "No List Owner",
      "error.list.sharedWith.noMatchingEmail": "\"Share List with\" email doesn’t match any users",
      "error.name.required": "Name is Required",
      "error.phone.invalid": "Phone appears to be invalid",
      "error.phone.required": "Phone is Required",
      "error.signin.incorrectOTP": "Incorrect. Please double check the code from your email and enter it again.",
      "errorAlert.default.header": "Whoops!",
      "errorAlert.default.message": "We ran into an error on our side. Sorry about that! If it happens multiple times, please feel free to <a href='mailto:support@otuvy.com'>Contact Support</a>.",
      "errorAlert.generic.header.error": "Error",
      "errorAlert.generic.header.errors": "Error(s)",
      "errorAlert.generic.contactSupport": "We apologize for the inconvenience. An error has occurred. In order to resolve this error, please <a href=\"mailto:support@otuvy.com\">Contact Support</a>.",
      "errorAlert.generic.happensAgainContactSupport": "If it happens multiple times, please feel free to <a href=\"mailto:support@otuvy.com\">Contact Support</a>.",
      "errorAlert.generic.tryAgain": "We apologize, an error has occurred on our side. Please wait a few moments and then try again.",
      "errorAlert.import.table.duplicates.email.header": "Duplicate emails in your data",
      "errorAlert.import.table.duplicates.email.message1": "The following emails are duplicated in your data:",
      "errorAlert.import.table.duplicates.email.message2": "Please correct (or remove) the duplicates and try again.",
      "errorAlert.import.table.inUse.email.header": "Emails exist already",
      "errorAlert.import.table.inUse.email.message1": "The following employees are already working for another organization:",
      "errorAlert.import.table.inUse.email.message2": "Please have them ask to be removed from the other organization first, or use a different email for them.",
      "errorAlert.import.table.fixThem": "Please correct any errors (usually highlighted in red), then try to submit it again.",
      "errorPage": "Hi, I'm the Error Page.",
      "errorPage.backIntoIt": "Let's get back into it.",
      "errorPage.sorry": "Sorry about that, we had a hiccup on our end. Don't worry, your work should save automatically.",
      "feedback.list.completed": "Great work! You've completed the list.",
      "feedback.list.import.success": "{{count}} lists uploaded successfully!",
      "feedback.person.added": "User added! See your invoice for updates.<br/><br/>An invitation has been sent to their email.",
      "feedback.person.deleted": "User removed. Your bill will be reduced accordingly.",
      "feedback.person.edited": "Changes Saved",
      "feedback.person.imported.acknowledge": "View People",

      "feedback.person.imported.created.message_one": "One user added/updated! See your invoice for updates.<br/><br/>An invitation has been sent to their email.",
      "feedback.person.imported.created.message_other": "{{count}} users added/updated! See your invoice for updates.<br/><br/>Invitations have been sent to their emails.",

      "feedback.person.imported.edited.message_one": "One user was edited.",
      "feedback.person.imported.edited.message_other": "{{count}} users were edited",

      "feedback.person.imported.reactivated.message_one": "One user was reactivated.",
      "feedback.person.imported.reactivated.message_other": "{{count}} users were reactivated",

      "feedback.person.welcomeEmailsResent_one": "{{count}} Email Sent!",
      "feedback.person.welcomeEmailsResent_other": "{{count}} Emails Sent!",
      "feedback.success.withFeeling": "Success!",
      "feedback.task.completed": "Great work! Task completed: \"{{taskName}}\"",
      "grid.column.header.user.email": "Email",
      "grid.column.header.user.firstName": "First Name",
      "grid.column.header.user.language": "Language",
      "grid.column.header.user.lastName": "Last Name",
      "home": "Home",
      "info.amountSelected": "{{count}} Selected",
      "label.list.list": "List",
      "label.list.owner": "Owner",
      "label.selectAllCompletedTasks": "Select all completed tasks",
      "label.selectAllTasks": "Select all tasks",
      "label.task.instructions": "Instructions",
      "label.task.name": "Task",
      "label.task.taskName": "Task",
      "label.task.notes": "Notes",
      "label.usage.admins.requiredAmount": "Admins (1 required)",
      "label.usage.employees": "Employees",
      "label.usage.lists": "Lists",
      "label.usage.storage": "Storage Space",
      "label.usage.tasks": "Tasks",
      "label.usage.totalUsers": "Total Users",
      "label.user.actions": "Actions",
      "label.user.email": "Email",
      "label.user.firstName": "First Name",
      "label.user.inactive": "Inactive",
      "label.user.language": "Language",
      "label.user.lastName": "Last Name",
      "label.user.name": "Name",
      "label.user.role": "Role",
      "language": "Language",
      "language.name.en": "English",
      "language.name.es": "Spanish",
      "listName": "Name of List",
      "list.rename": "Rename List",
      "lists": "Lists",
      "loading": "Loading...",
      "loading.message.user.import": "Importing your users.",
      "loading.message.list.import": "Creating your lists...",
      "logout": "Log out",
      "navigation.add": "Add",
      "navigation.import": "Import",
      "navigation.view": "View",
      "newCodeRequest": "Send me a new code",
      "newVerificationCodeSent.header": "We've sent the code.",
      "newVerificationCodeSent.message": "Please check your email inbox for the new code. It may have landed in your junk folder. It does that sometimes.",
      "offlineNoticeHeader": "No internet :(",
      "offlineNoticeMessage": "We should reconnect soon. To find out if it's not just our app, you can... <br/> <br/> <ul><li> Check your phone settings</li><li>Check other Apps</li><li>Check WiFi signal strength</li></ul><br/>And don't worry we save your work as we go!",
      "otpCode": "Code",
      "otpPrompt": "We'll send a code to your email so you can start using Otuvy Frontline",
      "otpSent": "We've sent a verification code to your email. Please enter it below.",
      "phone": "Phone",
      "page.listImport.dropzone.message": "Drag your Excel file here, or click to upload one.",
      "page.listImport.dropzone.message2": "(.XLS or .XLSX files only)",
      "page.listImport.dialog.error.fileType.header": "Error: .XLS or .XLSX files only",
      "page.listImport.dialog.error.fileType.message": "<p>To upload successfully, your data will need to be in an Excel file format (.XLS or .XLSX).</p><p>If you don’t have Excel, try copying your data into a Google Sheet then downloading it as an Excel file: File > Download > Microsoft Excel (.xlsx)</p><p>If that doesn't work and you still have questions, please feel free to contact support at <a href='mailto:support@otuvy.com'>support@otuvy.com</a> or <a href='tel:8018743443'>801.874.3443</a>.</p>",
      "page.listImport.panel.instructions.header": "Preparing your Lists for Import",
      "page.listImport.panel.instructions.instruction1": "1. <strong>Download</strong> the Template.",
      "page.listImport.panel.instructions.instruction1.sub": "<strong>Did you know?</strong> You can export a Google Sheet as an Excel File!",
      "page.listImport.panel.instructions.instruction2": "2. <strong>Copy/paste</strong> your data into it, and save the file.",
      "page.listImport.panel.instructions.instruction2.sub1.description": "The name of the list of tasks (Tip: Customers may see this.)",
      "page.listImport.panel.instructions.instruction2.sub1.label": "List Name",
      "page.listImport.panel.instructions.instruction2.sub2.description": "Email address - the person responsible for the list.",
      "page.listImport.panel.instructions.instruction2.sub2.label": "List Owner",
      "page.listImport.panel.instructions.instruction2.sub3.description": "Email addresses - other people who should see the list.",
      "page.listImport.panel.instructions.instruction2.sub3.label": "Share List with",
      "page.listImport.panel.instructions.instruction2.sub4.description": "Enter one task per row, going down.",
      "page.listImport.panel.instructions.instruction2.sub4.label": "Tasks",
      "page.listImport.panel.instructions.instruction3": "3. <strong>Upload</strong> the saved file above.",
      "page.listImport.panel.validation.header": "Your import data needs attention.",
      "page.listImport.panel.validation.instruction1": "1. Fix the following errors in your Excel file:",
      "page.listImport.panel.validation.instruction2": "2. <strong>Upload</strong> the saved file (with the fixes you just made) below.",
      "page.listImport.panel.validation.message": "Your lists were not imported due to errors that need to be fixed in your data.",
      "page.listImport.panel.validation.table.header.columnName": "Column",
      "page.listImport.panel.validation.table.header.email": "Incorrect Email",
      "page.listImport.panel.validation.table.header.errorMessage": "Error",
      "page.listImport.panel.validation.table.header.listName": "List Name",
      "picturesAbbreviated": "Pics",
      "placeholder.changeOwner": "Change Owner to:",
      "placeholder.email": "jane.doe@example.com",
      "placeholder.firstName": "Jane",
      "placeholder.generic": "Tap to enter text",
      "placeholder.language": "Select language",
      "placeholder.lastName": "Doe",
      "placeholder.name": "Jane Doe",
      "placeholder.otp": "Your verification code",
      "placeholder.selectOne": "Select one...",
      "product.version": "v.",
      "requestToWait": "Please wait...",
      "resendingInvite": "Resending Invite...",
      "select": "Select",
      "selectAll": "Select All",
      "sendCode": "Send Verification Code",
      "settings": "Settings",
      "status": "Status",
      "submit": "Submit",
      "tasks": "Tasks",
      
      "title.billing": "Billing",
      "title.lists": "Lists",
      "title.people": "People",
      "title.settings": "Settings",
      "title.timeTracking": "Time Tracking",
      "title.usage": "Usage",
      "title.integrations.qm": "Otuvy QM Integration",
      "title.integrations.qm.settings": "Settings",
      "title.integrations.qm.locations": "Locations",
      "undefinedList.name": "Undefined List",
      "unit.gigabyte": "GB",
      "unit.unlimited": "Unlimited",
      "wifiOnly": "Use Wifi Only",
      "wifiOnlyHint": "If you want the app to use your cell phone data, leave this option disabled. If you want to preserve your cell data, enable it.",
    }
  },
  es: {
    translation: {
      "addFirstList": "Presione \"+\" <br /> para agregar una lista",
      "addFirstTask": "Presione \"+\" <br /> para agregar una tarea",
      "addList": "Agregar Lista",
      "addTask": "Agregar Tarea",
      "button.acknowledge": "Aceptar",
      "button.cancel": "Cancelar",
      "button.resendInvite": "Reenviar invitación",
      "cancel": "Cancelar",
      "complete": "Completar",
      "completed": "Completadas",
      "completeTask": "Completar Tarea",
      "completeTasks": "Completar Tareas",
      "completeList": "Completar Lista",
      "confirmation.delete.image.confirm": "Eliminar imagen",
      "confirmation.delete.image.header": "Eliminar Imagen",
      "confirmation.delete.image.message": "No podrá recuperar esta imagen una vez eliminada.",
      "confirmation.delete.list.confirm": "Eliminar la lista",
      "confirmation.delete.list.confirm_one": "Eliminar la lista",
      "confirmation.delete.list.confirm_other": "Eliminar las listas",
      "confirmation.delete.list.header": "¿Eliminar esta lista?",
      "confirmation.delete.list.header_one": "¿Eliminar esta lista?",
      "confirmation.delete.list.header_other": "¿Eliminar estas listas?",
      "confirmation.delete.list.message": "Todas sus tareas (y sus fotos, notas, etc.) también se eliminarán.",
      "confirmation.delete.list.message_one": "Todas sus tareas (y sus fotos, notas, etc.) también se eliminarán.",
      "confirmation.delete.list.message_other": "Todas sus tareas (y sus fotos, notas, etc.) también se eliminarán.",
      "confirmation.delete.task.confirm": "Eliminar tarea",
      "confirmation.delete.task.header": "Eliminar Tarea",
      "confirmation.delete.task.message": "¿Está seguro? (Todas las fotos, notas, etc. también se eliminarán.)",
      "confirmation.delete.task.generic.confirm": "Eliminar",
      "confirmation.delete.task.generic.header": "Confirmar Eliminación",
      "confirmation.delete.task.generic.message": "¿Está seguro? (Todas las fotos, notas, etc. también se eliminarán.)",
      "contactSupport": "Contactar a Soporte",
      "darkMode": "Modo Oscuro",
      "delete": "Eliminar",
      "deleteTasks": "Eliminar Tareas",
      "deselectAll": "Deseleccionar Todo",
      "done": "Hecho",
      "duplicate": "Duplicar",
      "duplicateTask": "Duplicar Tarea",
      "duplicateTasks": "Duplicar Tareas",
      "duplicateList": "Duplicar Lista",
      "edit": "Editar",
      "email": "Correo electrónico",
      "error.name.required": "El nombre es obligatorio",
      "error.phone.invalid": "El teléfono parece no ser válido",
      "error.phone.required": "El teléfono es obligatorio",
      "error.signin.incorrectOTP": "Incorrecto. Verifique dos veces el código de su correo electrónico e ingréselo nuevamente.",
      "errorAlert.default.header": "Ups!",
      "errorAlert.default.message": "Nos encontramos con un error de nuestro lado. ¡Lo lamentamos! Si sucede varias veces, no dude en <a href='mailto:support@otuvy.com'>Ponerse en Contacto con el Soporte</a>.",
      "errorPage": "Hola, soy la Página de Error.",
      "errorPage.backIntoIt": "Retomemos.",
      "errorPage.sorry": "Lo sentimos, tuvimos un contratiempo de nuestra parte. No se preocupe, su trabajo debería guardarse automáticamente.",
      "feedback.list.completed": "¡Buen trabajo! Ha completado la lista.",
      "feedback.task.completed": "¡Buen trabajo! Tarea completada: \"{{taskName}}\"",
      "home": "Inicio",
      "label.selectAllCompletedTasks": "Seleccionar todas las tareas completadas",
      "label.selectAllTasks": "Seleccionar todas las tareas",
      "label.task.instructions": "Instrucciones",
      "label.task.name": "Tarea",
      "label.task.taskName": "Tarea",
      "label.task.notes": "Notas",
      "label.user.name": "Nombre",
      "language": "Idioma",
      "language.name.en": "Inglés",
      "language.name.es": "Español",
      "listName": "Nombre de la Lista",
      "list.rename": "Cambiar Nombre",
      "lists": "Listas",
      "loading": "Cargando...",
      "logout": "Cerrar sesión",
      "newCodeRequest": "Enviarme un nuevo código",
      "newVerificationCodeSent.header": "Hemos enviado el código.",
      "newVerificationCodeSent.message": "Revise su bandeja de entrada para obtener el nuevo código. Es posible que haya llegado a su carpeta de correo no deseado. A veces, pasa eso.",
      "offlineNoticeHeader": "Sin internet :(",
      "offlineNoticeMessage": "Deberíamos reconectarnos pronto. Para saber si no es solo nuestra aplicación, puede... <br/><br/><ul><li>Verificar la configuración de su teléfono</li><li>Verificar otras aplicaciones</li><li>Verificar la intensidad de la señal wifi</li></ul><br/>Y no se preocupe, ¡guardamos su trabajo a medida que avanzamos!",
      "otpCode": "Código",
      "otpPrompt": "Le enviaremos un código a su correo electrónico para que pueda comenzar a usar Otuvy Frontline",
      "otpSent": "Hemos enviado un código de verificación a su correo electrónico. Ingréselo a continuación.",
      "phone": "Teléfono",
      "picturesAbbreviated": "Fotos",
      "placeholder.email": "maria.sanchez@example.com",
      "placeholder.generic": "Tocar para ingresar texto",
      "placeholder.name": "María Sánchez",
      "placeholder.otp": "Su código de verificación",
      "product.version": "v.",
      "requestToWait": "Espere...",
      "resendingInvite": "Reenviar invitación...",
      "select": "Seleccionar",
      "selectAll": "Seleccionar Todo",
      "sendCode": "Enviar Código de Verificación",
      "settings": "Ajustes",
      "status": "Estado",
      "submit": "Enviar",
      "tasks": "Tareas",
      "title.lists": "Listas",
      "undefinedList.name": "Undefined List",
      "wifiOnly": "Usar Solo Wifi",
      "wifiOnlyHint": "Si desea que la aplicación use los datos de su teléfono celular, deje esta opción desactivada. Si desea conservar los datos de su celular, actívela.",
    }
  }
}

export default resources
