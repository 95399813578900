import { IonIcon } from '@ionic/react';
import { checkmarkCircleOutline } from 'ionicons/icons';

interface StepHeaderProps {
  header: string | JSX.Element;
  isCompleted: boolean;
  spaceBetween?: boolean;
}

const StepHeader: React.FC<StepHeaderProps> = ({ header, isCompleted, spaceBetween = true }) => {
  return (
    <>
      <div className="d-flex ion-align-items-center">
        {typeof header === 'string' ? <h1 className="ion-no-margin">{header}</h1> : header}
        <IonIcon
          color="primary"
          className={spaceBetween ? 'ion-margin-start' : ''}
          style={{ fontSize: '3rem', opacity: isCompleted ? '1' : '0.1', transition: 'opacity 0.5s ease' }}
          icon={checkmarkCircleOutline}
        />
      </div>
    </>
  );
};

export default StepHeader;
