// components/ViewSelector.tsx
import React from 'react';
import { IonRadioGroup, IonRadio, IonItem, IonLabel, IonButton } from '@ionic/react';
import StepsLabel from './StepsLabel';
import { useRecoilValue, useRecoilState } from 'recoil';
import { selectedEmployeesState, showReportState, viewState } from '../recoil/atoms';

const ViewSelector: React.FC = () => {
  const selectedEmployees = useRecoilValue(selectedEmployeesState);
 
  const [view, setView] = useRecoilState(viewState);



  return (
    <div>
      <StepsLabel stepTitle="3. Select View" StepSubtitle="What do you want to see?" />
      <IonRadioGroup value={view} onIonChange={e => setView(e.detail.value)}>
        <IonItem lines="none" disabled={!showReportState} style={{ '--min-height': '0px' }}>
          <IonLabel>Details (Separate reports for each selected employee)</IonLabel>
          <IonRadio slot="start" value="details"   />
        </IonItem>
        <IonItem lines="none" disabled={!showReportState} style={{ '--min-height': '0px' }}>
          <IonLabel>Overall (One report for all selected employees)</IonLabel>
          <IonRadio slot="start" value="overall"  />
        </IonItem>
      </IonRadioGroup>
      {/* <IonButton expand="block" disabled={selectedEmployees.length === 0} onClick={onGenerateReport}>
        Generate Report
      </IonButton> */}
    </div>
  );
};

export default ViewSelector;
