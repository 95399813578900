import './PeopleListGrid.css';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Person, getAllPeople, resendWelcomeEmails } from '../../peopleApi';
import LoadingMessage from '../../../../components/overlays/LoadingMessage';
import useEditPersonDialog from '../EditPersonDialog/useEditPersonDialog';
import { FlexGrid, FlexGridCellTemplate, FlexGridColumn } from '@grapecity/wijmo.react.grid';
import { FlexGridSearch } from '@grapecity/wijmo.react.grid.search';
import useAddPersonDialog from '../AddPersonDialog/useAddPersonDialog';
import { IonButton, IonCheckbox, IonText, useIonAlert, useIonLoading } from '@ionic/react';
import useGraphqlQuery from '../../../../hooks/useGraphqlQuery';
import { useRecoilValue } from 'recoil';
import { writeAccessRestrictedState } from '../../../../App';
import useToast from '../../../../hooks/useToast';
import { CollectionView, PageChangingEventArgs } from '@grapecity/wijmo';
import { CollectionViewNavigator } from '@grapecity/wijmo.react.input';
import { AuthCache, UserGroups } from '@otuvy/auth';
import CustomGridHeader from './CustomGridHeader';
import { toggleAdminPermission } from '../../permissionsApi';

const PeopleListGrid: React.FC = () => {
  const isWriteAccessRestricted = useRecoilValue(writeAccessRestrictedState);

  const { data, refreshData, isLoading } = useGraphqlQuery(getAllPeople);
  const [collectionViewData, setCollectionViewData] = useState<CollectionView<Person[]> | null>(null);
  const [pageNumber, setPageNumber] = useState(0);
  const { setCurrentPersonToEdit } = useEditPersonDialog(refreshData);
  const { present: presentAddPersonDialog } = useAddPersonDialog(refreshData, data!);
  const [presentPermissionDialog] = useIonAlert();

  const gridRef = useRef<FlexGrid | null>(null);
  const searchRef = useRef<any>();
  const [presentToast] = useToast();
  const [presentLoading, dismissLoading] = useIonLoading();

  useEffect(() => {
    rebuildCollectionView();
    if (gridRef.current && searchRef.current) {
      let theGrid = gridRef.current.control;
      let theSearch = searchRef.current.control;
      theSearch.grid = theGrid;
    }
  }, [gridRef, data]);

  const { t } = useTranslation();

  const rebuildCollectionView = () => {
    setCollectionViewData(
      new CollectionView(data ?? [], {
        trackChanges: true,
        pageSize: 50,
        pageChanged: (cv: CollectionView<Person[]>, { newPageIndex }: PageChangingEventArgs) => {
          if (pageNumber !== newPageIndex) setPageNumber(newPageIndex);
        },
      })
    );
  };

  const initialized = (grid: any) => {
    grid.rows.defaultSize = 48;
  };

  const resendEmails = async (userIds: string[]) => {
    await presentLoading({
      message: t('resendingInvite'),
    });
    const messageText = t('feedback.person.welcomeEmailsResent', { count: userIds.length });
    await resendWelcomeEmails(userIds);
    await dismissLoading();
    presentToast(messageText, 1800, 'bottom', 'dark');
  };

  const onPermissionChange = (personId: 'string', permissionType: any, event: any) => {
    console.log('Change permission for personId:', personId, 'PermissionType:', permissionType, event.target.checked);

    const hasShownPermissionDialog = sessionStorage.getItem('hasShownPermissionDialog');
    if (!hasShownPermissionDialog) {
      presentPermissionDialog({
        header: 'Permission Change',
        message: `It might take a few minutes for this change to take effect.`,
        buttons: ['Ok'],
      });
      sessionStorage.setItem('hasShownPermissionDialog', 'true');
    }

    // Call the API to update the permission
    toggleAdminPermission(permissionType, personId).then((result) => {
      console.log('Permission changed:', result);
    });
    // Update the collectionViewData
    // In this grid we have previously update the the collectionView by calling refreshDate
    // If we want to avoid making the extra API call, we can update the collectionViewData directly
    // This would be done by updating the "data" state object returned by the useGraphqlQuery hook
  };

  const editButtonText = t('button.edit');
  const flexGridWidth = gridRef.current?.control?.hostElement.scrollWidth; // Needed for the custom grid header

  return (
    <>
      <div style={{ maxWidth: 'fit-content' }}>
        {isLoading && <LoadingMessage />}
        <>
          <div className="d-flex ion-justify-content-end ion-align-items-center">
            <FlexGridSearch ref={searchRef} placeholder="Search" />
            <div>
              <IonButton disabled={isWriteAccessRestricted} routerLink="/people/add">
                Add Multiple People
              </IonButton>
              <IonButton disabled={isWriteAccessRestricted} onClick={() => presentAddPersonDialog()}>
                Add a Person
              </IonButton>
            </div>
          </div>
          <div className="container-fluid" style={{ width: '100%', overflowX: 'auto', marginTop: '1rem' }}>
            <CustomGridHeader flexGridWidth={flexGridWidth} />
            <FlexGrid
              id="gridHeight"
              className="ion-margin-bottom"
              itemsSource={collectionViewData}
              ref={gridRef}
              allowSorting={true}
              initialized={initialized}
              autoGenerateColumns={false}
              style={{ width: 'max-content' }}
              headersVisibility={'Column'}
            >
              <FlexGridColumn
                cssClassAll="center-grid-cell"
                header="First Name"
                binding="firstName"
                width={180}
                isReadOnly={true}
              ></FlexGridColumn>
              <FlexGridColumn
                cssClassAll="center-grid-cell"
                header="Last Name"
                binding="lastName"
                width={180}
                isReadOnly={true}
              ></FlexGridColumn>
              <FlexGridColumn
                cssClassAll="center-grid-cell"
                header="Email"
                binding="email"
                width={250}
                isReadOnly={true}
              >
                <FlexGridCellTemplate
                  cellType="Cell"
                  template={(context: any) => {
                    return (
                      <div style={{ textAlign: 'center', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {context.item.groups.includes(UserGroups.SUPER_ADMIN) && (
                          <IonText className="font-weight-400 font-18">*</IonText>
                        )}
                        <IonText>{context.item.email}</IonText>
                      </div>
                    );
                  }}
                />
              </FlexGridColumn>
              <FlexGridColumn
                cssClassAll="people-list-grid_column-header"
                header="People/Billing"
                width={120}
                isReadOnly={true}
              >
                <FlexGridCellTemplate
                  cellType="Cell"
                  template={(context: any) => {
                    return (
                      <div className="d-flex ion-align-items-center ion-justify-content-center">
                        <IonCheckbox
                          checked={
                            context.item.groups.includes(UserGroups.PEOPLE_MANAGER) ||
                            context.item.groups.includes(UserGroups.SUPER_ADMIN) ||
                            context.item.groups.includes(UserGroups.OTUVY_TECH_SUPPORT)
                          }
                          disabled={
                            context.item.groups.includes(UserGroups.SUPER_ADMIN) ||
                            context.item.groups.includes(UserGroups.OTUVY_TECH_SUPPORT) ||
                            context.item.userId === AuthCache.getCurrentUserId()
                          }
                          onClick={(event) => onPermissionChange(context.item.userId, UserGroups.PEOPLE_MANAGER, event)}
                        />
                      </div>
                    );
                  }}
                />
              </FlexGridColumn>
              <FlexGridColumn
                cssClassAll="people-list-grid_column-header"
                header="Manage All Lists Online"
                width={100}
                isReadOnly={true}
              >
                <FlexGridCellTemplate
                  cellType="Cell"
                  template={(context: any) => {
                    return (
                      <div className="d-flex ion-align-items-center ion-justify-content-center">
                        <IonCheckbox
                          checked={
                            context.item.groups.includes(UserGroups.LIST_MANAGER) ||
                            context.item.groups.includes(UserGroups.SUPER_ADMIN) ||
                            context.item.groups.includes(UserGroups.OTUVY_TECH_SUPPORT)
                          }
                          disabled={
                            context.item.groups.includes(UserGroups.SUPER_ADMIN) ||
                            context.item.groups.includes(UserGroups.OTUVY_TECH_SUPPORT) ||
                            context.item.userId === AuthCache.getCurrentUserId()
                          }
                          onClick={(event) => onPermissionChange(context.item.userId, UserGroups.LIST_MANAGER, event)}
                        />
                      </div>
                    );
                  }}
                />
              </FlexGridColumn>
              <FlexGridColumn
                cssClassAll="people-list-grid_column-header"
                header="Time Tracking"
                width={100}
                isReadOnly={true}
              >
                <FlexGridCellTemplate
                  cellType="Cell"
                  template={(context: any) => {
                    return (
                      <div className="d-flex ion-align-items-center ion-justify-content-center">
                        <IonCheckbox
                          checked={
                            context.item.groups.includes(UserGroups.TIME_TRACKING) ||
                            context.item.groups.includes(UserGroups.SUPER_ADMIN) ||
                            context.item.groups.includes(UserGroups.OTUVY_TECH_SUPPORT)
                          }
                          disabled={
                            context.item.groups.includes(UserGroups.SUPER_ADMIN) ||
                            context.item.groups.includes(UserGroups.OTUVY_TECH_SUPPORT) ||
                            context.item.userId === AuthCache.getCurrentUserId()
                          }
                          onClick={(event) => onPermissionChange(context.item.userId, UserGroups.TIME_TRACKING, event)}
                        />
                      </div>
                    );
                  }}
                />
              </FlexGridColumn>
              <FlexGridColumn
                cssClassAll="people-list-grid_column-header"
                header="QM Integration"
                width={100}
                isReadOnly={true}
              >
                <FlexGridCellTemplate
                  cellType="Cell"
                  template={(context: any) => {
                    return (
                      <div className="d-flex ion-align-items-center ion-justify-content-center">
                        <IonCheckbox
                          checked={
                            context.item.groups.includes(UserGroups.QM_INTEGRATION_MANAGER) ||
                            context.item.groups.includes(UserGroups.SUPER_ADMIN) ||
                            context.item.groups.includes(UserGroups.OTUVY_TECH_SUPPORT) ||
                            context.item.userId === AuthCache.getCurrentUserId()
                          }
                          disabled={
                            context.item.groups.includes(UserGroups.SUPER_ADMIN) ||
                            context.item.groups.includes(UserGroups.OTUVY_TECH_SUPPORT) ||
                            context.item.userId === AuthCache.getCurrentUserId()
                          }
                          onClick={(event) =>
                            onPermissionChange(context.item.userId, UserGroups.QM_INTEGRATION_MANAGER, event)
                          }
                        />
                      </div>
                    );
                  }}
                />
              </FlexGridColumn>
              <FlexGridColumn
                cssClassAll="center-grid-cell"
                header="Language"
                binding="language"
                width={90}
                isReadOnly={true}
              ></FlexGridColumn>
              <FlexGridColumn cssClassAll="center-grid-cell" header="Actions" isReadOnly={true} width={125}>
                <FlexGridCellTemplate
                  autoSizeRows={false}
                  cellType="Cell"
                  template={(context: any) => {
                    return (
                      <div className="ion-text-center">
                        {!context.item.groups.includes(UserGroups.OTUVY_TECH_SUPPORT) ? ( //Should tech support be able to edit themselves?
                          <IonButton
                            className="ion-no-margin"
                            disabled={isWriteAccessRestricted}
                            onClick={() => setCurrentPersonToEdit(context.item)}
                          >
                            {editButtonText}
                          </IonButton>
                        ) : (
                          ''
                        )}
                      </div>
                    );
                  }}
                />
              </FlexGridColumn>
              <FlexGridColumn
                cssClassAll="ion-justify-content-center"
                header="Registered"
                width={200}
                isReadOnly={true}
              >
                <FlexGridCellTemplate
                  cellType="Cell"
                  template={(context: any) => {
                    return (
                      <div>
                        {!context.item.isRegistered && !context.item.groups.includes(UserGroups.OTUVY_TECH_SUPPORT) ? (
                          <div className="d-flex ion-align-items-center ion-justify-content-center">
                            <IonText class="ion-margin-end">No</IonText>
                            <IonButton
                              className="ion-no-margin"
                              disabled={isWriteAccessRestricted}
                              onClick={() => resendEmails([context.item.userId])}
                            >
                              {t('button.resendInvite')}
                            </IonButton>
                          </div>
                        ) : (
                          'Yes'
                        )}
                      </div>
                    );
                  }}
                />
              </FlexGridColumn>
            </FlexGrid>
          </div>
          <div className="d-flex ion-justify-content-center">
            <CollectionViewNavigator
              headerFormat="Page {currentPage:n0} of {pageCount:n0}"
              byPage={true}
              cv={collectionViewData}
            />
          </div>
          <div>
            <p className="ion-no-margin font-16 font-weight-400">
              <span className="font-weight-600">Note:</span> (*) Next to the email address indicates a user with super
              admin access.
            </p>
          </div>
        </>
      </div>
    </>
  );
};

export default PeopleListGrid;
