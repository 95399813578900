import { IonAlert, IonicSafeString } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { AgreementStatus, AgreementStatusTypes, AgreementTypes } from '../../constants/constants';
import { useRecoilValue } from 'recoil';
import { agreementState } from '../../App';
import { useEffect, useState } from 'react';
import { graphql } from '../../utils/api/apiUtils';
import useLogout from '../login/useLogout';

const Agreement: React.FC = () => {
  const { logOut } = useLogout();
  const { t } = useTranslation();
  const agreements: AgreementStatus[] = useRecoilValue(agreementState);
  //TODO: have some sort of "alreadyAgreed" kill switch to prevent the popup from reopening in case of poor internet
  const [updated, setUpdated] = useState<boolean>();
  const [showAgreement, setShowAgreement] = useState<boolean>(false);

  useEffect(() => {
    setShowAgreement(needToAgree());
  }, [agreements]);

  const needToAgree = () => {
    let result = false;
    if (agreements) {
      agreements.forEach((a) => {
        //TODO: handle reacceptance slightly different than first time acceptance?
        result =
          result ||
          a.agreementStatus === AgreementStatusTypes.UNACCEPTED ||
          a.agreementStatus === AgreementStatusTypes.REACCEPTANCE_REQUIRED;
        if (a.agreementStatus === AgreementStatusTypes.REACCEPTANCE_REQUIRED) setUpdated(true);
      });
    }
    return result;
  };

  const agree = async () => {
    const agreementMutation = `
        mutation signAgreementMut(
            $agreementTypes:[String!]!
            $source:String
        ){
          signAgreement(
              agreementTypes: $agreementTypes
              source: $source
          )      
        }
    `;

    const variables = {
      agreementTypes: [`${AgreementTypes.TERMS_AND_CONDITIONS}`, `${AgreementTypes.PRIVACY_POLICY}`],
      source: 'Admin', //TODO: add app version and OS type?
    };

    const success = await graphql<boolean>(agreementMutation, variables);
    //TODO: handle failure
  };

  const dontAgree = () => {
    logOut();
  };

  return (
    <IonAlert
      backdropDismiss={false}
      cssClass="agreement-confirmation"
      header={updated ? 'Updated' : 'Welcome!'}
      message={
        // FIXME: i18n
        updated
          ? new IonicSafeString(
              `Our <a class="ion-home color-primary item" href="#" onclick="window.open('http://www.otuvy.com/frontline-eula', '_system', 'location=yes'); return false;">Terms of Service</a> and <a class="ion-home color-primary item" href="#" onclick="window.open('http://www.otuvy.com/privacy-policy', '_system', 'location=yes'); return false;">Privacy Policy</a> have been updated. To continue using Otuvy Frontline, you'll need to accept these updates.`
            )
          : new IonicSafeString(
              `To use Otuvy Frontline, you'll need to accept our <a class="ion-home color-primary item" href="#" onclick="window.open('http://www.otuvy.com/privacy-policy', '_system', 'location=yes'); return false;">Privacy Policy</a> and <a class="ion-home color-primary item" href="#" onclick="window.open('http://www.otuvy.com/frontline-eula', '_system', 'location=yes'); return false;">Terms of Service</a>.`
            )
      }
      isOpen={showAgreement}
      buttons={[
        {
          text: 'Do Not Accept',
          role: 'cancel',
          handler: dontAgree,
          cssClass: 'agreement-confirmation-do-not-accept',
        },
        {
          text: 'Accept',
          role: 'destructive',
          handler: agree,
          cssClass: 'agreement-confirmation-accept',
        },
      ]}
    />
  );
};

export default Agreement;
