import { useEffect, useRef, useState } from 'react';
import { IonCheckbox, IonInput, IonText } from '@ionic/react';

import './ListArchivePanel.css';
import { CheckboxChangeEventDetail, IonCheckboxCustomEvent, IonInputCustomEvent } from '@ionic/core';
import {
  OrganizationSetting,
  OrganizationSettingKey,
  getAutoArchiveThreshold,
  updateSetting,
} from '../../organizationSettingsApi';

const ListArchivePanel = () => {
  const [invalidInput, setInvalidInput] = useState<boolean>(false);

  const inputRef = useRef<HTMLIonInputElement>(null);

  const [autoArchiveSetting, setAutoArchiveSetting] = useState<OrganizationSetting>({
    key: OrganizationSettingKey.auto_archive_threshold,
    value: '-1',
  });

  useEffect(() => {
    getAutoArchiveThreshold().then((data) => {
      setAutoArchiveSetting(data);
    });
  }, []);

  const onCheckboxChangeHandler = (e: IonCheckboxCustomEvent<CheckboxChangeEventDetail<any>>) => {
    console.log('onCheckboxChangeHandler');
    const oldValue = autoArchiveSetting.value;
    const isChecked: boolean = e.detail.checked;
    const newValue: number = isChecked ? 30 : -1;

    // eslint-disable-next-line eqeqeq
    if (oldValue == newValue) {
      //Explicitly == since old value could be either a string or a number representation of the value
      //Prevent updates to the same value from going to the server, which would happen sometimes on page initialization
      return;
    }
    setAutoArchiveSetting({ ...autoArchiveSetting, value: newValue });
    updateSetting({ key: OrganizationSettingKey.auto_archive_threshold, value: newValue });
  };

  /**
   *  if we have a number
   *   if it's between 1 and 365
   *    save it!
   *   else, out of bounds, invalid
   *  else if we don't have a number
   *   if it's empty, set to -1 and save!
   *  else, invalid
   */
  const onInputHandler = (e: IonInputCustomEvent<InputEvent>) => {
    console.log('onInputHandler');
    const value = e.target.value;
    if (!Number.isNaN(value)) {
      // We have a number!
      const newValue = Number(value);
      if (newValue >= 1 && newValue <= 365 && Number.isInteger(newValue)) {
        setInvalidInput(false);
        setAutoArchiveSetting({ ...autoArchiveSetting, value: newValue });
        updateSetting({ key: OrganizationSettingKey.auto_archive_threshold, value: newValue });
      } else {
        setInvalidInput(true);
      }
    }
    // This might not be in the right place, doesn't seem to work as I'm expecting it to.
    else if (value === '' || value === null || value === undefined) {
      setInvalidInput(false);
      setAutoArchiveSetting({ ...autoArchiveSetting, value: -1 });
      updateSetting({ key: OrganizationSettingKey.auto_archive_threshold, value: -1 });
    } else {
      setInvalidInput(true);
      // Update nothing, I think.
    }
  };

  const onInputChangeHandler = () => {
    console.log('onInputChangeHandler');
  };

  return (
    <div
      className={`list-archive-panel d-flex ion-align-items-center ${autoArchiveSetting.value === -1 && 'disable'}`}
      style={{ width: 'fit-content', gap: '8px' }}
    >
      {/* Auto-archive checkbox */}
      <IonCheckbox
        className="list-archive-panel__checkbox"
        checked={autoArchiveSetting.value !== -1}
        onIonChange={onCheckboxChangeHandler}
      />

      <IonText>Auto-archive completed lists after</IonText>

      {/* Input for # of days */}
      <div
        className={`list-archive-panel__input-container d-flex ion-aling-items-center ion-justify-content-center ${
          invalidInput && 'invalid'
        }`}
      >
        <IonInput
          ref={inputRef}
          className={`list-archive-panel__input ${invalidInput && 'invalid'}`}
          aria-label="Number of completed days"
          type="number"
          placeholder="--"
          value={autoArchiveSetting.value !== -1 ? Number(autoArchiveSetting.value) : ''}
          onIonInput={onInputHandler}
          onIonChange={onInputChangeHandler}
          disabled={autoArchiveSetting.value === -1}
          debounce={500}
        />
      </div>

      <IonText>days.</IonText>
    </div>
  );
};

export default ListArchivePanel;
