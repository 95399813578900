import { CollectionView, PageChangingEventArgs } from '@grapecity/wijmo';
import { DataMap, RowCollection, FlexGrid as FlexGridClass } from '@grapecity/wijmo.grid';
import { FlexGrid, FlexGridCellTemplate, FlexGridColumn, ICellTemplateContext } from '@grapecity/wijmo.react.grid';
import { CollectionViewNavigator } from '@grapecity/wijmo.react.input';
import { forwardRef, useEffect, useRef, useState } from 'react';
import LoadingMessage from '../../../../components/overlays/LoadingMessage';
import PersonPickerSearch from '../../../../components/personPicker/PersonPickerSearch';
import { Person } from '../../../people/peopleApi';
import { QmLocation, updateQmServiceLocation } from '../integrationApi';

import './IntegrationsLocationsGrid.css';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { Row } from '@grapecity/wijmo.grid';
import Tooltip from '../../../../components/overlays/Tooltip';

const selectedItemsMapIdAndRow = new Map<string, QmLocation>();

const PAGE_SIZE = 50;

export interface QmLocationRow extends QmLocation {
  showClient: boolean;
}

interface IntegrationsLocationsGridProps {
  searchText: string;
  checkedItems: QmLocation[];
  setCheckedItems: (rows: QmLocation[]) => void;
  people: Person[] | null;
  defaultListOwner: string | undefined;
  data: QmLocation[] | undefined;
  isLoading: boolean;
  refreshData: () => void;
  setData: React.Dispatch<React.SetStateAction<QmLocation[] | undefined>>;
  handleError: (messageKey: string | boolean) => void;
}
type IntegrationsLocationsGridRef = FlexGrid;

const IntegrationsLocationsGrid = forwardRef<IntegrationsLocationsGridRef, IntegrationsLocationsGridProps>(
  (props, ref) => {
    const [pageNumber, setPageNumber] = useState(0);

    const [collectionView, setCollectionView] = useState(new CollectionView<QmLocationRow>([]));
    const checkedItemsRef = useRef<QmLocation[]>([]);

    useEffect(() => {
      checkedItemsRef.current = props.checkedItems;
      selectedItemsMapIdAndRow.clear();
      props.checkedItems.forEach((item) => {
        selectedItemsMapIdAndRow.set(item.serviceLocationId, item);
      });
    }, [props.checkedItems]);

    useEffect(() => {
      rebuildCollectionView(props.data);
    }, [props.data]);

    const hideAllClientsExceptFirstInstanceOfEachOnPage = (rows: RowCollection): void => {
      const clients: string[] = [];

      rows.forEach((row, index) => {
        const item: QmLocationRow = row.dataItem;
        if (index === 0) {
          clients.push(item.clientName);
          return;
        }

        if (clients.includes(item.clientName)) {
          row.cssClass = 'hide-client';
        } else {
          clients.push(item.clientName);
        }
      });
    };

    const rebuildCollectionView = (data: QmLocation[] | undefined) => {
      //sort data by client name to make sure we can hide all clients except the first instance of each on the page
      // we used to assume that the data was sorted by client name, but that is not always the case, sometimes the API returns the data sorted by location name
      const sortedData = data?.sort((a, b) => a.clientName.localeCompare(b.clientName));
      // We need to create a CollectionView to handle pagination
      // https://developer.mescius.com/wijmo/demos/Grid/PagingScrolling/Client-sidePaging/react
      const newView = new CollectionView<QmLocationRow>(sortedData, {
        pageSize: PAGE_SIZE,
        pageChanging: (cv: CollectionView<QmLocationRow>, { newPageIndex }: PageChangingEventArgs) => {
          if (pageNumber !== newPageIndex) setPageNumber(newPageIndex);
        },
      });

      newView.moveToPage(pageNumber); // Since changing the data triggers a re-render, we need to move back to the page we were on.  This assumes that there was no change in the data between the time the data was loaded and the time the collectionView was created.

      setCollectionView(newView);
    };

    const onloadedRows = (grid: FlexGridClass) => {
      const rows = grid.rows;
      rows.forEach((row: Row) => {
        const isChecked = checkedItemsRef.current.some(
          (item) => item.serviceLocationId === row.dataItem.serviceLocationId
        );
        row.isSelected = isChecked;
      });
      hideAllClientsExceptFirstInstanceOfEachOnPage(grid.rows);
    };

    const onGridInitialized = (grid: FlexGridClass) => {
      new Selector(grid, {
        itemChecked: (cv: any, e: any) => {
          const selectedRows = grid.rows.filter((row: Row) => row.isSelected);
          const uncheckedRows = grid.rows.filter((row: Row) => !row.isSelected);

          selectedRows.forEach((row: Row) => {
            selectedItemsMapIdAndRow.set((row.dataItem as QmLocationRow).serviceLocationId, row.dataItem);
          });
          uncheckedRows.forEach((row: Row) => {
            selectedItemsMapIdAndRow.delete((row.dataItem as QmLocationRow).serviceLocationId);
          });

          props.setCheckedItems(Array.from(selectedItemsMapIdAndRow.values()));
        },
      });
    };

    const createListOwnerDataMap = (peopleArr: Person[] | null) => {
      if (!peopleArr) return '';
      // We need to create a object map to use as a DataMap for the defaultListOwner column
      // This is so we can filter the listOwner column by the full name of the person intead of the userId
      const mapPeopleIdWithFullName = peopleArr?.map((person) => ({
        userId: person.userId,
        fullName: person.firstName + ' ' + person.lastName,
      }));

      // We pass this DataMap to the listOwner column
      return new DataMap(mapPeopleIdWithFullName, 'userId', 'fullName');
    };

    const handleAutoListsClick = (item: QmLocationRow) => {
      props.setData((prev) => {
        if (prev)
          return prev.map((d) => {
            if (d.serviceLocationId === item.serviceLocationId) {
              return { ...d, autoGenerateLists: !item.autoGenerateLists };
            }
            return d;
          });
        return prev;
      });

      updateQmServiceLocation({
        serviceLocationId: item.serviceLocationId,
        autoGenerateLists: !item.autoGenerateLists,
        defaultListOwner: item.defaultListOwner,
      }).catch((error: any) => {
        console.error('Failed to update QM service location', error);
        props.handleError(true);
      });
    };

    const handleDefaultListOwnerChange = (item: QmLocationRow, newOwnerId: string) => {
      props.setData((prev) => {
        if (prev)
          return prev.map((d) => {
            if (d.serviceLocationId === item.serviceLocationId) {
              return { ...d, defaultListOwner: newOwnerId };
            }
            return d;
          });
        return prev;
      });

      updateQmServiceLocation({
        serviceLocationId: item.serviceLocationId,
        autoGenerateLists: item.autoGenerateLists,
        defaultListOwner: newOwnerId,
      }).catch((error: any) => {
        console.error('Failed to update QM service location', error);
        props.handleError(true);
      });
    };

    return (
      <div className="container-fluid">
        <FlexGrid
          ref={ref}
          itemsSource={collectionView}
          loadedRows={onloadedRows}
          alternatingRowStep={0}
          // headersVisibility="2"
          initialized={onGridInitialized}
        >
          {/* Columns */}

          {/* Clients */}
          <FlexGridColumn cssClass="client-column" header="Clients" binding="clientName" width="*" isReadOnly />

          {/* Locations */}
          <FlexGridColumn header="Locations" binding="serviceLocationName" width="*" isReadOnly />

          {/* Auto-lists button*/}
          <FlexGridColumn
            cssClass="auto-lists-column"
            header="Auto-lists"
            binding="autoGenerateLists"
            width={150}
            allowSorting={false}
            allowResizing={false}
            isReadOnly
          >
            <FlexGridCellTemplate
              cellType="ColumnHeader"
              template={(context: ICellTemplateContext) => (
                <div className="d-flex ion-align-items-center ion-justify-content-center" style={{ gap: '.25rem' }}>
                  <span>Auto-List</span>
                  <Tooltip
                    tooltip={
                      'When this is turned on and the auto generation toggle on the settings page is on, auto deficiency lists will be created in Otuvy Frontline for this location.'
                    }
                  />
                </div>
              )}
            />
            <FlexGridCellTemplate
              cellType="Cell"
              template={(context: ICellTemplateContext) => (
                <div
                  className={`auto-lists-cell ${
                    context.item.autoGenerateLists ? 'auto-lists-cell-enabled' : 'auto-lists-cell-disabled'
                  }`}
                  onClick={() => handleAutoListsClick(context.item)}
                >
                  <span className="default-text">{context.item.autoGenerateLists ? 'On' : 'Off'}</span>
                  <span className="hover-text">
                    {context.item.autoGenerateLists ? 'Click to Turn Off' : 'Click to Turn On'}
                  </span>
                </div>
              )}
            />
          </FlexGridColumn>

          {/* Default List Owner DropDown*/}
          <FlexGridColumn
            header="Default List Owner"
            binding="defaultListOwner"
            width={300}
            allowSorting={false}
            allowResizing={false}
            isReadOnly
            dataMap={createListOwnerDataMap(props.people)}
          >
            <FlexGridCellTemplate
              cellType="Cell"
              template={(context: ICellTemplateContext) => (
                <PersonPickerSearch
                  people={props.people}
                  isInWijmoCell
                  selectedPersonId={context.item.defaultListOwner}
                  defaultListOwner={props.defaultListOwner}
                  searchText={props.searchText}
                  onIonChange={(e) => {
                    handleDefaultListOwnerChange(context.item, e.detail.value);
                  }}
                />
              )}
            />
          </FlexGridColumn>
        </FlexGrid>

        {/* Pagination */}
        <div className="d-flex ion-justify-content-center">
          <CollectionViewNavigator
            headerFormat="Page {currentPage:n0} of {pageCount:n0}"
            byPage={true}
            cv={collectionView}
          />
        </div>

        {/* Loading */}
        {props.isLoading && <LoadingMessage />}
      </div>
    );
  }
);

export default IntegrationsLocationsGrid;
