import { useIonModal } from '@ionic/react';
import { useState } from 'react';
import EditWorkLogRecordDiaglog from '../components/EditWorkLogRecordDiaglog';
import { DetailedShiftData } from '../../../utils/dataTransformers';

const useEditWorkLogRecordDialog = () => {
  const [currentWorkLogToEdit, setCurrentWorklogToEdit] = useState<DetailedShiftData | null>(null);

  const [present, dismiss] = useIonModal(EditWorkLogRecordDiaglog, {
    dismiss: dismissModal,
    workLogRecord: currentWorkLogToEdit,
  });

  function presentModal(record: DetailedShiftData) {
    setCurrentWorklogToEdit(record);
    present({
      onWillDismiss: dismissModal,
    });
  }

  function dismissModal() {
    dismiss();
    setCurrentWorklogToEdit(null);
  }

  return { presentModal };
};

export default useEditWorkLogRecordDialog;
