import { IonImg, IonLabel } from '@ionic/react';
import { format } from 'date-fns';
import ListQrCodesToPrint, { qrCodeListsSizeState, qrCodeListsState } from '../features/qrCodes/ListQrCodesToPrint';

import { useRecoilValue } from 'recoil';
import { currentUserFullNameState } from '../SecureApp';

import logo from '../assets/images/app-logo.png';

const PrintContent: React.FC = () => {
  const lists = useRecoilValue(qrCodeListsState);
  const size = useRecoilValue(qrCodeListsSizeState);

  const listInSets = () => {
    const listsToPrint = [...lists];
    const buffer = [];

    const numberOfItemsPerSection = size === 100 ? 9 : size === 200 ? 4 : 1;

    for (let i = 0; i < listsToPrint.length; i += numberOfItemsPerSection) {
      buffer.push(listsToPrint.slice(i, i + numberOfItemsPerSection));
    }

    return buffer;
  };

  // This is a 2D array of lists to print, where each inner array is a set of lists to print based on the size of QR Codes selected
  // This was the only workaround we found to force proper page breaks when printing
  const qrCodesSections = listInSets();

  return (
    <div className="print-content">
      <PrintContentHeader />
      {qrCodesSections.map((set, index) => (
        <ListQrCodesToPrint key={index} lists={set} />
      ))}
    </div>
  );
};

export default PrintContent;

// TODO: Move this to a separate file?
const PrintContentHeader: React.FC = () => {
  const currentUserFullName = useRecoilValue(currentUserFullNameState);

  return (
    <div className="d-flex ion-justify-content-between ion-align-items-center ion-padding">
      <div>
        <IonImg style={{ height: '6rem' }} src={logo} />
      </div>
      <div className="ion-padding ion-text-end">
        <IonLabel style={{ fontWeight: '500', fontSize: '1rem' }}>Created by: {currentUserFullName ?? ''}</IonLabel>
        <br />
        <IonLabel style={{ fontWeight: '500', fontSize: '1rem' }}>
          Date: {format(new Date(), 'M/dd/yy h:mm a')}
        </IonLabel>
      </div>
    </div>
  );
};
