import { useIonModal } from '@ionic/react';
import AddWorkLogRecordDialog from '../components/AddWorkLogRecordDialog';

const useAddWorkLogRecordDialog = () => {
  const [present, dismiss] = useIonModal(AddWorkLogRecordDialog, {
    dismiss: dismissModal,
  });

  function presentModal() {
    present({
      onWillDismiss: dismissModal,
    });
  }

  function dismissModal() {
    dismiss();
  }

  return { presentModal };
};

export default useAddWorkLogRecordDialog;
