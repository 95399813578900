import './ListImportValidationErrorsOutput.css';
import { IonCol, IonIcon, IonRow } from '@ionic/react';
import { cloudUploadOutline } from 'ionicons/icons';
import { useEffect, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { atom, useRecoilValue } from 'recoil';

export interface ListImportValidationError {
  columnName: string;
  listName: string;
  errorMessage: string;
  email?: string;
}

export const listImportValidationErrorState = atom<ListImportValidationError[]>({
  key: 'listImportValidationErrorState',
  default: [],
});

const ListImportValidationErrorsOutput: React.FC = () => {
  const errors = useRecoilValue(listImportValidationErrorState);

  const containerRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();

  useEffect(() => {
    containerRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, [errors]);

  return (
    <div ref={containerRef} className="list-import-errors-panel">
      <div className="font-28 font-weight-500 ion-margin-bottom">{t('page.listImport.panel.validation.header')}</div>
      <div className="font-16 ion-padding-start ion-margin-bottom">{t('page.listImport.panel.validation.message')}</div>
      <div style={{ paddingInlineStart: '32px' }}>
        <div className="ion-margin-bottom font-weight-500">{t('page.listImport.panel.validation.instruction1')}</div>
        <div>
          <div className="list-import-errors-table">
            <IonRow>
              <IonCol size="1.25" className="list-import-error-cell" style={{ textAlign: 'center' }}>
                {t('page.listImport.panel.validation.table.header.columnName')}
              </IonCol>
              <IonCol size="3" className="list-import-error-cell">
                {t('page.listImport.panel.validation.table.header.listName')}
              </IonCol>
              <IonCol size="4" className="list-import-error-cell">
                {t('page.listImport.panel.validation.table.header.errorMessage')}
              </IonCol>
              <IonCol size="3.75" className="list-import-error-cell">
                {t('page.listImport.panel.validation.table.header.email')}
              </IonCol>
            </IonRow>
            {errors.map(({ columnName, listName, errorMessage, email }) => (
              <IonRow key={`${columnName}|${errorMessage}|${email}`} className="list-import-error-body-row">
                <IonCol size="1.25" className="list-import-error-cell" style={{ textAlign: 'center' }}>
                  {columnName}
                </IonCol>
                <IonCol size="3" className="list-import-error-cell">
                  {listName}
                </IonCol>
                <IonCol size="4" className="list-import-error-cell">
                  {errorMessage}
                </IonCol>
                <IonCol size="3.75" className="list-import-error-cell">
                  {email}
                </IonCol>
              </IonRow>
            ))}
          </div>
        </div>
        <div className="ion-margin-top font-20 font-weight-500 d-flex ion-align-items-center">
          <div>
            <span className="ion-margin-end">
              <Trans t={t} i18nKey={'page.listImport.panel.validation.instruction2'} />
            </span>
          </div>
          <IonIcon
            style={{ '--ionicon-stroke-width': '42px' }}
            className="font-24"
            slot="end"
            icon={cloudUploadOutline}
          />
        </div>
      </div>
    </div>
  );
};

export default ListImportValidationErrorsOutput;
