import StorageGovernorBanners from './StorageGovernorBanners';
import PaymentGovernorBanners from './PaymentGovernorBanners';

const GovernorBanners: React.FC = () => {
  return (
    <>
      <StorageGovernorBanners />
      <PaymentGovernorBanners />
    </>
  );
};

export default GovernorBanners;
