import { IonButton, IonIcon } from '@ionic/react';
import { openOutline } from 'ionicons/icons';
import './Banner.css';

export enum BannerLevel {
  INFO = 'info',
  WARN = 'warn',
  DANGER = 'danger',
}

interface BannerProps {
  message: string;
  level?: BannerLevel;
  buttonText?: string;
  onButtonClick?: () => void;
  escapeHtml?: boolean;
}

const Banner: React.FC<BannerProps> = ({
  message,
  level = BannerLevel.INFO,
  buttonText,
  onButtonClick,
  escapeHtml,
}) => {
  return (
    <div className={`d-flex ion-justify-content-center ion-align-items-center banner-box banner-level-${level}`}>
      {escapeHtml ? <div dangerouslySetInnerHTML={{ __html: message }} /> : message}
      {buttonText && (
        <IonButton class="banner-button" onClick={onButtonClick}>
          {buttonText}
          <IonIcon slot="end" icon={openOutline} />
        </IonButton>
      )}
    </div>
  );
};

export default Banner;
