import { useTranslation } from 'react-i18next';
import Page from '../../../components/Page';
import IntegrationsDisconnectPanel from '../../../features/integrations/qm/settings/IntegrationsDisconnectPanel';

interface IntegrationsDisconnectPageProps {
  defaultListOwnerFromServer: string | undefined;
}

const IntegrationsDisconnectPage: React.FC<IntegrationsDisconnectPageProps> = ({ defaultListOwnerFromServer }) => {
  const { t } = useTranslation();

  return (
    <Page title={t('title.integrations.qm')}>
      <IntegrationsDisconnectPanel defaultListOwnerFromServer={defaultListOwnerFromServer} />
    </Page>
  );
};

export default IntegrationsDisconnectPage;
