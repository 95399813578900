import { Fragment, useEffect, useRef, useState } from 'react';
import { IonItem, IonLabel, IonIcon, IonPopover, IonContent, IonSearchbar, IonHeader, IonText } from '@ionic/react';
import { chevronUpOutline } from 'ionicons/icons';
import { Person } from '../../features/people/peopleApi';
import PersonPickerSearchItem from './PersonPickerSearchItem';

import './PersonPickerSearch.css';

interface PersonPickerSearchProps {
  placeholder?: string;
  disabled?: boolean;
  onIonChange?: (e: CustomEvent) => void;
  selectedPersonId?: string | undefined;
  disabledUsersId?: string[];
  isInWijmoCell?: boolean;
  people: Person[] | null;
  defaultListOwner?: string | undefined;
  searchText?: string;
}

const PersonPickerSearch: React.FC<PersonPickerSearchProps> = ({
  placeholder = 'Select',
  disabled = false,
  onIonChange,
  selectedPersonId,
  disabledUsersId = [],
  isInWijmoCell = false,
  people,
  defaultListOwner,
  searchText,
}) => {
  const popoverRef = useRef<HTMLIonPopoverElement>(null);
  const itemRef = useRef<HTMLIonItemElement>(null);

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [selectedPerson, setSelectedPerson] = useState<Person | null>(null);

  const popoverId = Math.random().toString(36).substring(2);

  useEffect(() => {
    if (people && selectedPersonId) {
      const person = people.find((person) => person.userId === selectedPersonId);
      setSelectedPerson(person || null);
    } else if (people && !selectedPersonId && defaultListOwner) {
      const person = people?.find((person) => person.userId === defaultListOwner);
      setSelectedPerson(person || null);
    }
  }, [people, selectedPersonId, defaultListOwner]);

  const onPersonClickHandler = async (person: Person) => {
    if (popoverRef.current) {
      await popoverRef.current.dismiss();
      setSelectedPerson(person);
      onIonChange && onIonChange(new CustomEvent('ionChange', { detail: { value: person.userId } }));
    }
  };

  const filteredPeople = people?.filter((person) => {
    const fullName = `${person.firstName} ${person.lastName}`;
    return fullName.toLowerCase().includes(searchQuery.toLowerCase()) && !disabledUsersId.includes(person.userId);
  });

  const calculatePopoverHeight = () => {
    const itemHeight = 48;
    if (!filteredPeople) return 0;
    const popoverHeight = filteredPeople.length * itemHeight + 60;

    return popoverHeight;
  };

  const formatSelectedPersonLabel = (person: Person, searchValue: string) => {
    const highlightedName = () => {
      const fullName = `${person.firstName} ${person.lastName}`;
      const searchWords = searchValue
        .toLowerCase()
        .split(' ')
        .filter((word) => word.trim() !== '');
      const parts = [];
      let lastIndex = 0;

      searchWords.forEach((searchWord, index) => {
        const searchRegex = new RegExp(searchWord, 'gi');
        let match;

        while ((match = searchRegex.exec(fullName)) !== null) {
          const matchIndex = match.index;
          parts.push(
            <Fragment key={`name-${index}-${matchIndex}`}>{fullName.substring(lastIndex, matchIndex)}</Fragment>
          );
          parts.push(
            <IonText
              key={`natch-${index}-${matchIndex}`}
              color="primary"
              style={{ background: '#e9ffd9', fontWeight: '700', color: '#000' }}
            >
              {match[0]}
            </IonText>
          );
          lastIndex = matchIndex + match[0].length;
        }
      });

      parts.push(<Fragment key={`Tail`}>{fullName.substring(lastIndex)}</Fragment>);

      return <>{parts}</>;
    };

    if (person.userId === defaultListOwner) {
      return (
        <>
          Org. Default <IonText color="light">{highlightedName()}</IonText>
        </>
      );
    }

    return <>{highlightedName()}</>;
  };

  // Not a big fan of this, but it's a quick fix for now
  // This is to make sure the default owner is always at the top of the list
  // I tried doing this in the return JSX but it was causing issues with the popover making the scrollbar appear
  // Tried multiple things but no luck
  // Things i've tried:
  // - Adding the ion-item with the default owner prop to the header of the popover
  // - Adding the ion-item with the default owner prop to the top of the filteredPeople array
  // - Wrapping all the items in the ion-content with an ion-list
  // - Forcing a fix width on the popover to avoid the overflow that was causing the scrollbar to appear
  // - Multiple CSS fixes to try to avoid the overflow when adding an item outside of the filteredPeople array
  // This might probably be cause by some wierd shadow dom behavior...
  if (defaultListOwner) {
    const defaultOwner = people?.find((person) => person.userId === defaultListOwner);
    filteredPeople?.unshift(defaultOwner as Person);
  }

  return (
    <>
      <IonItem
        id={`ion-select-search-popover-trigger-${popoverId}`}
        ref={itemRef}
        lines="none"
        className={`ion-select-search ${isInWijmoCell && 'ion-select-search--no-border ion-no-padding'}`}
        disabled={(people ? false : true) || disabled}
      >
        {people ? (
          <IonLabel className={`ion-select-search__label ${isInWijmoCell ? 'font-weight-400' : 'font-weight-600'}`}>
            {selectedPerson ? formatSelectedPersonLabel(selectedPerson, searchText ?? '') : placeholder}
          </IonLabel>
        ) : (
          <IonLabel className="ion-select-search__label">Loading...</IonLabel>
        )}
        {!isInWijmoCell ? (
          <IonIcon
            className={`ion-select-search__icon ${isPopoverOpen && 'ion-select-search__icon--open'}`}
            size="small"
            color="dark"
            icon={chevronUpOutline}
            slot="end"
          />
        ) : (
          <IonIcon
            className={`ion-select-search__icon ${isPopoverOpen && 'ion-select-search__icon--open'}`}
            color="medium"
            icon={chevronUpOutline}
          />
        )}
      </IonItem>

      <IonPopover
        ref={popoverRef}
        className="ion-select-search__popover"
        showBackdrop={false}
        onIonPopoverWillPresent={() => setIsPopoverOpen(true)}
        onIonPopoverWillDismiss={() => {
          setIsPopoverOpen(false);
          setSearchQuery('');
        }}
        trigger={`ion-select-search-popover-trigger-${popoverId}`}
        style={{ '--max-height': `${calculatePopoverHeight()}px` }}
        size="cover"
        side="bottom"
      >
        <IonHeader className="ion-no-border">
          <IonSearchbar
            mode="ios"
            className="ion-select-search__popover-searchbar"
            searchIcon={undefined}
            value={searchQuery}
            onIonChange={(e) => setSearchQuery(e.detail.value!)}
          ></IonSearchbar>
        </IonHeader>
        <IonContent
          className="ion-select-search__popover-content"
          style={{ width: itemRef.current?.clientWidth + 'px', pointerEvents: 'all !imporant' }}
        >
          {filteredPeople?.map((person: Person, index) => (
            <PersonPickerSearchItem
              key={person.userId + index}
              person={person}
              onPersonClickHandler={onPersonClickHandler}
              isDefaultOwner={index === 0 && defaultListOwner !== undefined}
            />
          ))}
        </IonContent>
      </IonPopover>
    </>
  );
};

export default PersonPickerSearch;
