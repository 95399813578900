import { useEffect } from 'react';
import { atom, useSetRecoilState } from 'recoil';
import { getOrganization, Organization } from './organizationApi';

export const organizationState = atom<Organization | undefined>({
  key: 'organization',
  default: undefined,
});

const useFetchOrganization = () => {
  const setOrganization = useSetRecoilState(organizationState);

  useEffect(() => {
    getOrganization().then(setOrganization);
  }, []);
};

export default useFetchOrganization;
