import {
  isSameDay,
  setMilliseconds,
  setSeconds,
  setMinutes,
  setHours,
  addDays,
  format,
  isAfter,
  isBefore,
  isSameMinute,
  differenceInMilliseconds,
} from 'date-fns';
import {
  DetailedShiftData,
  Punch,
  SummaryData,
  TimeTrackingEvent,
  TimeTrackingEventType,
  UserShiftData,
  WorkLog,
} from '../../../utils/dataTransformers';
import { v4 as uuid } from 'uuid';

export const transformDetailedDataToCSVFormat = (data: DetailedShiftData[]) => {
  const formattedData: any[] = [];
  const calculatteDuration = (startDate: Date, endDate: Date) => {
    if (!endDate) return '';
    const durationInMs = differenceInMilliseconds(endDate, startDate);
    const durationInHours = durationInMs / 1000 / 60 / 60;
    return durationInHours;
  };

  data.forEach((item) => {
    if (item.endDate && !isSameDay(item.startDate, item.endDate)) {
      // The shift spans across midnight
      const midnightEnd = setMilliseconds(setSeconds(setMinutes(setHours(item.startDate, 23), 59), 59), 999);
      const midnightStart = addDays(setMilliseconds(setSeconds(setMinutes(setHours(item.startDate, 0), 0), 0), 0), 1);

      // First day row
      formattedData.push({
        Name: item.fullName,
        Date: format(item.startDate, 'M/dd/yyyy'),
        'Start Time': format(item.startDate, 'h:mm a'),
        'End Time': format(midnightEnd, 'h:mm a'),
        Duration: calculatteDuration(item.startDate, midnightEnd),
      });

      // Second day row
      formattedData.push({
        Name: item.fullName,
        Date: format(midnightStart, 'M/dd/yyyy'),
        'Start Time': format(midnightStart, 'h:mm a'),
        'End Time': format(item.endDate, 'h:mm a'),
        Duration: calculatteDuration(midnightStart, item.endDate),
      });
    } else {
      // The shift doesn't span across midnight, use the original data
      formattedData.push({
        Name: item.fullName,
        Date: format(item.startDate, 'M/dd/yyyy'),
        'Start Time': format(item.startDate, 'h:mm a'),
        'End Time': item.endDate ? format(item.endDate, 'h:mm a') : '',
        Duration: calculatteDuration(item.startDate, item.endDate!),
      });
    }
  });

  return formattedData;
};

export const transformSummaryDataToCSVFormat = (data: SummaryData[], dateRange: [Date | null, Date | null]) => {
  const formatedDateRange = `${format(dateRange[0]!, 'M/dd/yyyy')} - ${format(dateRange[1]!, 'M/dd/yyyy')}`;
  const formattedData: any[] = [];

  data.forEach((item) => {
    formattedData.push({
      Name: item.fullName,
      Date: formatedDateRange,
      'Total Hours': item.totalWorkingHours,
    });
  });

  return formattedData;
};

export const generateWorkLogRecord = (startDate: Date, endDate: Date | null) => {
  const workLogId: string = uuid();

  const punchIn: Punch = {
    punchId: uuid(),
    punchTime: startDate,
    punchType: TimeTrackingEventType.CLOCK_IN,
    address: null,
    timeZoneOffset: new Date().getTimezoneOffset(),
  };

  const punchOut: Punch = {
    punchId: uuid(),
    punchTime: endDate,
    punchType: TimeTrackingEventType.CLOCK_OUT,
    address: null,
    timeZoneOffset: new Date().getTimezoneOffset(),
  };

  const timeTrackingEventIn: TimeTrackingEvent = {
    punchId: punchIn.punchId!,
    workLogId,
    punchTime: startDate,
    punchType: TimeTrackingEventType.CLOCK_IN,
    timeZoneOffset: new Date().getTimezoneOffset(),
    createdOn: new Date(),
  };

  const timeTrackingEventOut: TimeTrackingEvent = {
    punchId: punchOut.punchId!,
    workLogId,
    punchTime: endDate ? endDate : new Date(),
    punchType: TimeTrackingEventType.CLOCK_OUT,
    timeZoneOffset: new Date().getTimezoneOffset(),
    createdOn: new Date(),
  };

  const worklog = endDate
    ? {
        workLogId,
        punchIn,
        punchOut,
      }
    : {
        workLogId,
        punchIn,
      };
  const timeTrackingEvents = [timeTrackingEventIn, timeTrackingEventOut];

  return {
    worklog,
    timeTrackingEvents,
  };
};

export const checkForWorkLogOverlap = (
  startTime: Date,
  endTime: Date | null,
  data: UserShiftData[],
  selectedPersonId: string,
  workLogId: string | null = null
) => {
  const userWorkLogs = data.find((shift) => shift.userId === selectedPersonId)?.workLogs ?? [];
  const isOverlap = userWorkLogs.some((userWorkLog) => {
    if (workLogId !== null && workLogId === userWorkLog.workLogId) return false; // Skip the current work log in an edit situation
    if (!userWorkLog.punchIn.punchTime || !(userWorkLog.punchOut && userWorkLog.punchOut.punchTime)) return false;
    if (!endTime) return false; //zero duration worklog

    const workLogStartTime = new Date(userWorkLog.punchIn.punchTime!);
    const workLogEndTime = new Date(userWorkLog.punchOut && userWorkLog.punchOut.punchTime);

    const overlaps =
      isSameMinute(startTime, workLogStartTime) ||
      isSameMinute(endTime, workLogEndTime) ||
      (isAfter(startTime, workLogStartTime) && isBefore(startTime, workLogEndTime)) ||
      (isAfter(endTime, workLogStartTime) && isBefore(endTime, workLogEndTime)) ||
      (isAfter(workLogStartTime, startTime) && isBefore(workLogStartTime, endTime)) ||
      (isAfter(workLogEndTime, startTime) && isBefore(workLogEndTime, endTime));

    //if (overlaps) console.log(`Overlap looking at ${workLogId} vs ${userWorkLog.workLogId}: ${workLogStartTime} - ${workLogEndTime} with ${startTime} - ${endTime}: ${overlaps}`);

    return overlaps;
  });

  return isOverlap;
};

export const calculateTotalWorkingHours = (startDate: Date, endDate: Date | null): number => {
  if (!endDate) return 0;
  if (isAfter(startDate, endDate)) return 0;

  const difference = differenceInMilliseconds(endDate, startDate);
  const roundToHours = difference / 3600000;

  return roundToHours;
};

export const sortWorkLogsByPunchtime = (arr: WorkLog[]): WorkLog[] => {
  const isDateObject = (value: any): Date => {
    if (value instanceof Date) {
      return value;
    }
    return new Date(value);
  };

  return [...arr].sort((a, b) => {
    if (!a.punchIn?.punchTime && !b.punchIn?.punchTime) {
      return 0;
    }
    if (!a.punchIn?.punchTime) {
      return 1;
    }
    if (!b.punchIn?.punchTime) {
      return -1;
    }

    const aDate = isDateObject(a.punchIn.punchTime).getTime();
    const bDate = isDateObject(b.punchIn.punchTime).getTime();

    /*
      If this is 0 we could additionally sort by punch out time.
      For a single user history that *should* not happen, 
      but it might for the multi user hisotry
    */
    return aDate - bDate;
  });
};
