import { getIDTokenAsString } from '@otuvy/auth';
import { TIME_TRACKING_API_BASE_URL } from '../../../aws-exports';
import { streamToString } from '../../../utils/api/apiUtils';
import { TimeTrackingEvent } from '../../../utils/dataTransformers';

export const enableTimeTracking = async () => {
  return timeTrackingEnabledDisabled(true);
};

export const disableTimeTracking = async () => {
  return timeTrackingEnabledDisabled(false);
};

const timeTrackingEnabledDisabled = async (enabled: boolean) => {
  const idToken: string | undefined = await getIDTokenAsString();
  if (!idToken) {
    throw new Error('No ID Token');
  }

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Id: idToken,
    },
  };

  const url = TIME_TRACKING_API_BASE_URL + (enabled ? 'enable' : 'disable');

  const response: Response = await fetch(url, requestOptions);

  if (response.status !== 200) {
    console.error('To change time tracking status', response);
  } else {
    console.log('Time tracking state changed to ' + (enabled ? 'enabled' : 'disabled') + ' on the server');
  }
};

export const getTimeTrackingStatusFromServer = async (): Promise<boolean> => {
  const idToken: string | undefined = await getIDTokenAsString();
  if (!idToken) {
    throw new Error('No ID Token');
  }

  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Id: idToken,
    },
  };

  const url = TIME_TRACKING_API_BASE_URL + 'isEnabled';

  const response: Response = await fetch(url, requestOptions);

  if (response.status !== 200) {
    console.error('Error fetching the current time tracking status', response);
  } else if (response.body) {
    const body: string = await streamToString(response.body);
    if (!body || body === '') {
      console.error('No body in response');
      return false;
    }
    const data = JSON.parse(body);
    return data.isEnabled === 'true';
  }
  console.warn('Unable to fetch time tracking status from server');
  return false;
};

export const getWorkLogsForCurrentUser = async (dateRange: any, temporarySelection: any) => {
  try {
    const idToken: string | undefined = await getIDTokenAsString();
    if (!idToken) {
      console.error('No ID Token');
      throw new Error('No ID Token');
    }

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Id: idToken,
      },
      body: JSON.stringify({
        userIds: temporarySelection,
        startDate: dateRange[0]?.toISOString(),
        endDate: dateRange[1]?.toISOString(),
      }),
    };

    const url = TIME_TRACKING_API_BASE_URL + 'download-work-log-data';
    const response: Response = await fetch(url, requestOptions);
    console.log('response', response);
    if (response.status !== 200) {
      console.error(`Error fetching work logs: ${response.statusText}`);
    } else if (response.body) {
      const body = await streamToString(response.body);
      console.log('Response body from edit call:', body);
      if (body && body !== null && body !== '') {
        const data = JSON.parse(body);
        console.log('response body data', data);
        return data;
      }
      console.log('No body in edit response');
    }

    return [];
  } catch (error) {
    console.error('Failed to get work logs for current user', error);
    return;
  }
};

export const editWorkLog = async (workLogId: string, startTime: Date | null, endTime: Date | null) => {
  if (!workLogId) {
    console.error('No work log id');
    return;
  }
  try {
    const idToken: string | undefined = await getIDTokenAsString();
    if (!idToken) {
      console.error('No ID Token');
      throw new Error('No ID Token');
    }

    console.log(
      `Attempting to update work log record ${workLogId} with start time ${startTime} and end time ${endTime}`
    );

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Id: idToken,
      },
      body: JSON.stringify({
        workLogId,
        startTime: startTime ? new Date(startTime).toISOString() : null,
        endTime: endTime ? new Date(endTime).toISOString() : null,
      }),
    };

    const url = TIME_TRACKING_API_BASE_URL + 'edit-work-log-data';
    const response: Response = await fetch(url, requestOptions);
    console.log('response', response);
    if (response.status !== 200) {
      console.error(`Error editing work log: ${response.statusText}`);
    } else if (response.body) {
      const body = await streamToString(response.body);
      if (body && body !== null && body !== '') {
        const data = JSON.parse(body);
        console.log('response body data', data);
      } else {
        console.log('No body in edit response');
      }
    }
  } catch (error) {
    console.error('Failed to edit work log', error);
  }
};

export const deleteWorklog = async (worklogId: string) => {
  try {
    const idToken: string | undefined = await getIDTokenAsString();
    if (!idToken) {
      console.error('No ID Token');
      throw new Error('No ID Token');
    }

    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Id: idToken,
      },
    };

    const url = TIME_TRACKING_API_BASE_URL + 'delete-work-log-data/' + worklogId;
    const response: Response = await fetch(url, requestOptions);
    console.log('response', response);
    if (response.status !== 200) {
      console.error(`Error deleting work log: ${response.statusText}`);
    } else {
      console.log('Work log deleted successfully');
    }
  } catch (error) {
    console.error('Failed to delete work log', error);
  }
};

//TODO: combine into one upload rather than an iteration over multiple uploads
export const sendTimeTrackingEvents = async (events: TimeTrackingEvent[], forUserId: string) => {
  console.log('sendTimeTrackingEventsById', events);
  events.forEach(async (event) => {
    await sendTimeTrackingEventById(event, forUserId);
  });
};

const sendTimeTrackingEventById = async (event: TimeTrackingEvent, forUserId: string) => {
  console.log('sendTimeTrackingEventById', event.workLogId);
  try {
    const idToken: string | undefined = await getIDTokenAsString();
    if (!idToken) {
      console.error('No ID Token');
      throw new Error('No ID Token');
    }

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Id: idToken,
      },
      body: JSON.stringify({
        timeTrackingEvents: [event],
        forUserId: forUserId,
      }),
    };

    const url = TIME_TRACKING_API_BASE_URL + 'upload-work-log-data';
    const response: Response = await fetch(url, requestOptions);

    if (response.status !== 200) {
      console.error(`Error uploading work log (${'eventId'}): ${response.statusText}`);
    } else {
      console.log('Work log uploaded successfully');
    }

    return [];
  } catch (error) {
    console.error('Failed to send time tracking event', error);
    return;
  }
};
