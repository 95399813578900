import { IonToggle } from '@ionic/react';
import { disableTimeTracking, enableTimeTracking, getTimeTrackingStatusFromServer } from '../api/TimeTrackingAPI';
import useGraphqlQuery from '../../../hooks/useGraphqlQuery';
import BasicSetting from '../../../components/BasicSetting';

const TimeTrackingToggle: React.FC = () => {
  const {
    data: timeTrackingEnabled,
    isLoading,
    setData: setTimeTrackingEnabled,
  } = useGraphqlQuery(getTimeTrackingStatusFromServer);

  const onToggleTimeTrackingEnabledChange = () => {
    if (timeTrackingEnabled) {
      disableTimeTracking().then(() => setTimeTrackingEnabled(false));
    } else {
      enableTimeTracking().then(() => setTimeTrackingEnabled(true));
    }
    console.log('Time Tracking Enabled changed to:', !timeTrackingEnabled);
  };

  return (
    <div>
      <BasicSetting
        label="Time Tracking"
        tooltip="This will enable time tracking in the mobile app for your team and allow them to clock in and out of work.  Your payroll data sheets will be available below."
      >
        <IonToggle
          disabled={isLoading}
          mode="ios"
          slot="start"
          checked={timeTrackingEnabled}
          onClick={() => onToggleTimeTrackingEnabledChange()}
        />
      </BasicSetting>
    </div>
  );
};

export default TimeTrackingToggle;
