import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonButton,
  IonTitle,
  IonContent,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonRadio,
  IonRadioGroup,
  IonItem,
} from '@ionic/react';
import { useEffect, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { QrCodeSize, qrCodeListsSizeState, qrCodeListsState } from './ListQrCodesToPrint';
import PrintContent from '../../components/PrintContent';
import JSZip from 'jszip';

import './QRCodePreviewModal.css';

const QRCodePreviewModal: React.FC = () => {
  const [qrCodeSize, setQrCodeSize] = useRecoilState(qrCodeListsSizeState);
  const lists = useRecoilValue(qrCodeListsState);
  const [qrCodesDataUrl, setQrCodesDataUrl] = useState<string[]>([]);

  const modal = useRef<HTMLIonModalElement>(null);

  useEffect(() => {
    const qrCodeMetadata = generateQRCode([...lists]);
    const promises = qrCodeMetadata.map(({ url }) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          if (ctx) {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            resolve(canvas.toDataURL('image/png'));
          } else {
            reject(new Error('Canvas context is not supported.'));
          }
        };
        img.onerror = (error) => {
          reject(error);
        };
        img.src = url;
      });
    });

    Promise.all(promises)
      .then((qrCodeDataUrls) => {
        setQrCodesDataUrl(qrCodeDataUrls as any);
      })
      .catch((error) => {
        console.error('Error loading QR code images:', error);
      });
  }, [lists, qrCodeSize]);

  const generateQRCode = (
    selectedLists: {
      listId: string;
      listName: string;
    }[]
  ) => {
    const qrCodeMetadata = selectedLists.map(({ listId }) => {
      const qrCode = document.querySelector(`#qrCode_${listId}`) as SVGSVGElement;
      const data = new XMLSerializer().serializeToString(qrCode);
      const svgBlob = new Blob([data], { type: 'image/svg+xml;charset=utf-8' });
      const url = (window.URL || window.webkitURL || window).createObjectURL(svgBlob);
      return { listId, url };
    });
    return qrCodeMetadata;
  };

  const onDownloadClickHandler = async () => {
    const zip = new JSZip();

    qrCodesDataUrl.forEach((dataURL, index) => {
      // We need to add the index to the file name to avoid overwriting files with the same name
      const invalidCharacters = /[\/|\\:*?"<>]/g;
      const sanitizedFileName = lists[index]?.listName.replace(invalidCharacters, '-').replace(/^-+|-+$/g, '');

      zip.file(`${index + 1}_${sanitizedFileName}.png`, dataURL.split(',')[1], { base64: true });
    });

    const zipData = await zip.generateAsync({ type: 'blob', streamFiles: true });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(zipData);
    link.download = 'OtuvyFL_QRCodes.zip';
    link.click();
  };

  const onPrintClickHandler = () => {
    console.debug('print qr codes');
    window.print();
  };

  return (
    <IonModal mode="md" className="qr-code-modal" ref={modal} trigger="open-qr-codes">
      {/* Modal Header */}
      <IonHeader className="qr-code-modal__header ion-padding">
        <IonToolbar>
          <IonTitle>Print QR Codes</IonTitle>
          <div slot="end">
            {/* Dismiss button */}
            <IonButton style={{ fontSize: '1rem' }} fill="clear" onClick={() => modal.current?.dismiss()}>
              Cancel
            </IonButton>

            {/* Print button */}
            <IonButton
              style={{
                fontSize: '1rem',
                '--padding-top': '1.25rem',
                '--padding-bottom': '1.25rem',
                '--padding-start': '2.5rem',
                '--padding-end': '2.5rem',
              }}
              fill="clear"
              onClick={onPrintClickHandler}
            >
              Print...
            </IonButton>

            <IonButton
              style={{
                fontSize: '1rem',
                '--padding-top': '1.25rem',
                '--padding-bottom': '1.25rem',
                '--padding-start': '2.5rem',
                '--padding-end': '2.5rem',
              }}
              onClick={() => onDownloadClickHandler()}
            >
              Download...
            </IonButton>
          </div>
        </IonToolbar>
      </IonHeader>

      {/* Modal Content */}
      <IonContent className="ion-padding">
        <IonGrid fixed style={{ height: '100%' }}>
          <IonRow style={{ height: '100%' }} className="ion-align-items-top">
            {/* Left Column */}
            <IonCol size="3" style={{ height: '100%', paddingLeft: '2rem' }}>
              <div style={{ marginTop: '1rem', marginBottom: '.5rem' }}>
                <IonLabel style={{ fontSize: '1rem', fontWeight: '600' }}>QR Code Size</IonLabel>
              </div>
              <div>
                <IonRadioGroup value={qrCodeSize} onIonChange={(e) => setQrCodeSize(e.detail.value)}>
                  <IonItem lines="none" style={{ '--min-height': '40px' }}>
                    <IonRadio slot="start" value={QrCodeSize.SMALL} style={{ marginInlineEnd: '.5rem' }}></IonRadio>
                    <IonLabel>Small</IonLabel>
                  </IonItem>

                  <IonItem lines="none" style={{ '--min-height': '40px' }}>
                    <IonRadio slot="start" value={QrCodeSize.MEDIUM} style={{ marginInlineEnd: '.5rem' }}></IonRadio>
                    <IonLabel>Medium</IonLabel>
                  </IonItem>

                  <IonItem lines="none" style={{ '--min-height': '40px' }}>
                    <IonRadio slot="start" value={QrCodeSize.LARGE} style={{ marginInlineEnd: '.5rem' }}></IonRadio>
                    <IonLabel>Large</IonLabel>
                  </IonItem>
                </IonRadioGroup>
              </div>
            </IonCol>

            {/* Right Column */}
            <IonCol>
              <div style={{ height: '100%', paddingLeft: '2rem', background: '#FFFFFF', maxWidth: '720px' }}>
                <PrintContent />
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonModal>
  );
};

export default QRCodePreviewModal;
