import { useRecoilValue } from 'recoil';
import { organizationState } from '../organization/useFetchOrganization';
import { createPortalSession } from './stripeAPI';

const useStripe = () => {
  const { stripeCustomerId } = useRecoilValue(organizationState) ?? {};

  const redirectToStripe = () => {
    // console.log(stripeCustomerId, 'Attempting to redirect to Stripe');
    createPortalSession(stripeCustomerId)
      ?.then((value) => {
        // console.log(value);
        window.open(value.session_url, '_blank', 'noreferrer');
      })
      .catch((error) => {
        console.log('Problem accessing Stripe API.', error);
      });
  };

  return {
    redirectToStripe,
  };
};

export default useStripe;
