import { IonLabel } from '@ionic/react';
import PeopleImportGrid from './PeopleImportGrid';
import PeopleImportInstructionPanel from './PeopleImportInstructionPanel';
import './PeopleImportPanel.css';

const PeopleImportPanel: React.FC = () => {
  return (
    <div style={{maxWidth: '1080px'}}>
      <div className="grid-container">
        <IonLabel class="font-20">Add People Here:</IonLabel>
        <PeopleImportGrid />
      </div>
      <PeopleImportInstructionPanel />
    </div>
  );
};

export default PeopleImportPanel;
