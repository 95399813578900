import { IonItem, IonLabel, IonText } from '@ionic/react';
import { useRef, useEffect } from 'react';
import { Person } from '../../features/people/peopleApi';

interface PersonPickerSearchItemProps {
  person: Person;
  onPersonClickHandler: (person: Person) => void;
  isDefaultOwner?: boolean;
}

const PersonPickerSearchItem: React.FC<PersonPickerSearchItemProps> = ({
  person,
  onPersonClickHandler,
  isDefaultOwner = false,
}) => {
  const itemRef = useRef<HTMLIonItemElement>(null);

  useEffect(() => {
    if (itemRef.current) {
      itemRef.current.addEventListener('click', (e) => {
        onPersonClickHandler(person);
      });
    }

    return () => {
      if (itemRef.current) {
        itemRef.current.removeEventListener('click', (e) => {
          onPersonClickHandler(person);
        });
      }
    };
  }, []);

  return (
    <IonItem
      mode="md"
      ref={itemRef}
      className="ion-select-search__popover-item"
      button
      style={{ '--padding-start': '0' }}
    >
      <IonLabel className="ion-padding-start">
        {isDefaultOwner ? (
          <>
            Organization Default{' '}
            <IonText color="light">
              ({person.firstName} {person.lastName})
            </IonText>
          </>
        ) : (
          `${person.firstName} ${person.lastName}`
        )}
      </IonLabel>
    </IonItem>
  );
};

export default PersonPickerSearchItem;
