import { graphql } from '../../utils/api/apiUtils';

export const toggleAdminPermission = async (groupName: string, userId: string): Promise<boolean> => {
  const query = `
    mutation toggleAdminPermissionMutation($groupName: String!, $userId: String!) {
      toggleAdminPermission(groupName: $groupName, userId: $userId)
    }
  `;
  const variables = { groupName, userId };
  return graphql<boolean>(query, variables);
};
