import { IonCol, IonGrid, IonLabel, IonRow } from '@ionic/react';
import { QRCodeSVG } from 'qrcode.react';
import { atom, useRecoilValue } from 'recoil';

import './ListQrCodesToPrint.css';
import { EnvironmentConfig, getString } from '../../utils/environmentUtils';

export const qrCodeListsState = atom<{ listId: string; listName: string }[]>({
  key: 'qrCodeListsState',
  default: [],
});

export enum QrCodeSize {
  SMALL = 100,
  MEDIUM = 200,
  LARGE = 500,
}

export const qrCodeListsSizeState = atom<QrCodeSize>({
  key: 'qrCodeListsSizeState',
  default: QrCodeSize.SMALL,
});

const DOMAIN = getString(EnvironmentConfig.QR_APP_DOMAIN);
const PATH = '/qr/sh/list/';

interface ListQrCodesToPrintProps {
  lists: { listId: string; listName: string }[];
}

const ListQrCodesToPrint: React.FC<ListQrCodesToPrintProps> = ({ lists }) => {
  const qrCodeSize = useRecoilValue(qrCodeListsSizeState);

  const colSize = qrCodeSize === QrCodeSize.SMALL ? 4 : qrCodeSize === QrCodeSize.MEDIUM ? 6 : 12;

  const truncateTextInTheMiddle = (text: string, maxLength: number) => {
    if (text.length <= maxLength) return text;

    const firstHalf = text.slice(0, maxLength / 2);
    const secondHalf = text.slice(text.length - maxLength / 2);

    return `${firstHalf} ... ${secondHalf}`;
  };

  return (
    <>
      <IonGrid>
        <IonRow className="ion-justify-content-start">
          {lists.map(({ listId, listName }) => (
            <IonCol
              className={`qr-code-box ${qrCodeSize === QrCodeSize.MEDIUM && 'medium'} ${
                qrCodeSize === QrCodeSize.LARGE && 'large'
              }`}
              key={listId}
              size={colSize + ''}
            >
              <IonRow>
                <IonCol className="d-flex ion-justify-content-center">
                  <QRCodeSVG
                    id={`qrCode_${listId}`}
                    style={{ height: '100%', width: '100%' }}
                    size={qrCodeSize}
                    includeMargin
                    value={`${DOMAIN}${PATH}${listId}`}
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol className="d-flex ion-justify-content-center">
                  <IonLabel
                    className={`qr-code-box__label ${qrCodeSize === QrCodeSize.MEDIUM && 'medium'}  ${
                      qrCodeSize === QrCodeSize.LARGE && 'large'
                    }`}
                  >
                    {truncateTextInTheMiddle(listName, 75)}
                  </IonLabel>
                </IonCol>
              </IonRow>
            </IonCol>
          ))}
        </IonRow>
      </IonGrid>
      <div className="page-break"></div>
    </>
  );
};

export default ListQrCodesToPrint;
