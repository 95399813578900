import { IonAvatar, IonButton, IonImg, IonLabel, IonText } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import useLogout from '../../../login/useLogout';
import { URL_PATH_PREFIX } from '../../../../constants/constants';
import DeleteAccount from './DeleteAccount';
import { useRecoilValue } from 'recoil';
import { currentUserFullNameState } from '../../../../SecureApp';
import { currentUserProfilePhoto } from '../../../toolbar/Toolbar';

const UserMenu: React.FC = () => {
  const fullName = useRecoilValue(currentUserFullNameState) ?? '';
  const profilePhoto = useRecoilValue(currentUserProfilePhoto);

  const { t } = useTranslation();
  const { logOut } = useLogout();

  return (
    <>
      <div className="ion-text-center">
        <IonAvatar class="m-auto d-flex ion-justify-content-center ion-align-items-end">
          {profilePhoto ? (
            <IonImg id="user-menu-trigger" src={profilePhoto} />
          ) : (
            <img
              id="user-menu-trigger"
              src={'./' + (URL_PATH_PREFIX === '' ? '' : URL_PATH_PREFIX + '/') + 'assets/img/profile-dark.svg'}
              alt="profile avatar"
            />
          )}
        </IonAvatar>
        <IonLabel color="dark" style={{ textTransform: 'capitalize' }}>
          <b>{fullName}</b>
        </IonLabel>
      </div>

      <IonButton class="ion-margin-bottom" expand="full" fill="clear" onClick={logOut}>
        {t('logout')}
      </IonButton>
      <br />
      <div className="d-flex ion-justify-content-between">
        <IonText class="font-9" color="primary">
          <a
            className="ion-home color-primary item cursor-pointer"
            href="#"
            onClick={(e) => {
              window.open('https://www.otuvy.com/privacy-policy', '_system', 'location=yes');
              return false;
            }}
          >
            Privacy Policy
          </a>
        </IonText>
        <IonText class="font-9" color="primary">
          <a
            className="ion-home color-primary item cursor-pointer"
            href="#"
            onClick={(e) => {
              window.open('https://www.otuvy.com/frontline-eula ', '_system', 'location=yes');
              return false;
            }}
          >
            Terms and Conditions
          </a>
        </IonText>
        <IonText class="font-9 cursor-pointer" id="delete-account">
          Delete My Account
        </IonText>
      </div>
      <div className="ion-text-center">
        <IonText class="font-9">v1.0.1</IonText>
      </div>
      <DeleteAccount trigger="delete-account" />
    </>
  );
};

export default UserMenu;
