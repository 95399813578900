import { useEffect } from 'react';
import { atom, useSetRecoilState } from 'recoil';
import { OrganizationQmIntegration, getIntegrationInfo } from './integrationApi';

export const integrationState = atom<OrganizationQmIntegration | undefined>({
  key: 'integration',
  default: undefined,
});

const useFetchIntegration = (fetchOnFirstRender: boolean = true) => {
  const setIntegration = useSetRecoilState(integrationState);

  const fetchIntegration = () => getIntegrationInfo().then(setIntegration);

  useEffect(() => {
    if (!fetchOnFirstRender) return;
    setIntegration(undefined);
    fetchIntegration();
  }, []);

  return {
    fetchIntegration,
  };
};

export default useFetchIntegration;
