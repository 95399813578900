import * as wjcCore from '@grapecity/wijmo';
import { useTranslation } from 'react-i18next';
import { isImportRowEmpty, mapLanguage } from '../peopleGridUtils';
import { PersonRow } from '../components/PeopleImportGrid/PeopleImportGrid';
import { Person } from '../peopleApi';
import { Language } from '../../../constants/constants';

const usePeopleValidations = (setErrorKeys?: React.Dispatch<React.SetStateAction<string[]>>) => {
  const { t } = useTranslation();

  const validatePropOnPerson = (person: Partial<Person>, prop: keyof Person, parsing?: boolean): string | undefined => {
    const isRequired = (prop: string): boolean => {
      return prop === 'firstName' || prop === 'lastName' || prop === 'email';
    };

    const validateEmail = (email: string): boolean => {
      const emailRegex = new RegExp(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      const match = emailRegex.test(email);

      return match;
    };

    const propDisplayName = t(`grid.column.header.user.${prop}`);

    if (isRequired(prop)) {
      const value = person[prop];
      if (wjcCore.isUndefined(value)) {
        return t('error.generic.required', { field: propDisplayName });
      }
      const str = wjcCore.changeType(value, wjcCore.DataType.String);
      if (wjcCore.isNullOrWhiteSpace(str)) {
        return t('error.generic.required', { field: propDisplayName });
      }
    }

    if (prop === 'email') {
      //TODO: validate that the email is unique in the whole grid
      const email = wjcCore.changeType(person.email, wjcCore.DataType.String);

      if (!validateEmail(email)) {
        return t('error.email.invalid');
      }
      //TODO: validate that the email is unique on this particular person grid
    }

    if (prop === 'language' && person.language === Language.INVALID) {
      return t('error.language.unsupported');
    }

    // We've reached the bottom without flagging/returning any errors, therefore this value is OK
    return undefined;
  };

  const validateImportGridCell = (row: PersonRow, prop: string, parsing: boolean): string | undefined => {
    const gridCellValidator = (row: PersonRow, prop: keyof PersonRow, parsing: boolean): string | undefined => {
      if (prop === null) {
        //For some reason a "null" property is being passed in at the end of every row
        return;
      }
      if (prop === 'rowId') {
        return;
      }
      if (!row.rowId) {
        // Row not finished being created, so skip validation
        return;
      }

      if (isImportRowEmpty(row)) {
        // Empty row, so skip validation (per requirements)
        return;
      }

      const { rowId, ...sharedProps } = row;
      const person: Partial<Person> = {
        ...sharedProps,
        language: mapLanguage(sharedProps.language),
      };

      return validatePropOnPerson(person, prop, parsing);
    };

    const errorMessage = gridCellValidator(row, prop as keyof PersonRow, parsing);
    const key = `${row.rowId}.${prop}`;
    updateErrorKeys(key, errorMessage);

    return errorMessage;
  };

  const validateProp = (key: keyof Person, value: any): string | undefined => {
    const person: Partial<Person> = { [key]: value };
    const errorMessage = validatePropOnPerson(person, key);
    updateErrorKeys(key, errorMessage);
    return errorMessage;
  };

  const updateErrorKeys = (key: string, errorMessage: string | undefined) => {
    if (!setErrorKeys) return;

    const flagError = (key: string) => {
      setErrorKeys((prev) => {
        if (!prev.includes(key)) {
          return [...prev, key];
        }
        return prev;
      });
    };

    const clearError = (key: string) => {
      setErrorKeys((prev) => prev.filter((k) => k !== key));
    };

    if (errorMessage) {
      flagError(key);
    } else {
      clearError(key);
    }
  };

  return {
    validateImportGridCell,
    validateProp,
  };
};

export default usePeopleValidations;
