import { IonModal, IonCheckbox, IonLabel, IonButton } from '@ionic/react';
import { useState, useRef } from 'react';
import { disconnectIntegration } from '../integrationApi';
import useFetchIntegration from '../useFetchIntegration';

const DisconnectAccountModal = () => {
  const [isChecked, setIsChecked] = useState(false);
  const modalRef = useRef<HTMLIonModalElement>(null);
  const { fetchIntegration } = useFetchIntegration(false);

  const closeModal = async () => {
    setIsChecked(false);
    await modalRef.current?.dismiss();
  };

  return (
    <IonModal
      ref={modalRef}
      trigger="disconnect-account-button"
      showBackdrop
      style={{ '--border-color': 'transparent' }}
    >
      <div className="ion-padding">
        {/* Header */}
        <div>
          <h1>Heads up! Inspections will no longer generate task lists.</h1>
        </div>

        {/* Body */}
        <div className="ion-padding-vertical">
          <p>
            If you disconnect your company’s Otuvy QM and Otuvy Frontline accounts, inspections will no longer generate
            tasks lists by default.
          </p>
          <p>Would you like to disconnect your company account?</p>
        </div>

        {/* Checkmark */}
        <div className="d-flex ion-align-items-start ion-padding-vertical">
          <div style={{ paddingTop: '.25rem' }}>
            <IonCheckbox checked={isChecked} onClick={() => setIsChecked((prev) => !prev)} />
          </div>
          <IonLabel style={{ paddingLeft: '.75rem' }}>
            I understand that any Otuvy QM Inspections will no longer generate checklists in Otuvy Frontline for anyone
            in my company.
          </IonLabel>
        </div>

        {/* Buttons */}
        <div className="d-flex flex-direction-column ion-margin-top">
          <IonButton
            size="default"
            fill="clear"
            className="font-20 ion-margin-bottom"
            color="danger"
            disabled={!isChecked}
            onClick={() => {
              disconnectIntegration().then(fetchIntegration);
              closeModal();
            }}
          >
            Disconnect my company’s Otuvy QM Account.
          </IonButton>
          <IonButton
            size="default"
            fill="clear"
            className="font-20 ion-margin-bottom"
            color="primary"
            onClick={() => closeModal()}
          >
            Cancel
          </IonButton>
        </div>
      </div>
    </IonModal>
  );
};

export default DisconnectAccountModal;
