import { useRecoilValue } from 'recoil';
import ListImportDropzone from './ListImportDropzone';
import ListImportInstructionsPanel from './ListImportInstructionsPanel';
import ListImportValidationErrorsOutput, { listImportValidationErrorState } from './ListImportValidationErrorsOutput';

const ListImportPanel: React.FC = () => {
  const errors = useRecoilValue(listImportValidationErrorState);

  return (
    <div>
      <div style={{ marginLeft: '1.75rem', marginTop: '1.75rem', maxWidth: '1000px' }}>
        {errors.length > 0 ? <ListImportValidationErrorsOutput /> : null}
        <ListImportDropzone />
        <ListImportInstructionsPanel />
      </div>
    </div>
  );
};

export default ListImportPanel;
