import { IonContent, IonItem, IonPage } from '@ionic/react';
import React from 'react';
import './PeoplePage.css';
import { useTranslation } from 'react-i18next';

interface PeoplePageProps {
  children: any;
  tab: string;
}

const PeoplePage: React.FC<PeoplePageProps> = ({ children, tab }) => {
  const { t } = useTranslation();

  const title = t(`title.people`);

  return (
    <IonPage>
      <h1 className="ion-padding">{title}</h1>
      <IonContent class="ion-padding ion-margin-bottom">
        <div className="d-flex ion-justify-content-start ion-margin-bottom">
          <IonItem
            class={`ion-no-padding ion-text-center people-page ${tab === 'add' ? 'item-active' : ''}`}
            routerLink="/people/add"
            routerDirection="none"
          >
            {t('navigation.add')}
          </IonItem>
          <IonItem
            class={`ion-no-padding ion-text-center people-page ${tab === 'view' ? 'item-active' : ''}`}
            routerLink="/people/list"
            routerDirection="none"
          >
            {t('navigation.view')}
          </IonItem>
        </div>
        {children}
      </IonContent>
    </IonPage>
  );
};

export default PeoplePage;
