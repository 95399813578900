import { useEffect, useState } from 'react';
import useErrorMessage from '../error/useErrorMessage';
import { getAllLists, List } from '../features/lists/listsApi';
import { getAllPeople, Person } from '../features/people/peopleApi';

import ListOwnersGrid from '../features/lists/components/ListOwnersPanel/ListOwnersGrid';
import ListsPage from './ListsPage';
import { useRecoilState, useRecoilValue } from 'recoil';
import { gridRefreshState } from '../features/lists/gridRefreshState';
import { currentUserLanguage } from '../features/toolbar/Toolbar';

const ViewListsPage: React.FC = () => {
  const targetLanguage = useRecoilValue(currentUserLanguage);
  const [lists, setLists] = useState<List[]>([]);
  const [people, setPeople] = useState<Person[]>([]);
  const [updateGrid, setUpdateGrid] = useState<boolean>(false);
  const [isDataFetched, setIsDataFetched] = useState<boolean>(false);
  const [refreshGrid, setRefreshGrid] = useRecoilState(gridRefreshState);

  const refreshData = async () => {
    console.log('Refreshing data');
    console.log('targetLanguage', targetLanguage);
    await Promise.all([getAllLists(targetLanguage).then(setLists), getAllPeople().then(setPeople)]);
  };
  const { handleError } = useErrorMessage(refreshData);

  useEffect(() => {
    refreshData()
      .catch(() => handleError(true))
      .finally(() => {
        setIsDataFetched(true);
        document.querySelectorAll('ion-loading').forEach((loading) => loading.remove()); // The loading elements should be removed when isDataFetched is set to true, but not all are
      });
  }, [updateGrid]);

  useEffect(() => {
    if (refreshGrid) {
      setUpdateGrid(true);
      setRefreshGrid(false);
    }
  }, [refreshGrid]);

  return (
    <ListsPage tab="view">
      <ListOwnersGrid
        lists={lists}
        people={people}
        isDataFetched={isDataFetched}
        setUpdateGrid={setUpdateGrid}
        updateGrid={updateGrid}
        setIsDataFetched={setIsDataFetched}
      />
    </ListsPage>
  );
};

export default ViewListsPage;
