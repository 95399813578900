import { IonButton, IonInput, IonItem, IonLabel } from '@ionic/react';
import { EnvironmentConfig, getString } from '../../../../utils/environmentUtils';
import { Dispatch, SetStateAction, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface QmLoginFormProps {
  qmAuthToken: string;
  setQmAuthToken: Dispatch<SetStateAction<string>>;
}

const QmLoginForm: React.FC<QmLoginFormProps> = ({ qmAuthToken, setQmAuthToken }) => {
  const [QMUserName, setQMUserName] = useState<string>('');
  const [QMUserPassword, setQMUserPassword] = useState<string>('');
  const [isQMAuthTokenLoading, setIsQMAuthTokenLoading] = useState<boolean>(false);

  const [authenticationErrorMessage, setAuthenticationErrorMessage] = useState<string | undefined>();

  const QMUserNameInputRef = useRef<HTMLIonInputElement>(null);
  const QMUserPasswordInputRef = useRef<HTMLIonInputElement>(null);

  const { t } = useTranslation();

  const handleLinkAuth = async () => {
    try {
      console.log('Authenticating with QM');
      setIsQMAuthTokenLoading(true);
      setAuthenticationErrorMessage(undefined);

      if (!QMUserName) {
        setAuthenticationErrorMessage('Username is required');
        return;
      }
      if (!QMUserPassword) {
        setAuthenticationErrorMessage('Password is required');
        return;
      }

      const userNameParam = encodeURIComponent('username') + '=' + encodeURIComponent(QMUserName);
      const passwordParam = encodeURIComponent('password') + '=' + encodeURIComponent(QMUserPassword);
      const params = userNameParam + '&' + passwordParam;

      const requestOptions: RequestInit = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
        body: params,
      };

      const environmentSpecificQMDomain = getString(EnvironmentConfig.QM_DOMAIN);
      if (!environmentSpecificQMDomain) {
        console.warn('No explicit QM domain set!');
        return;
      }

      const url = environmentSpecificQMDomain + '/api/v1/authenticate';

      fetch(url, requestOptions)
        .then(async (response) => {
          const body: { authToken: string } | { error: string } = await response.json();

          if (response.status !== 200) {
            if (response.status === 404) {
              //The API defines this as "Invalid username or password", though it may get confused with the URL not resolving
              setAuthenticationErrorMessage('Invalid username or password');
            } else {
              if ('error' in body) {
                console.error('Error from auth attempt', body.error);
              }
            }
            return;
          }

          if ('authToken' in body && body.authToken.length > 0) {
            console.log('Authentication with QM appears to have succeeded!');
            /*
            TODO: there is potentially more validation that could be done at this step
            For example making sure the user is not a tech support user, or a client user
            */
            setQmAuthToken(body.authToken);

            const UserNameNativeInputElement = await QMUserNameInputRef.current?.getInputElement();
            const PasswordNativeInputElement = await QMUserPasswordInputRef.current?.getInputElement();
            UserNameNativeInputElement?.blur();
            PasswordNativeInputElement?.blur();
          }
        })
        .catch((error) => {
          console.error('Failed to authenticate', error);
          setAuthenticationErrorMessage('Failed to authenticate');
        });
    } catch (error) {
      console.error('Failed to authenticate', error);
      setAuthenticationErrorMessage('Failed to authenticate');
    } finally {
      setIsQMAuthTokenLoading(false);
    }
  };

  return (
    <div
      className={`ion-padding ion-margin-top ${qmAuthToken ? 'disable-pointer-events' : ''}`}
      style={{
        maxWidth: '320px',
        marginLeft: '2rem',
        textAlign: 'center',
        border: '1.32px solid #F9F9F9',
        borderRadius: '1rem',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        background: '#F9F9F9',
      }}
    >
      <IonItem color="transparent">
        <IonLabel position="stacked">Username</IonLabel>
        <IonInput
          ref={QMUserNameInputRef}
          class="centered-input"
          value={QMUserName}
          required={true}
          type={'text'}
          placeholder="Otuvy QM Username"
          onIonChange={(event) => setQMUserName(event.detail.value ?? '')}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              if (isQMAuthTokenLoading) return;
              if (QMUserName && QMUserPassword && !qmAuthToken) handleLinkAuth();
            }
          }}
        />
      </IonItem>

      <IonItem color="transparent">
        <IonLabel position="stacked">Password</IonLabel>
        <IonInput
          ref={QMUserPasswordInputRef}
          class="centered-input"
          value={QMUserPassword}
          required={true}
          type={'password'}
          placeholder="Otuvy QM Password"
          onIonChange={(event) => setQMUserPassword(event.detail.value ?? '')}
          onKeyDown={async (event) => {
            if (event.key === 'Enter') {
              if (isQMAuthTokenLoading) return;
              if (QMUserName && QMUserPassword && !qmAuthToken) handleLinkAuth();
            }
          }}
        />
      </IonItem>

      {authenticationErrorMessage && (
        <div>
          <IonLabel color="danger">{authenticationErrorMessage}</IonLabel>
        </div>
      )}

      <div className="ion-margin-top">
        <IonButton
          size="small"
          className="font-24"
          style={{
            width: '100%',
            transition: 'opacity 0.5s ease',
            '--background': `rgba(var(--ion-color-primary-rgb), ${qmAuthToken ? '0.7' : '1'})`,
          }}
          disabled={!QMUserName || !QMUserPassword || isQMAuthTokenLoading}
          onClick={handleLinkAuth}
        >
          {isQMAuthTokenLoading ? 'Loading...' : qmAuthToken ? 'Authorized!' : t('submit')}
        </IonButton>
      </div>
    </div>
  );
};

export default QmLoginForm;
