import { IonIcon, IonText, useIonAlert, useIonLoading, useIonToast } from '@ionic/react';
import { FileRejection, useDropzone } from 'react-dropzone';
import * as XLSX from 'xlsx';

import './ListImportGrid.css';
import { cloudUploadOutline } from 'ionicons/icons';
import { useCallback, useEffect, useState } from 'react';
import { ImportListInput, createLists } from '../../listsApi';
import { Person, getActivePeople } from '../../../people/peopleApi';
import { MaxFieldLengths } from '../../../../constants/constants';
import { ListImportValidationError, listImportValidationErrorState } from './ListImportValidationErrorsOutput';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { writeAccessRestrictedState } from '../../../../App';
import useGraphqlQuery from '../../../../hooks/useGraphqlQuery';
import useErrorMessage from '../../../../error/useErrorMessage';
import { sendErrorEmail } from '../../../../error/errorApi';
import { EnvironmentConfig, getFlag } from '../../../../utils/environmentUtils';
import { gridRefreshState } from '../../gridRefreshState';

const columnsHeaders: (keyof ImportListInput)[] = ['listName', 'listOwner', 'sharedWith', 'tasks'];
const listPropertiesCount = columnsHeaders.length - 1;

const acceptedFileTypes = {
  'application/vnd.ms-excel': ['.xls'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
};

const ListImportDropzone: React.FC = () => {
  const isWriteAccessRestricted = useRecoilValue(writeAccessRestrictedState);
  const setValidationErrors = useSetRecoilState(listImportValidationErrorState);

  const [filesInProcess, setFilesInProcess] = useState<File[]>([]);

  const { data: users, isLoading: isLoadingUsers } = useGraphqlQuery(getActivePeople);
  const [presentLoadingSpinner, dismissLoadingSpinner] = useIonLoading();
  const [presentToast] = useIonToast();
  const [presentAlert] = useIonAlert();
  const { t } = useTranslation();
  const { handleError } = useErrorMessage();
  const setGridRefresh = useSetRecoilState(gridRefreshState);

  useEffect(() => {
    if (filesInProcess.length === 0 || isLoadingUsers) return;

    processFiles(filesInProcess);
    setFilesInProcess([]);
  }, [filesInProcess, users]);

  const onDrop = async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    if (fileRejections.length > 0) {
      console.error('File rejected:', fileRejections[0].errors[0].message);
      presentAlert({
        cssClass: 'list-import-alert',
        header: t('page.listImport.dialog.error.fileType.header'),
        message: t('page.listImport.dialog.error.fileType.message'),
        buttons: [t('button.acknowledge')],
      });
      return;
    }

    // We need to await presenting the spinner so it will be there when we try to dismiss it later
    await presentLoadingSpinner({
      message: t('loading.message.list.import'),
      spinner: 'crescent',
      showBackdrop: true,
    });

    setFilesInProcess(acceptedFiles);
  };

  const processFiles = useCallback(
    (acceptedFiles: File[]) => {
      acceptedFiles.forEach((file: Blob) => {
        const reader = new FileReader();

        reader.onload = async (e: ProgressEvent<FileReader>) => {
          try {
            const bstr = e.target?.result;

            if (typeof bstr !== 'string') {
              throw new Error('bstr is not a string');
            }

            const { dataColumns, isFirstColumnHeader } = parseSheet(bstr);
            console.log(dataColumns, 'dataColumns');

            const errors: ListImportValidationError[] = validate(dataColumns, isFirstColumnHeader);
            if (errors.length > 0) {
              // We wait for dismissal of the loading spinner before setting state because focus returns to the dropzone after dismissing,
              // and we want to scroll the top of the errors panel into view, so dismissal must complete first.
              await dismissLoadingSpinner();
              setValidationErrors(errors);
              return;
            }
            setValidationErrors(errors);

            const createListInputs: ImportListInput[] = prepareApiInput(dataColumns);
            console.log(createListInputs, 'createListInputs');

            console.time('Sending lists');
            const successCount = await createLists(createListInputs);
            console.timeEnd('Sending lists');
            console.log('Finished sending lists.');

            if (successCount < createListInputs.length) {
              console.warn(`Only ${successCount} lists were created out of ${createListInputs.length}`);
              throw new Error('Some lists were not created');
            }

            presentToast({
              duration: 5000,
              cssClass: 'list-import-toast',
              message: t('feedback.list.import.success', { count: successCount }),
              position: 'bottom',
            });

            // Trigger refresh of lists
            setGridRefresh(true);
          } catch (error) {
            handleError(true);
            console.error('Error sending lists:', error);
            if (getFlag(EnvironmentConfig.CAPTURE_LOGS)) sendErrorEmail('Error importing lists');
          } finally {
            dismissLoadingSpinner();
          }
        };

        reader.readAsBinaryString(file);
      });
    },
    [users]
  );

  const parseSheet = (bstr: any): { dataColumns: ImportListInput[]; isFirstColumnHeader: boolean } => {
    const wb = XLSX.read(bstr, { type: 'binary' });

    /* Get first worksheet */
    const wsname = wb.SheetNames[0];
    const ws = wb.Sheets[wsname];

    // Read the sheet as rows.
    const dataRows: Array<Array<string | number | boolean | Date>> = XLSX.utils.sheet_to_json(ws, { header: 1 });

    // We need the columns, so we transpose the rows.
    const dataColumns: Array<Array<string | number | boolean | Date>> = transposeRowsToColumns(dataRows);
    const isFirstColumnHeader = (dataColumns[0][0] as string).slice(0, 9).toLowerCase() === 'list name'; //TODO: If the first cell in the template changes so it doesn't start with "list name", this will need to be updated.
    const dataColumnsWithoutHeader = isFirstColumnHeader ? dataColumns.slice(1) : dataColumns;

    // Looping through the columns.
    const baseCreatedOn = new Date();
    const dataColumnsFinalArr: ImportListInput[] = dataColumnsWithoutHeader.map((col, i) =>
      parseColumn(col, new Date(baseCreatedOn.getTime() + i))
    );

    return {
      dataColumns: dataColumnsFinalArr,
      isFirstColumnHeader,
    };
  };

  const transposeRowsToColumns = (
    dataRows: Array<Array<string | number | boolean | Date>>
  ): Array<Array<string | number | boolean | Date>> => {
    if (dataRows.length === 0) return [];

    console.log(dataRows, 'dataRows');

    const maxColumnIndexWithData = dataRows.reduce((maxColumnIndex, row) => {
      const columnCount = row.length;
      if (columnCount > maxColumnIndex) {
        return columnCount;
      }
      return maxColumnIndex;
    }, 0);
    const dataColumns: Array<Array<string | number | boolean | Date>> = [];
    for (let columnIndex = 0; columnIndex < maxColumnIndexWithData; columnIndex++) {
      const dataColumn = dataRows
        .map((row) => row[columnIndex] ?? '')
        .filter((columnCell, rowIndex) => rowIndex < listPropertiesCount || columnCell !== ''); //Include all list properties and any tasks that are not empty.
      dataColumns.push(dataColumn);
    }

    console.log(dataColumns, 'dataColumns');
    return dataColumns;
  };

  const parseColumn = (column: (string | number | boolean | Date)[], createdOn: Date): ImportListInput => {
    const listPropertiesObject: { [s: string]: string | number | boolean | Date } = {};
    const tasksArray: (string | number | boolean | Date)[] = [];
    for (let index = 0; index < columnsHeaders.length; index++) {
      if (index === listPropertiesCount) {
        //At this point, take the rest of the rows as tasks
        tasksArray.push(...column.slice(3));
        break;
      }
      listPropertiesObject[columnsHeaders[index]] = column[index];
    }

    const parsedColumn: ImportListInput = {
      listName: '',
      createdOn,
    };
    Object.entries(listPropertiesObject).forEach(([key, value]) => {
      const trimmedValue = value.toString().trim();
      if (trimmedValue === '') return;

      switch (key as keyof ImportListInput) {
        case 'listName':
          parsedColumn.listName = trimmedValue;
          break;
        case 'listOwner':
          parsedColumn.listOwner = trimmedValue;
          break;
        case 'sharedWith':
          parsedColumn.sharedWith = trimmedValue
            .split(',')
            .map((email) => email.trim())
            .filter((email) => email !== '');
          break;
      }
    });
    parsedColumn.tasks = tasksArray.length > 0 ? tasksArray.map((task) => task.toString().trim()) : undefined;

    return parsedColumn;
  };

  const validate = (
    dataColumnsFinalArr: ImportListInput[],
    isFirstColumnHeader: boolean
  ): ListImportValidationError[] => {
    const validateColumn = (
      { listName, listOwner, sharedWith }: ImportListInput,
      columnIndex: number
    ): ListImportValidationError[] => {
      const errorMessagesAndEmails: [string, string | undefined][] = [];

      if (listName === '') {
        errorMessagesAndEmails.push([t('error.list.listName.required'), undefined]);
      }

      if (listOwner) {
        const ownerId: string | undefined = getUserIdFromEmail(listOwner);
        if (!ownerId) {
          errorMessagesAndEmails.push([t('error.list.listOwner.noMatchingEmail'), listOwner]);
        }
      }

      if (sharedWith) {
        for (const email of sharedWith) {
          const sharedWithId = getUserIdFromEmail(email);
          if (!sharedWithId) {
            errorMessagesAndEmails.push([t('error.list.sharedWith.noMatchingEmail'), email]);
          }
        }
      }

      if (errorMessagesAndEmails.length > 0) {
        const i = isFirstColumnHeader ? columnIndex + 1 : columnIndex; // +1 since we removed the header column
        const columnName = XLSX.utils.encode_col(i);

        return errorMessagesAndEmails.map(
          ([errorMessage, email]): ListImportValidationError => ({
            columnName,
            listName,
            errorMessage,
            email,
          })
        );
      }
      return [];
    };

    const errors: ListImportValidationError[] = dataColumnsFinalArr.flatMap(validateColumn);
    return errors;
  };

  const getUserIdFromEmail = (email: string): string | undefined => {
    if (!users) {
      throw new Error('Users not loaded yet');
    }
    return users.find((user: Person) => user.email.toLowerCase() === email.toLowerCase())?.userId;
  };

  const prepareApiInput = (dataColumnsFinalArr: ImportListInput[]): ImportListInput[] => {
    const lists: ImportListInput[] = dataColumnsFinalArr.map<ImportListInput>((rawList) => {
      const truncatedListName: string = rawList.listName.slice(0, MaxFieldLengths.LIST_NAME);
      const ownerId: string | undefined = rawList.listOwner ? getUserIdFromEmail(rawList.listOwner) : undefined;
      const sharedWithIds: string[] | undefined = rawList.sharedWith?.map(
        (email) => getUserIdFromEmail(email) as string
      ); // We know these will find matches because we validated them earlier.
      const truncatedTaskNames: string[] | undefined = rawList.tasks?.map((taskName) =>
        taskName.slice(0, MaxFieldLengths.TASK_NAME)
      );

      return {
        listName: truncatedListName,
        listOwner: ownerId,
        createdOn: rawList.createdOn,
        sharedWith: sharedWithIds,
        tasks: truncatedTaskNames,
      };
    });

    return lists;
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptedFileTypes,
  });

  return (
    <div className={`ion-margin-bottom ${isWriteAccessRestricted ? 'disabled' : ''}`}>
      <div className="dropzone" {...getRootProps()}>
        <input {...getInputProps()} />
        <IonIcon className="cloud-icon" icon={cloudUploadOutline} />
        <p>{t('page.listImport.dropzone.message')}</p>
        <IonText color="medium" className="font-16">
          {t('page.listImport.dropzone.message2')}
        </IonText>
      </div>
    </div>
  );
};

export default ListImportDropzone;
