import { parseISO } from 'date-fns';
import { graphql } from '../../utils/api/apiUtils';
import { AgreementStatus } from '../../constants/constants';

export interface OrganizationMetrics {
  storageSpaceInMB: number;
  numberOfLists: number;
  numberOfTasks: number;
  numberOfEmployees: number;
  numberOfAdmins: number;
}

export const getMetrics = async (): Promise<OrganizationMetrics> => {
  const query = `
    query getOrganizationMetricsQuery {
      getOrganizationMetrics {
        storageSpaceInMB
        numberOfLists
        numberOfTasks
        numberOfEmployees
        numberOfAdmins
      }
    }
  `;
  const metrics = await graphql<OrganizationMetrics>(query);
  console.log('got organization metrics', metrics);
  return metrics;
};

export interface OrganizationGovernors {
  organizationId: string;
  isPaid: boolean;
  isWriteAccessRestricted: boolean;
  isLocked: boolean;
  maxStorageSpaceInMb: number;
  storageSpaceLastChecked: Date | null;
  governorLevels: GovernorLevel[];

  //Technically this is a person level, not an org level
  agreementState: AgreementStatus[];
}

export interface GovernorLevel {
  key: string;
  level: number;
  limitExceededOn: Date | null;
}

const downloadCurrentUserAgreementStatus = async (): Promise<AgreementStatus[]> => {
  const query = `
      query getCurrentUserQuery {
        getCurrentUserBase {
            agreementStatus {
              agreementType,
              agreementStatus
            }            
        }
      }
    `;

  try {
    const agreementData = await graphql<any>(query);
    return agreementData.agreementStatus;
  } catch (e) {
    console.error('Error fetching current user', e);
  }
  return [];
};

export const getGovernors = async (): Promise<OrganizationGovernors> => {
  const query = `
    query getOrganizationGovernorsQuery {
      getOrganizationGovernors {
        organizationId
        isPaid
        isWriteAccessRestricted
        isLocked
        maxStorageSpaceInMb
        storageSpaceLastChecked
        governorLevels {
          key
          level
          limitExceededOn
        }
      }
    }
  `;
  const governors = await graphql<OrganizationGovernors>(query);
  const parsedGovernors = parseGovernors(governors);
  parsedGovernors.agreementState = await downloadCurrentUserAgreementStatus();
  return parsedGovernors;
};

const parseGovernors = (governors: OrganizationGovernors): OrganizationGovernors => {
  return {
    ...governors,
    storageSpaceLastChecked: governors.storageSpaceLastChecked
      ? parseISO(governors.storageSpaceLastChecked as any)
      : null,
    governorLevels: governors.governorLevels.map((gl) => ({
      ...gl,
      limitExceededOn: gl.limitExceededOn ? parseISO(gl.limitExceededOn as any) : null,
    })),
  };
};
