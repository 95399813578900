// @ts-ignore Requires --resolveJsonModule flag on tsc to resolve correctly
import environment from './environment.json';

//https://youtu.be/pXd9BCwpjhA?t=2623
const amplifyConfig = {
  aws_project_region: environment['VeriTask'].ProjectRegion,

  aws_appsync_region: environment['VeriTask'].ProjectRegion,
  aws_appsync_graphqlEndpoint: environment['VeriTask'].AppSyncUrl,

  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',

  cognito: {
    USER_POOL_ID: environment['VeriTask'].USERPOOLID,
    APP_CLIENT_ID: environment['VeriTask'].CLIENTAPPID,
    IDENTITY_POOL_ID: environment['VeriTask'].IDENTITYPOOLID,
  },

  aws_cognito_region: environment['VeriTask'].ProjectRegion,
  aws_user_pools_id: environment['VeriTask'].USERPOOLID,
  aws_user_pools_web_client_id: environment['VeriTask'].CLIENTAPPID,

  Auth: {
    region: environment['VeriTask'].ProjectRegion,
    userPoolId: environment['VeriTask'].USERPOOLID,
    userPoolWebClientId: environment['VeriTask'].CLIENTAPPID,
  },
};

export default amplifyConfig;

export const STRIPE_API_BASE_URL = environment['VeriTask'].StripeApiEndpoint;
export const FILE_API_BASE_URL = environment['VeriTask'].FileApiEndpoint;
export const TIME_TRACKING_API_BASE_URL = environment['VeriTask'].TimeTrackingApiEndpoint;
export const QM_API_BASE_URL = environment['VeriTask'].QMApiEndpoint;
