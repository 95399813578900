import ListImportPanel from '../features/lists/components/ListImport/ListImportPanel';
import ListsPage from './ListsPage';

const ListImportPage: React.FC = () => {
  return (
    <ListsPage tab="import">
      <ListImportPanel />
    </ListsPage>
  );
};

export default ListImportPage;
