import { useIonModal } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import useToast from '../../../../hooks/useToast';
import { Person, putPeople, PutPersonInput } from '../../peopleApi';
import AddPersonDialog from './AddPersonDialog';

const useAddPersonDialog = (refreshData: () => void, people: Person[]) => {
  const { t } = useTranslation();
  const [presentToast] = useToast();

  const dismissModal = () => {
    dismiss();
  };

  const savePerson = (person: PutPersonInput[]) => {
    putPeople(person).then((user) => {
      refreshData();

      if (user.createdUsers.length > 0 && user.failedUsers.length < 1) {
        presentToast(t('feedback.person.added'), 10000, 'bottom', 'dark');
      } else if (user.failedUsers.length > 0) {
        //TODO: make this toast give better details.  Use the multi user error messages?
        presentToast('An error occurred when trying to add a new user. Please try again.', 6000, 'top', 'error');
        console.warn('User failed to upload:', user.failedUsers);
      }
    });
  };
  const [present, dismiss] = useIonModal(AddPersonDialog, {
    dismiss: dismissModal,
    savePerson,
  });

  return {
    present,
  };
};

export default useAddPersonDialog;
