import React from 'react';
import { IonItem, IonLabel, IonText } from '@ionic/react';

interface StepProps {
    stepTitle: string;
    StepSubtitle: string;
    
}

const StepsLabel: React.FC<StepProps> = ({stepTitle, StepSubtitle}) => {

  return (
    <IonItem lines="none">
      <IonLabel>
        <h1><IonText className='font-weight-500 font-22'>{stepTitle}</IonText></h1>
        <p className="ion-padding-start">{StepSubtitle}</p>
      </IonLabel>
      </IonItem>
    );
}
export default StepsLabel;