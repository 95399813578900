import { IonContent, IonGrid, IonPage } from '@ionic/react';
import { PropsWithChildren } from 'react';

interface PageProps {
  title: string;
}

const Page: React.FC<PropsWithChildren<PageProps>> = ({ children, title }) => {
  return (
    <IonPage>
      <IonGrid fixed={true}>
        <IonContent class="ion-padding ion-margin-bottom">
          <h1>{title}</h1>
          {children}
        </IonContent>
      </IonGrid>
    </IonPage>
  );
};

export default Page;
