import { addDays, differenceInDays, subDays } from 'date-fns';
import { GovernorLevel } from '../usageApi';
import { GovernorLevelConstants, GovernorLevelConstantsGroup, GovernorLevelKey } from '../../../constants/constants';
import { useRecoilValue } from 'recoil';
import { governorsState } from './useFetchGovernors';

const useGovernor = (key: GovernorLevelKey) => {
  const governors = useRecoilValue(governorsState);

  const constants: GovernorLevelConstantsGroup | undefined = GovernorLevelConstants.find((glc) => glc.key === key);
  const governorLevel: GovernorLevel | undefined = governors?.governorLevels?.find((gl) => gl.key === key);
  if (!constants || !governorLevel) return;

  const {
    WARNING_LEVEL_1_THRESHOLD_PERCENT,
    WARNING_LEVEL_2_THRESHOLD_PERCENT,
    DAYS_BEFORE_WRITE_ACCESS_RESTRICTED,
    DAYS_BEFORE_LOCKED,
  } = constants;
  const { level, limitExceededOn } = governorLevel;

  const daysPastLimit: number | null = limitExceededOn ? differenceInDays(new Date(), limitExceededOn) : null;
  const writeAccessRestrictedDate: Date | null = limitExceededOn
    ? addDays(limitExceededOn, DAYS_BEFORE_WRITE_ACCESS_RESTRICTED)
    : null;
  const isWriteAccessRestricted: boolean = !!writeAccessRestrictedDate && new Date() > writeAccessRestrictedDate;
  const writeAccessRestrictionWithin7Days: boolean =
    !!writeAccessRestrictedDate && !isWriteAccessRestricted && new Date() > subDays(writeAccessRestrictedDate, 7);
  const lockdownDate: Date | null = limitExceededOn ? addDays(limitExceededOn, DAYS_BEFORE_LOCKED) : null;
  const isLocked: boolean = !!lockdownDate && new Date() > lockdownDate;

  return {
    WARNING_LEVEL_1_THRESHOLD_PERCENT,
    WARNING_LEVEL_2_THRESHOLD_PERCENT,
    level,
    limitExceededOn,
    daysPastLimit,
    writeAccessRestrictedDate,
    isWriteAccessRestricted,
    writeAccessRestrictionWithin7Days,
    lockdownDate,
    isLocked,
  };
};

export default useGovernor;
