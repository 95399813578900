import { IonImg, useIonViewDidEnter } from '@ionic/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import { integrationState } from '../useFetchIntegration';
import { getIntegrationInfo, updateDefaultListOwner } from '../integrationApi';

import appLogo from '../../../../assets/images/qm-logo.png';
import DefaultListOwnerPanel from '../general/DefaultListOwnerPanel';
import QmIntegrationInstructions from '../general/QmIntegrationInstructions';
import DisconnectAccountButton from './DisconnectAccountButton';

interface IntegrationsDisconnectPanelProps {
  defaultListOwnerFromServer: string | undefined;
}

// TODO: This component will be replace with the IntegrationsSettingsPage component in the future
const IntegrationsDisconnectPanel: React.FC<IntegrationsDisconnectPanelProps> = ({ defaultListOwnerFromServer }) => {
  const [defaultListOwner, setDefaultListOwner] = useState<string | undefined>();
  const setIntegration = useSetRecoilState(integrationState);

  useIonViewDidEnter(() => {
    getIntegrationInfo().then((integration) => {
      setIntegration(integration);
      setDefaultListOwner(integration.defaultListOwner);
    });
  }, []);

  const { t } = useTranslation();

  const onDefaultListOwnerChange = async (personId: string) => {
    try {
      console.log('Default list owner changed to:', personId);
      await updateDefaultListOwner(personId);
      setDefaultListOwner(personId);
    } catch (error) {
      console.error('Failed to update QM default list owner!', error);
    }
  };

  return (
    <div className="ion-padding" style={{ maxWidth: '800px' }}>
      {/* Integrations Disconnect View Header */}
      {/* we could use the same header for the unlink view as well, but is not worth the effort adding that abstraction at this point */}
      <div style={{ marginBottom: '2.5rem' }}>
        <div className="d-flex ion-align-items-center">
          <h1 className="ion-no-margin font-24 font-weight-400">Your organization’s</h1>
          {/* TODO: Reach out to Buck to find out if thre is a better logo we could use for this */}
          <IonImg src={appLogo} />
          <h1 className="ion-no-margin font-24 font-weight-400">account is connected.</h1>
        </div>
        <QmIntegrationInstructions />
      </div>

      {/* Settings */}
      <div>
        <h1 style={{ marginBottom: '2.5rem' }} className="ion-no-margin font-24 font-weight-400">
          Otuvy QM Settings
        </h1>

        <div style={{ marginBottom: '6rem' }}>
          <DefaultListOwnerPanel
            defaultListOwner={defaultListOwner}
            onDefaultListOwnerChange={onDefaultListOwnerChange}
          />
        </div>
      </div>

      <DisconnectAccountButton />
    </div>
  );
};

export default IntegrationsDisconnectPanel;
