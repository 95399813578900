import environment from '../environment.json';

export const getFlag = (flagKey: string): boolean => {
  if (!environment.hasOwnProperty('environmentConfig')) return false;

  const flagValue = (environment as any)['environmentConfig'][flagKey];
  if (flagValue) return true;

  return false;
};

export const getNumber = (key: string, defaultValue?: number): number => {
  if (!environment.hasOwnProperty('environmentConfig') || !environment.environmentConfig.hasOwnProperty(key)) {
    if (defaultValue !== undefined) {
      return defaultValue;
    }
    throw new Error('Failed to get number from environmentConfig and no default value was provided');
  }

  const value = (environment as any)['environmentConfig'][key];
  if (value === undefined) throw new Error('Failed to get number from environmentConfig');

  return value;
};

export const getString = (key: string): string | undefined => {
  if (!environment.hasOwnProperty('environmentConfig')) throw new Error('Failed to get string from environmentConfig');

  const value = (environment as any)['environmentConfig'][key];
  if (value === undefined) {
    return undefined;
  }

  return value;
};

export enum EnvironmentConfig {
  // Address flags
  APP_DOMAIN = 'appDomain',
  QR_APP_DOMAIN = 'qrAppDomain',
  ADMIN_PORTAL_DOMAIN = 'adminPortalDomain',
  QM_DOMAIN = 'qmDomain',

  // Other permanent flags
  CAPTURE_LOGS = 'captureLogs',

  // Temporary flags
  LIST_IMPORT = 'listImport',
  SHOW_SETTINGS = 'showSettings',
  SHOW_INTEGRATION_LINKING = 'showIntegrationLinking',
  SHOW_INTEGRATION_SUB_PAGES = 'showIntegrationSubPages',
}
