import { GraphqlCustomError, GraphqlCustomErrorType, graphql } from '../../utils/api/apiUtils';

export interface List {
  listId: string;
  listName: string;
  owner: string;
  createdOn: string;
  sharedWith?: string[];
}

export const getAllLists = async (targetLanguage?: string | undefined): Promise<List[]> => {
  const query = `
      query listsQuery {
        getAllLists {
          listId
          listName
          owner
          createdOn
          sharedWith
        }
      }
    `;

  const lists = await graphql<List[]>(query, undefined, targetLanguage);
  console.log('got lists', lists);
  return lists;
};

export interface ListOwnerInput {
  listId: string;
  ownerId: string;
}

export const transferOwnershipOfLists = async (input: ListOwnerInput[]) => {
  const query = `
    mutation transferOwnersMutation($input: [ListOwnerInput!]!) {
      transferOwnershipOfLists(input: $input)
    }
  `;
  const variables = { input };
  await graphql(query, variables);
  console.log('lists ownership transferred', input);
};

export const deleteLists = async (listIds: string[]) => {
  const query = `
    mutation deleteListsMutation($listIds: [String!]!) {
      deleteLists(listIds: $listIds)
    }
  `;
  const variables = { listIds };
  await graphql(query, variables);
  console.log('lists deleted', listIds);
};

const UPLOAD_LISTS_MAX_BATCH_SIZE = 50;
const UPLOAD_LISTS_MIN_BATCH_SIZE = 5;
const UPLOAD_LISTS_MIN_ATTEMPTS = 3;
const UNRECOVERABLE_ERROR_MESSAGE =
  'We were unable to get the IDs of the records in order to try importing again or to remove them from the database.' +
  ' This is unrecoverable on the client and can only be recovered from by an Otuvy developer.';

export interface ImportListInput {
  listChangeId?: string;
  listId?: string;
  listName: string;
  listOwner?: string;
  createdOn: Date;
  sharedWith?: string[];
  sharedWithChangeId?: string;
  tasks?: string[];
  taskChangeIds?: string[];
  taskIds?: string[];
}

interface ImportListIds {
  listChangeLogIds?: string[];
  sharedWithChangeLogIds?: string[];
  taskChangeLogIds?: string[];
  listIds?: string[];
  taskIds?: string[];
}

export interface ImportListResponse extends ImportListIds {
  success: boolean;
}

export const createLists = async (inputs: ImportListInput[]): Promise<number> => {
  const inputsCopy = [...inputs];

  const successes: ImportListInput[] = [];
  while (inputsCopy.length > 0) {
    const listBatch = inputsCopy.splice(0, UPLOAD_LISTS_MAX_BATCH_SIZE);
    successes.push(...(await createListsBatch(listBatch)));
  }

  console.log('Lists created', successes);
  return successes.length;
};

const createListsBatch = async (inputs: ImportListInput[], attempts: number = 0): Promise<ImportListInput[]> => {
  const query = `
    mutation createListsMutation($inputs: [ImportListInput!]!) {
      importLists(importListInputs: $inputs) {
        success
        listChangeLogIds
        sharedWithChangeLogIds
        taskChangeLogIds
        listIds
        taskIds
      }
    }
  `;
  const variables = { inputs };

  try {
    const { success, ...ids } = await graphql<ImportListResponse>(query, variables);

    if (success) {
      console.log('Batch of lists created', inputs);
      return inputs;
    } else {
      console.error('Failed to create batch of lists', ids, inputs);
      return retryCreateListsBatch(inputs, ids, attempts + 1);
    }
  } catch (error) {
    if (error instanceof GraphqlCustomError) {
      if (error.errorType === GraphqlCustomErrorType.RequestTooLarge) {
        console.error('Request was too large', inputs);
        return retryCreateListsBatch(inputs, {}, attempts + 1, true);
      } else if (error.errorType === GraphqlCustomErrorType.ResponseTooLarge) {
        console.error(`Response was too large. ${UNRECOVERABLE_ERROR_MESSAGE}`, inputs);
        throw error;
      } else if (error.errorType === GraphqlCustomErrorType.Timeout) {
        console.error(`Request timed out. ${UNRECOVERABLE_ERROR_MESSAGE}`, inputs);
        throw error;
      }
    }
    console.error(
      `Unhandled error calling API to create batch of lists. ${UNRECOVERABLE_ERROR_MESSAGE}`,
      error,
      inputs
    );
    throw error;
  }
};

const retryCreateListsBatch = async (
  inputs: ImportListInput[],
  ids: ImportListIds,
  attempts: number,
  tooLarge: boolean = false
): Promise<ImportListInput[]> => {
  const inputsWithIds = convertInputsToInputsWithIds(inputs, ids);

  if (inputsWithIds.length <= UPLOAD_LISTS_MIN_BATCH_SIZE) {
    if (tooLarge) {
      console.log('Min batch size already attempted and request too large, no more retries for this batch');
      return [];
    } else if (attempts >= UPLOAD_LISTS_MIN_ATTEMPTS) {
      console.log(
        'Min batch size already attempted and min number of attempts reached, no more retries for this batch'
      );
      await removeCreatedListsBatch(inputsWithIds);
      return [];
    } else {
      console.log('Min batch size already attempted, retrying the batch again');
      return createListsBatch(inputsWithIds, attempts);
    }
  }

  console.log('Splitting the batch in half and attempting to create each batch separately');
  const medianIndex = Math.floor(inputsWithIds.length / 2);
  const splitBatch1 = inputsWithIds.slice(0, medianIndex);
  const splitBatch2 = inputsWithIds.slice(medianIndex);

  return [...(await createListsBatch(splitBatch1, attempts)), ...(await createListsBatch(splitBatch2, attempts))];
};

const convertInputsToInputsWithIds = (inputs: ImportListInput[], ids: ImportListIds): ImportListInput[] => {
  let sharedWithIndex = 0;
  let taskIndex = 0;

  const inputsWithIds = inputs.map<ImportListInput>((input, listIndex) => {
    let sharedWithChangeId: string | undefined = undefined;
    if (input.sharedWith && input.sharedWith.length > 0) {
      sharedWithChangeId = ids.sharedWithChangeLogIds?.[sharedWithIndex];
      sharedWithIndex++;
    }

    let taskChangeIds: string[] | undefined = undefined;
    let taskIds: string[] | undefined = undefined;
    if (input.tasks && input.tasks.length > 0) {
      const nextTaskIndex = taskIndex + input.tasks.length;
      taskChangeIds = ids.taskChangeLogIds?.slice(taskIndex, nextTaskIndex);
      taskIds = ids.taskIds?.slice(taskIndex, nextTaskIndex);
      taskIndex = nextTaskIndex;
    }

    return {
      ...input,
      listChangeId: ids.listChangeLogIds?.[listIndex],
      sharedWithChangeId,
      taskChangeIds,
      listId: ids.listIds?.[listIndex],
      taskIds,
    };
  });

  return inputsWithIds;
};

const removeCreatedListsBatch = async (inputs: ImportListInput[]) => {
  console.log('Removing imported lists', inputs);

  const query = `
    mutation removeCreatedListsMutation($inputs: [ImportListInput!]!) {
      importLists(importListInputs: $inputs, nuke: true) {
        success
      }
    }
  `;
  const variables = { inputs };

  try {
    const { success } = await graphql<{ success: boolean }>(query, variables);
    if (!success) {
      console.error('Failed to remove imported lists');
    }
  } catch (error) {
    console.error('Error calling API to remove imported lists');
  }
};
