import { logOut as authLogOut } from '@otuvy/auth';

const useLogout = () => {
  async function logOut() {
    try {
      await authLogOut();
      //TODO: redirect to login page
    } catch (err) {
      console.error('Sign-out error', err);
      alert('Failed to log out');
    }
  }

  return {
    logOut,
  };
};

export default useLogout;
