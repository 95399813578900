import React, { FormEvent, useRef } from 'react';
import {
  IonItem,
  IonButton,
  IonInput,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonText,
  IonPage,
  IonContent,
} from '@ionic/react';
import logo from '../../assets/images/app-logo.png';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import { errorAlertMessageKeyState } from '../../error/ErrorAlert';
import { useSignIn } from '@otuvy/auth';

/*
https://www.davidsalter.com/posts/creating-a-custom-login-page-with-aws-amplify/
https://docs.amplify.aws/lib/auth/emailpassword/q/platform/js/

Assumption is that any time they are validating they have an internet connection.
Currently, no "offline" authentication is allowed.
*/

const SignIn: React.FC = () => {
  const setErrorMessageKey = useSetRecoilState(errorAlertMessageKeyState);

  const {
    email,
    setEmail,
    validateEmail,
    hideRequestOtp,
    setHideRequestOtp,
    requestOtp,
    requestNewOTP,
    otpConfirmation,
    setOtpConfirmation,
    verifyOtp,
    otpValidationFailed,
  } = useSignIn({
    isAdminPortal: true, //<----------------------make sure this is set correctly for the project TODO: move to env file
    setErrorMessageKey,
  });

  const { t } = useTranslation();
  const emailInputRef = useRef<HTMLIonInputElement | null>(null);

  const handleEmailSubmit = async (event: MouseEvent | KeyboardEvent | FormEvent | CustomEvent<FocusEvent>) => {
    try {
      event.preventDefault();
      emailInputRef.current?.getInputElement().then((input) => input.blur()); // This is needed when submitting with the enter key to avoid a potential focus event when returning the app to the foreground
      requestOtp();
    } catch (error) {
      console.error('Failed to submit Email', error);
    }
  };

  const handleOTPSubmit = async (event: MouseEvent | KeyboardEvent | FormEvent | CustomEvent<FocusEvent>) => {
    try {
      event.preventDefault();
      await verifyOtp();
    } catch (error) {
      console.error('Failed to submit OTP request', error);
    }
  };

  return (
    <IonPage>
      <IonContent>
        <IonGrid class="h-100 ion-padding-horizontal ion-align-items-center">
          <IonRow class="h-100 ion-justify-content-center ion-align-items-center">
            <IonCol size="12" sizeMd="10" sizeLg="6">
              <IonRow class="ion-justify-content-center">
                <IonCol size="12">
                  <IonImg src={logo} />
                </IonCol>
              </IonRow>
              <IonRow class="ion-justify-content-center">
                <IonCol size="12">
                  <IonItem>
                    <form onSubmit={handleEmailSubmit} style={{ width: '100%' }}>
                      <IonLabel style={{ fontSize: '30px' }} class="font-weight-700" position="floating">
                        {t('email')}
                      </IonLabel>
                      <IonInput
                        class="centered-input"
                        ref={emailInputRef}
                        placeholder={t('placeholder.email')}
                        value={email}
                        type={'email'}
                        autocomplete="email"
                        autofocus={true}
                        onIonChange={(event) => setEmail(event.detail.value?.trim().toLowerCase() ?? '')}
                        // onIonInput={(event) => validateAndSetEmail(event.detail.value?.toString().trim().toLowerCase() ?? '')} //TODO: use this after upgrading to Ionic 7
                        onIonFocus={() => setHideRequestOtp(false)} // This functionality is per design
                      />
                    </form>
                  </IonItem>
                </IonCol>
              </IonRow>
              {!hideRequestOtp ? (
                <>
                  <IonRow>
                    <IonCol size="12">
                      <IonItem lines="none">
                        <IonText class="ion-text-center ion-margin-horizontal">
                          <h6>{t('otpPrompt')}</h6>
                        </IonText>
                      </IonItem>
                    </IonCol>
                  </IonRow>
                  <IonRow class="ion-justify-content-center">
                    <IonCol size="12">
                      <IonButton color="primary" disabled={!validateEmail(email)} expand="block" onClick={requestOtp}>
                        {t('sendCode')}
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </>
              ) : (
                <>
                  <IonRow>
                    <IonCol size="12">
                      <IonItem lines="none">
                        <IonText class="ion-text-center ion-margin-horizontal">
                          <h6>{t('otpSent')}</h6>
                        </IonText>
                      </IonItem>
                    </IonCol>
                  </IonRow>

                  <IonRow>
                    <IonCol size="12">
                      <IonItem>
                        <form style={{ width: '100%' }} onSubmit={handleOTPSubmit}>
                          <IonLabel style={{ fontSize: '30px' }} class="font-weight-700" position="floating">
                            {t('otpCode')}
                          </IonLabel>
                          <IonInput
                            class="centered-input"
                            placeholder={t('placeholder.otp')}
                            value={otpConfirmation}
                            type={'number'}
                            pattern={'[0-9]*'}
                            autocomplete="one-time-code"
                            onIonChange={(e) => setOtpConfirmation(e.detail.value?.trim() ?? '')}
                            // onIonInput={(e) => setOtpConfirmation(e.detail.value?.toString().trim() ?? '')} //TODO: use this after upgrading to Ionic 7
                          />
                        </form>
                      </IonItem>
                    </IonCol>
                  </IonRow>
                  {otpValidationFailed ? (
                    <IonRow>
                      <IonCol size="12">
                        <IonText color="danger">
                          <h6>{t('error.signin.incorrectOTP')}</h6>
                        </IonText>
                      </IonCol>
                    </IonRow>
                  ) : null}
                  <IonRow class="ion-margin-vertical">
                    <IonCol size="12">
                      <IonButton
                        color="primary"
                        // className="custom-button-radius"
                        disabled={!otpConfirmation}
                        expand="block"
                        onClick={verifyOtp}
                      >
                        {t('submit')}
                      </IonButton>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol size="12" class="ion-text-center">
                      <a href="#" onClick={(e) => requestNewOTP(e)}>
                        {t('newCodeRequest')}
                      </a>
                    </IonCol>
                  </IonRow>
                </>
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default SignIn;
