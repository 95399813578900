import { logFriendlyObject } from '@otuvy/common-utils';

const MIN_CONSOLE_LOG_LIFETIME = 5 * 60 * 1000; // 5 minutes
const MAX_CONSOLE_LOG_LIFETIME = 10 * 60 * 1000; // 10 minutes

declare global {
  interface Console {
    everything: any;
  }
}

// See https://stackoverflow.com/a/67449524/5423329 for the JavaScript solution
// See https://stackoverflow.com/a/44764462/5423329 for making it work with TypeScript
export default function captureConsole() {
  if (console.everything === undefined) {
    console.everything = [];
    const TS = () => {
      return new Date().toISOString();
    };
    window.onerror = (error, url, line) => {
      console.everything.push({
        type: 'exception',
        timeStamp: TS(),
        value: { error, url, line },
      });
      return false;
    };
    window.onunhandledrejection = (e) => {
      console.everything.push({
        type: 'promiseRejection',
        timeStamp: TS(),
        value: e.reason,
      });
    };

    const hookLogType = (logType: keyof Console) => {
      const original = console[logType].bind(console);
      return function () {
        console.everything.push({
          type: logType,
          timeStamp: TS(),
          value: Array.from(arguments),
        });
        original.apply(console, arguments);
      };
    };

    (['log', 'error', 'warn', 'debug'] as (keyof Console)[]).forEach((logType) => {
      console[logType] = hookLogType(logType);
    });

    const clearOldLogs = () => {
      const now = Date.now();
      console.everything = console.everything.filter(
        (log: any) => now - Date.parse(log.timeStamp) < MIN_CONSOLE_LOG_LIFETIME
      );
    };
    setInterval(clearOldLogs, MAX_CONSOLE_LOG_LIFETIME);
  }
}

export const getConsoleLogs = () => {
  return logFriendlyObject(console.everything);
};
