import { IonText } from '@ionic/react';

interface CustomGridHeaderProps {
  flexGridWidth: number;
}

const CustomGridHeader: React.FC<CustomGridHeaderProps> = ({ flexGridWidth }) => {
  return (
    <div
      style={{
        width: flexGridWidth ? `${flexGridWidth}px` : '100%',
        display: 'grid',
        gridTemplateColumns: '180px 180px 250px 1fr 90px 125px 200px',
      }}
    >
      <div></div>
      <div></div>
      <div></div>
      <div
        className="wj-cell wj-header people-list-grid_column-header"
        style={{
          position: 'relative',
          borderBottom: '0px',
          borderLeft: '1px solid rgba(0, 0, 0, .2)',
          borderTop: '1px solid rgba(0, 0, 0, .2)',
          borderRight: '1px solid rgba(0, 0, 0, .2)',
        }}
      >
        <IonText>Admin Permissions </IonText>
        <IonText className="font-weight-500">- Checking any box will make the user an Otuvy Frontline Admin.</IonText>
      </div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default CustomGridHeader;
