import { useTranslation } from 'react-i18next';
import { NotificationMessage } from './NotificationModal';

export enum GenericNotificationKey {
  ERROR_FIX_THEM = 'ERROR_FIX_THEM',
  ERROR_TRY_AGAIN = 'ERROR_TRY_AGAIN',
  ERROR_CONTACT_SUPPORT = 'ERROR_CONTACT_SUPPORT',
}

export const useGenericNotifications = () => {
  const { t } = useTranslation();

  const getGenericNotification = (key: GenericNotificationKey): NotificationMessage => {
    switch (key) {
      case GenericNotificationKey.ERROR_FIX_THEM:
        return {
          title: t('errorAlert.generic.header.errors'),
          message: (
            <>
              <p>{t('errorAlert.import.table.fixThem')}</p>
              <p
                dangerouslySetInnerHTML={{
                  __html: t('errorAlert.generic.happensAgainContactSupport'),
                }}
              />
            </>
          ),
        };
      case GenericNotificationKey.ERROR_TRY_AGAIN:
        return {
          title: t('errorAlert.generic.header.error'),
          message: (
            <>
              <p>{t('errorAlert.generic.tryAgain')}</p>
              <p
                dangerouslySetInnerHTML={{
                  __html: t('errorAlert.generic.happensAgainContactSupport'),
                }}
              />
            </>
          ),
        };
      case GenericNotificationKey.ERROR_CONTACT_SUPPORT:
        return {
          title: t('errorAlert.generic.header.error'),
          message: (
            <div
              dangerouslySetInnerHTML={{
                __html: t('errorAlert.generic.contactSupport'),
              }}
            />
          ),
        };
      default:
        throw new Error(`Unknown GenericNotificationKey: ${key}`);
    }
  };

  return {
    getGenericNotification,
  };
};
