import { IonPage, IonRouterOutlet } from '@ionic/react';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';
import IntegrationsConnectPage from './IntegrationsConnectPage';
import IntegrationsSettingsPage from './IntegrationsSettingsPage';
import IntegrationsLocationsPage from './IntegrationsLocationsPage';
import { integrationState } from '../../../features/integrations/qm/useFetchIntegration';
import { useRecoilValue } from 'recoil';
import LoadingMessage from '../../../components/overlays/LoadingMessage';
import { EnvironmentConfig, getFlag } from '../../../utils/environmentUtils';
import IntegrationsDisconnectPage from './IntegrationsDisconnectPage';

const IntegrationsPage: React.FC<RouteComponentProps> = ({ match }) => {
  const integrationInfo = useRecoilValue(integrationState);
  const { isConnected, defaultListOwner, generateLists, newLocationsGenerateLists } = integrationInfo ?? {};

  const getRoute = (subpath?: 'settings' | 'locations' | 'connected'): JSX.Element => {
    if (integrationInfo === undefined) {
      return <LoadingMessage />;
    }
    if (!isConnected) {
      if (!subpath) {
        return <IntegrationsConnectPage />;
      } else {
        return <Redirect to={match.url} />;
      }
    } else {
      if (getFlag(EnvironmentConfig.SHOW_INTEGRATION_SUB_PAGES) && subpath === 'settings') {
        return (
          <IntegrationsSettingsPage
            defaultListOwnerFromServer={defaultListOwner}
            generateListsFromServer={generateLists}
            newLocationsGenerateListsFromServer={newLocationsGenerateLists}
          />
        );
      } else if (getFlag(EnvironmentConfig.SHOW_INTEGRATION_SUB_PAGES) && subpath === 'locations') {
        return <IntegrationsLocationsPage />;
      } else if (subpath === 'connected') {
        return <IntegrationsDisconnectPage defaultListOwnerFromServer={defaultListOwner} />;
      } else {
        return <Redirect to={`${match.url}/connected`} />;
      }
    }
  };

  return (
    <IonPage>
      <IonRouterOutlet>
        <Route path={`${match.url}`} exact>
          {getRoute()}
        </Route>
        <Route path={`${match.url}/connected`}>{getRoute('connected')}</Route>
        <Route path={`${match.url}/settings`}>{getRoute('settings')}</Route>
        <Route path={`${match.url}/locations`}>{getRoute('locations')}</Route>
      </IonRouterOutlet>
    </IonPage>
  );
};

export default IntegrationsPage;
