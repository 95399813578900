import { IonButton, IonModal } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { atom, useRecoilState } from 'recoil';

export const notificationModalState = atom<NotificationMessage | undefined>({
  key: 'notificationModalState',
  default: undefined,
});

export type NotificationMessage = {
  title?: string;
  message?: JSX.Element | string;
  dismissEvent?: () => void;
};

const NotificationModal: React.FC = () => {
  const { t } = useTranslation();

  const [notification, setNotification] = useRecoilState(notificationModalState);

  const defaultValues: NotificationMessage = {
    title: t('errorAlert.default.header'),
    message: t('errorAlert.default.message'),
    dismissEvent: () => {
      console.log('Default dismiss event');
    },
  };
  const { title, message, dismissEvent } = notification ?? defaultValues;
  const show: boolean = !!notification;

  const dismissHandler = () => {
    if (dismissEvent) {
      dismissEvent();
    }

    setNotification(undefined);
  };

  return (
    <IonModal
      role="alertdialog"
      canDismiss={!show}
      isOpen={show}
      style={{
        '--height': 'fit-content',
        '--width': 'auto',
        '--border-width': '0px',
        '--box-shadow': '0 4px 4px rgba(0, 0, 0, 0.25)',

        alignItems: 'center',
      }}
    >
      <div className="ion-padding">
        <div
          style={{
            fontFamily: 'Baloo Thambi 2',
            fontSize: '24px',
            fontWeight: '600',
            lineHeight: '32px',
            letterSpacing: '0em',
            textAlign: 'left',
            marginBottom: '0.75rem',
          }}
        >
          {title}
        </div>

        <div
          style={{
            fontFamily: 'Baloo Thambi 2',
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '20px',
            letterSpacing: '0.25px',
            textAlign: 'left',
            marginBottom: '0.75rem',
          }}
        >
          <>{message}</>
        </div>

        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <IonButton
            style={{
              '--border-radius': '5rem',
              textTransform: 'uppercase',
            }}
            onClick={dismissHandler}
          >
            {t('OK')}
          </IonButton>
        </div>
      </div>
    </IonModal>
  );
};

export default NotificationModal;
