import { graphql } from '../../utils/api/apiUtils';

export interface OrganizationSetting {
  key: OrganizationSettingKey;
  value: string | number | boolean | null;
}

export enum OrganizationSettingKey {
  qm_sp_id = 'qm_sp_id',
  qm_integration_api_key = 'qm_integration_api_key',
  qm_default_list_owner = 'qm_default_list_owner',
  auto_archive_threshold = 'auto_archive_threshold',
  auto_generate_deficiency_list = 'auto_generate_deficiency_list',
  auto_generate_deficiency_list_for_new_location = 'auto_generate_deficiency_list_for_new_location',
}

export enum OrganizationSettingGroup {
  general = 'general',
  qm_integration = 'qm_integration',
  list_archiving = 'list_archiving',
}

// Instructions: This returns everything as a string.  The caller should handle any type casting.
export const getAllSettings = async (): Promise<OrganizationSetting[]> => {
  const query = `
      query getOrgSettingsQuery {
        getOrganizationSettings {
          key
          value
        }
      }
    `;
  const settings = await graphql<OrganizationSetting[]>(query);
  console.log('got all settings', settings);
  return settings;
};

const getSetting = async (key: OrganizationSettingKey): Promise<OrganizationSetting> => {
  const query = `
      query getOrgSettingQuery($key: String!) {
        getOrganizationSetting(key: $key) {
          key
          value
        }
      }
    `;
  const variables = { key };
  const setting = await graphql<OrganizationSetting>(query, variables);
  console.log('got setting', setting);
  return setting;
};

export const updateSetting = async (input: OrganizationSetting) => {
  const key = input.key;
  const value = String(input.value);
  console.log(`Attempting to set organization setting ${key} to ${value}`);
  const query = `
    mutation updateOrgSettingMutation($key: String!, $value: String) {
      updateOrganizationSetting(key: $key, value: $value) {
        key
        value
      }
    }
  `;
  const variables = { key, value };
  await graphql(query, variables);
  console.log('setting updated', input);
};

// Instructions: Add a getter for any specific property you need to retrieve from the organization settings.
// It should handle any type casting.
export const getAutoArchiveThreshold = async (): Promise<OrganizationSetting> => {
  console.log('getting auto archive threshold');
  const setting = await getSetting(OrganizationSettingKey.auto_archive_threshold);

  console.log('got autoarchive setting', setting);
  if (!setting || !setting.value) {
    updateSetting({ key: OrganizationSettingKey.auto_archive_threshold, value: -1 });
    return { key: OrganizationSettingKey.auto_archive_threshold, value: -1 };
  } else {
    return { key: OrganizationSettingKey.auto_archive_threshold, value: Number(setting.value) };
  }
};
