import { IonButton } from '@ionic/react';
import DisconnectAccountModal from './DisconnectAccountModal';

const DisconnectAccountButton: React.FC = () => {
  return (
    <>
      <div>
        <IonButton
          id="disconnect-account-button"
          size="default"
          fill="clear"
          className="font-20"
          color="light"
          style={{ width: '271px', '--padding-start': '0px', '--justify-content': 'start' }}
        >
          <span style={{ marginRight: 'auto' }}>Disconnect Account</span>
        </IonButton>
      </div>
      <DisconnectAccountModal />
    </>
  );
};

export default DisconnectAccountButton;
