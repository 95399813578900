import { GovernorLevelKey, GOVERNOR_LEVEL_LIMIT } from '../../../../constants/constants';
import Banner, { BannerLevel } from '../../../../components/banner/Banner';
// import { useTranslation } from 'react-i18next';
import useGovernor from '../../hooks/useGovernor';

const StorageGovernorBanners: React.FC = () => {
  // const { t } = useTranslation();
  const govData = useGovernor(GovernorLevelKey.STORAGE);
  if (!govData) return <></>;

  const {
    WARNING_LEVEL_1_THRESHOLD_PERCENT,
    WARNING_LEVEL_2_THRESHOLD_PERCENT,
    level,
    isWriteAccessRestricted,
    lockdownDate,
    isLocked,
  } = govData;

  // FIXME: get actual design and wording (make sure it's translated)
  const warningLevel1Banner = (
    <Banner
      level={BannerLevel.INFO}
      message={`Your organization has used over ${WARNING_LEVEL_1_THRESHOLD_PERCENT}% of its available storage. Please consider upgrading to get more storage.`}
    />
  );

  // FIXME: get actual design and wording (make sure it's translated)
  const warningLevel2Banner = (
    <Banner
      level={BannerLevel.WARN}
      message={`Your organization has used over ${WARNING_LEVEL_2_THRESHOLD_PERCENT}% of its available storage. Please consider upgrading to get more storage.`}
    />
  );

  // FIXME: get actual design and wording (make sure it's translated)
  const limitExceededBanner = (
    <Banner
      level={BannerLevel.DANGER}
      message={`Your organization has used more than its available storage. On ${lockdownDate?.toLocaleDateString()}, your account will be locked. To avoid this fate, upgrade now to get more storage.`}
    />
  );

  // FIXME: get actual design and wording (make sure it's translated)
  const writeAccessRestrictedBanner = (
    <Banner
      level={BannerLevel.DANGER}
      message="Your organization has been over its available storage for more than 14 days. Your account is now locked. To unlock your account, upgrade now to get more storage."
    />
  );

  // FIXME: get actual design and wording (make sure it's translated)
  const organizationLockedBanner = (
    <Banner
      level={BannerLevel.DANGER}
      message="Your organization has been over its available storage for more than 14 days. Your account is now locked. To unlock your account, upgrade now to get more storage."
    />
  );

  const banner = (): JSX.Element => {
    if (level >= GOVERNOR_LEVEL_LIMIT) {
      if (isLocked) {
        return organizationLockedBanner;
      } else if (isWriteAccessRestricted) {
        return writeAccessRestrictedBanner;
      } else {
        return limitExceededBanner;
      }
    } else if (level === WARNING_LEVEL_2_THRESHOLD_PERCENT) {
      return warningLevel2Banner;
    } else if (level === WARNING_LEVEL_1_THRESHOLD_PERCENT) {
      return warningLevel1Banner;
    } else {
      return <></>;
    }
  };

  return <>{banner()}</>;
};

export default StorageGovernorBanners;
