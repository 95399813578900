import { useIonViewWillEnter } from '@ionic/react';
import { useState, useEffect } from 'react';
import { Person, getAllPeople } from '../../features/people/peopleApi';
import { useRecoilValue } from 'recoil';
import { integrationState } from '../../features/integrations/qm/useFetchIntegration';

const useGetPersonPickerSearchOwnerData = () => {
  const [people, setPeople] = useState<Person[] | null>(null);
  const integrationInfo = useRecoilValue(integrationState);
  const { defaultListOwner } = integrationInfo ?? {};

  const fetchUsers = async () => {
    try {
      const people = await getAllPeople();
      setPeople(people);
    } catch (error) {
      console.error('Failed to fetch users', error);
    }
  };

  useIonViewWillEnter(() => {
    fetchUsers();
  });

  useEffect(() => {
    fetchUsers();
  }, []);

  return { people, defaultListOwner };
};

export default useGetPersonPickerSearchOwnerData;
