import { isAfter, isEqual } from 'date-fns';
import { UserShiftData } from '../../../utils/dataTransformers';
import { checkForWorkLogOverlap } from './timeTrackingUtils';

export const gridValidation = (cell: any, property: string, parsin: boolean, data: UserShiftData[]) => {
  // describes whether user was trying to edit the value and entered a value that could not be parsed into the data type expected (parsing == true)
  if (parsin) {
    if (property === 'startDate' || property === 'endDate') {
      return 'Please enter a valid date in the format MM/DD/YYYY';
    }
    if (property === 'startTime' || property === 'endTime') {
      return 'Please enter a valid time in the format HH:MM AM/PM';
    }
  }

  const rowDataStartDate = cell.startDate;
  const rowDataEndDate = cell.endDate;
  const rowDataUserId = cell.userId;
  const rowDataWorLogId = cell.workLogId;

  const isOverlap = checkForWorkLogOverlap(rowDataStartDate, rowDataEndDate, data, rowDataUserId, rowDataWorLogId);

  if (
    isOverlap &&
    (property === 'startDate' || property === 'endDate' || property === 'startTime' || property === 'endTime')
  ) {
    return 'This is an overlapping record';
  }

  // describes whether the data has already been parsed and applied to the data item (parsing == false)
  //TODO: This validation might need to be refactored once we get the actual data
  // This is just for testing and to show how to use the getError method
  // TODO: If the date data is a string, we need to convert it to a Date object and use date-fns to compare dates
  if (property === 'startDate' && valiDate(cell.startDate, cell.endDate)) {
    if (cell.endDate === null) return;
    if (cell.startDate === null) return 'Start date is required';
    return 'Start date must be before end date or the same';
  }
  if (property === 'endDate' && cell.endDate !== '--:--' && valiDate(cell.startDate, cell.endDate)) {
    if (cell.endDate === null) return 'End date is required';
    return 'End date must be after start date or the same';
  }

  if (property === 'startTime' && valiTime(cell.startTime, cell.endTime)) {
    if (cell.endTime === null) return;
    if (cell.startTime === null) return 'Start is required';
    return 'Start time must be before end time';
  }
  if (property === 'endTime' && cell.endTime !== '--:--' && valiTime(cell.startTime, cell.endTime)) {
    if (cell.endTime === null) return 'End time is required';
    return 'End time must be after start time';
  }
};

const valiDate = (date1: string, date2: string) => {
  if (date1 === null || date2 === null) return true; // If either date is null, we show the error message;
  return isAfter(new Date(date1), new Date(date2)); // If date1 is after date2, we show the error message;
};

const valiTime = (time1: string, time2: string) => {
  if (time1 === null || time2 === null) return true; // If either time is null, we show the error message;
  return !isEqual(new Date(time1), new Date(time2)) && isAfter(new Date(time1), new Date(time2)); // If time1 is after time2, we show the error message;
};
