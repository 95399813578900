// TimeTrackingSummaryGrid.tsx
import React, { useEffect, useState } from 'react';
import '@grapecity/wijmo.styles/wijmo.css';
import { FlexGrid, FlexGridCellTemplate, FlexGridColumn } from '@grapecity/wijmo.react.grid';
import { CollectionView } from '@grapecity/wijmo';
import { SummaryData } from '../../../utils/dataTransformers';
import { useRecoilValue } from 'recoil';
import { dateRangeState } from '../recoil/atoms';
import { IonButton, IonIcon } from '@ionic/react';
import { format } from 'date-fns';
import { transformSummaryDataToCSVFormat } from '../utils/timeTrackingUtils';
import { utils } from 'xlsx';
import { downloadOutline } from 'ionicons/icons';

const TimeTrackingSummaryGrid: React.FC<{ data: SummaryData[] }> = ({ data }) => {
  const [collectionView, setCollectionView] = useState(new CollectionView([]));
  const dateRange = useRecoilValue(dateRangeState);

  useEffect(() => {
    setCollectionView(new CollectionView(data));
  }, [data]);

  const formatDateRangeToFileName = (dateRange: [Date | null, Date | null]) => {
    const startDate = format(dateRange[0]!, 'M-dd-yy');
    const endDate = format(dateRange[1]!, 'M-dd-yy');
    return `Time Report ${startDate} - ${endDate}`;
  };
  const downloadCSVFile = (reportData: SummaryData[], reportFilename: string) => {
    // Transform the data to the format required by the CSV
    const formatedData = transformSummaryDataToCSVFormat(reportData, dateRange);

    // Convert the data to a worksheet
    const worksheet = utils.json_to_sheet(formatedData);

    // Convert the worksheet to a CSV
    const csv = utils.sheet_to_csv(worksheet);

    // Create a blob from the CSV string
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

    //Create a link element to download the file
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', reportFilename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <div className="d-flex ion-justify-content-end ion-align-items-center ion-margin-bottom">
        <div>
          <IonButton onClick={() => downloadCSVFile(data, formatDateRangeToFileName(dateRange))}>
            Export <IonIcon style={{ marginLeft: '3px', paddingBottom: '2px' }} icon={downloadOutline} />
          </IonButton>
        </div>
      </div>
      <div className="container-fluid">
        <FlexGrid itemsSource={collectionView} headersVisibility={true}>
          <FlexGridColumn isReadOnly={true} header="Name" binding="fullName" width={'*'} />
          <FlexGridColumn header="Date Range" binding="dateRange" width={'*'}>
            <FlexGridCellTemplate
              autoSizeRows={false}
              cellType="Cell"
              template={() => {
                return (
                  <div className="ion-text-center">
                    {`${dateRange[0]?.toLocaleDateString('en-US')} - ${dateRange[1]?.toLocaleDateString('en-US')}`}
                  </div>
                );
              }}
            />
          </FlexGridColumn>
          <FlexGridColumn header="Total Working Hours" binding="totalWorkingHours" width={'*'} />
        </FlexGrid>
      </div>
    </>
  );
};

export default TimeTrackingSummaryGrid;
