import { CellType } from '@grapecity/wijmo.grid';
import { FlexGrid } from '@grapecity/wijmo.react.grid';
import { FlexGridSearch } from '@grapecity/wijmo.react.grid.search';
import { IonLabel, IonSearchbar } from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import { useEffect, useRef, useState } from 'react';
import useGetPersonPickerSearchOwnerData from '../../../components/personPicker/useGetPersonPickerOwnerData';
import useErrorMessage from '../../../error/useErrorMessage';
import { getQmServiceLocations, QmLocation } from '../../../features/integrations/qm/integrationApi';
import IntegrationsLocationsGrid from '../../../features/integrations/qm/locations/IntegrationsLocationsGrid';
import useGraphqlQuery from '../../../hooks/useGraphqlQuery';
import IntegrationsLocationsGridActionButton from './IntegrationsLocationsGridActionButton';
import IntegrationsTabsPage from './IntegrationsTabsPage';
import { FlexGrid as FlexGridClass } from '@grapecity/wijmo.grid';

const IntegrationsLocationsPage = () => {
  const gridRef = useRef<FlexGrid>(null);
  const searchRef = useRef<FlexGridSearch>(null);
  const [searchText, setSearchText] = useState('');
  const [checkedItems, setCheckedItems] = useState<QmLocation[]>([]);

  const { data, refreshData, isLoading, setData } = useGraphqlQuery(getQmServiceLocations);
  const { people, defaultListOwner } = useGetPersonPickerSearchOwnerData();
  const { handleError } = useErrorMessage(refreshData);

  useEffect(() => {
    if (gridRef.current && searchRef.current) searchRef.current.control.grid = gridRef.current.control;

    if (gridRef.current) {
      onSelectAllHeaderCheckboxClick(gridRef.current.control);
    }
  }, []);

  const onSelectAllHeaderCheckboxClick = (grid: FlexGridClass) => {
    const handleClick = (e: MouseEvent) => {
      let hit = grid.hitTest(e);
      if (hit._col === 0 && hit.row === 0 && hit.cellType === CellType.TopLeft) {
        console.log((hit.target as any).checked);
        const checked = (hit.target as any).checked;
        if (checked) {
          setCheckedItems(grid.collectionView.sourceCollection);
        }

        if (!checked) {
          setCheckedItems([]);
        }
      }
    };

    grid.hostElement.addEventListener('click', handleClick);

    // Cleanup event listener on component unmount
    return () => {
      grid.hostElement.removeEventListener('click', handleClick);
    };
  };

  return (
    <IntegrationsTabsPage tab="locations">
      <div className="ion-padding" style={{ maxWidth: '1400px' }}>
        {/* Header */}
        <div
          className="d-flex ion-align-items-center ion-padding-bottom"
          style={{
            position: 'sticky',
            top: '-16px',
            zIndex: '10000',
            background: 'var(--ion-background-color)',
            gap: '1rem',
          }}
        >
          <div className="d-flex ion-align-items-center">
            <h1 className="ion-no-margin font-24 font-weight-400">Locations & List Owners</h1>
          </div>
          {/* Searchbar */}
          <div className="ion-margin-start" style={{ flex: '1' }}>
            <IonSearchbar
              mode="ios"
              cancelButtonIcon={closeOutline}
              placeholder="Search for locations"
              value={searchText}
              onIonChange={(e) => setSearchText(e.target.value as string)}
            />
            {/* We keep the FlexGridSearch component hidden, 
                but it is needed to handle all the search for us, 
                but we were force to use the IonSearchbar component 
                to expose the searchText value 

              I need to explain this better, but that is the basic idea 
             */}
            <div style={{ visibility: 'hidden', display: 'none' }}>
              <FlexGridSearch ref={searchRef} placeholder="Search" grid={gridRef} text={searchText} />
            </div>
          </div>

          <div>
            <IntegrationsLocationsGridActionButton
              people={people ?? []}
              defaultListOwner={defaultListOwner}
              selectedLocations={checkedItems}
              setSelectedLocations={setCheckedItems}
              setData={setData}
            />
          </div>
          <div>
            <IonLabel className="font-32 ">{checkedItems.length ?? '0'} Selected</IonLabel>
          </div>
        </div>

        {/* Content */}
        <div>
          {/* Locations Grid */}
          {/* Custom Grid Headers */}
          {/* <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 150px 300px',
              position: 'sticky',
              top: '56px',
              zIndex: '10000',
              borderTop: '1px solid rgba(0,0,0,.2)',
              borderLeft: '1px solid rgba(0,0,0,.2)',
              borderRight: '1px solid rgba(0,0,0,0)',
            }}
          >
            <div className="wj-cell wj-header" style={{ position: 'relative' }}>
              Clients
            </div>
            <div className="wj-cell wj-header" style={{ position: 'relative' }}>
              Locations
            </div>
            <div
              className="wj-cell wj-header d-flex ion-center-align-items-center ion-justify-content-center"
              style={{ position: 'relative' }}
            >
              <div style={{ marginRight: '0.25rem' }}>Auto-lists</div>
              <Tooltip
                tooltip={
                  'When this is turned on and the auto generation toggle on the settings page is on, auto deficiency lists will be created in Otuvy Frontline for this location.'
                }
              />
            </div>
            <div className="wj-cell wj-header" style={{ position: 'relative' }}>
              Default List Owner
            </div>
          </div> */}

          {/* Wijmo Grid */}
          <IntegrationsLocationsGrid
            ref={gridRef}
            data={data}
            isLoading={isLoading}
            refreshData={refreshData}
            handleError={handleError}
            setData={setData}
            searchText={searchText}
            checkedItems={checkedItems}
            setCheckedItems={setCheckedItems}
            people={people}
            defaultListOwner={defaultListOwner}
          />
        </div>
      </div>
    </IntegrationsTabsPage>
  );
};

export default IntegrationsLocationsPage;
