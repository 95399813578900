import React, { useEffect, useState } from 'react';
import '@grapecity/wijmo.styles/wijmo.css';
import { FlexGrid, FlexGridCellTemplate, FlexGridColumn } from '@grapecity/wijmo.react.grid';
import { CollectionView } from '@grapecity/wijmo';
import { DetailedShiftData } from '../../../utils/dataTransformers';
import * as wjcGrid from '@grapecity/wijmo.grid';
import { IonButton, IonContent, IonIcon, IonPopover, IonText } from '@ionic/react';
import { writeAccessRestrictedState } from '../../../App';
import useEditWorkLogRecordDialog from '../hooks/useEditWorkLogRecordDialog';
import { useRecoilValue } from 'recoil';
import useAddWorkLogRecordDialog from '../hooks/useAddWorkLogRecordDialog';
import format from 'date-fns/format';
import { gridValidation } from '../utils/wijmoGridValidation';
import { utils } from 'xlsx';
import { dateRangeState, userShiftDataState } from '../recoil/atoms';
import { downloadOutline } from 'ionicons/icons';
import { transformDetailedDataToCSVFormat } from '../utils/timeTrackingUtils';
import { isAfter } from 'date-fns';

const TimeTrackingDetailsGrid: React.FC<{ data: DetailedShiftData[] }> = ({ data }) => {
  const [collectionView, setCollectionView] = useState(new CollectionView([]));
  const isWriteAccessRestricted = useRecoilValue(writeAccessRestrictedState);
  const userShiftData = useRecoilValue(userShiftDataState);

  const { presentModal: presentEditModal } = useEditWorkLogRecordDialog();
  const { presentModal: presentAddModal } = useAddWorkLogRecordDialog();

  const dateRange = useRecoilValue(dateRangeState);

  useEffect(() => {
    const newCollectionView = buildCollectionView(data);
    setCollectionView(newCollectionView);
  }, [data]);

  const buildCollectionView = (newData: DetailedShiftData[]) => {
    const newCollectionView = new CollectionView(newData, {
      // https://demo.grapecity.com.cn/wijmo/api/classes/wijmo.collectionview.html#geterror
      getError: (cell: any, property: string, parsing: boolean) => {
        return gridValidation(cell, property, parsing, userShiftData);
      },
    });

    return newCollectionView;
  };

  const onloadedRows = (grid: wjcGrid.FlexGrid) => {
    // Add a row to the grid that shows the total working hours per person
    // This function is called when the grid rows are loaded, following the pattern used in the QM Integration Locations grid
    // This algorithm works if the data is sorted by person already. If not, we will need to sort it first.
    const addTotalTimePerPersonRow = (flex: wjcGrid.FlexGrid) => {
      const gridRows = flex.rows;
      const rowData = gridRows.map((row) => row.dataItem);

      // Group the data by person [fullName, startIndex, endIndex, data] FIXME: This is not a good way to group data
      // We should group the data by person using a Map or a Set or an object
      // I will leave this as is for now, but we should refactor this for better type safety and readability
      // The current structure is Arr[fullName, startIndex, endIndex, data] where data is an array of DetailedShiftData
      const groupedData = rowData.reduce((acc, curr, index) => {
        if (index === 0) {
          acc.push([curr.fullName, index, index, [curr]]);
          return acc;
        }

        const lastGroup = acc[acc.length - 1];
        if (lastGroup[0] === curr.fullName) {
          lastGroup[2] = index;
          lastGroup[3].push(curr);
        } else {
          acc.push([curr.fullName, index, index, [curr]]);
        }

        return acc;
      }, []);

      groupedData.forEach((group: any, index: any) => {
        const totalWorkingHours = group[3].reduce((acc: any, curr: any) => acc + curr.totalWorkingHours, 0);
        const newRow = new wjcGrid.Row();

        newRow.isReadOnly = true;
        newRow.dataItem = {
          totalWorkingHours: totalWorkingHours,
        };

        flex.rows.splice(group[2] + index + 1, 0, newRow);
      });
    };

    addTotalTimePerPersonRow(grid);
  };

  const formatDateRangeToFileName = (dateRange: [Date | null, Date | null]) => {
    const startDate = format(dateRange[0]!, 'M-dd-yy');
    const endDate = format(dateRange[1]!, 'M-dd-yy');
    return `Time Report ${startDate} - ${endDate}`;
  };

  const downloadCSVFile = (reportData: DetailedShiftData[], reportFilename: string) => {
    // Transform the data to the format required by the CSV
    const formatedData = transformDetailedDataToCSVFormat(reportData);

    // Convert the data to a worksheet
    const worksheet = utils.json_to_sheet(formatedData);

    // Convert the worksheet to a CSV
    const csv = utils.sheet_to_csv(worksheet);

    // Create a blob from the CSV string
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

    //Create a link element to download the file
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', reportFilename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <div className="d-flex ion-justify-content-end ion-align-items-center ion-margin-bottom">
        <div>
          <IonButton disabled={isWriteAccessRestricted} onClick={() => presentAddModal()}>
            Add a Record
          </IonButton>
        </div>
        <div>
          <IonButton
            disabled={isWriteAccessRestricted}
            onClick={() => downloadCSVFile(data, formatDateRangeToFileName(dateRange))}
          >
            Export <IonIcon style={{ marginLeft: '3px', paddingBottom: '2px' }} icon={downloadOutline} />
          </IonButton>
        </div>
      </div>
      <div className="container-fluid">
        <FlexGrid headersVisibility={true} itemsSource={collectionView} loadedRows={onloadedRows} selectionMode={0}>
          <FlexGridColumn header="Name" binding="fullName" width="*" minWidth={200} isReadOnly={true} />
          <FlexGridColumn binding="startDate" header="Start Date" isReadOnly={true} />
          <FlexGridColumn binding="startTime" header="Start Time" width={130} isReadOnly={true}>
            <FlexGridCellTemplate
              autoSizeRows={false}
              cellType="Cell"
              template={(context: any) => {
                if (!context.item.startTime) return <div></div>;
                return <div className="ion-text-center">{format(context.item.startTime, 'h:mm a')}</div>;
              }}
            />
          </FlexGridColumn>
          <FlexGridColumn binding="endDate" header="End Date" isReadOnly={true}>
            <FlexGridCellTemplate
              autoSizeRows={false}
              cellType="Cell"
              template={(context: any) => {
                if (!context.item.userId) return <div></div>;
                if (!context.item.endDate) return <div onClick={() => presentEditModal(context.item)}>--:--</div>;
                return <div className="ion-text-center">{format(context.item.endDate, 'M/d/yyyy')}</div>;
              }}
            />
          </FlexGridColumn>
          <FlexGridColumn binding="endTime" header="End Time" width={130} isReadOnly={true}>
            <FlexGridCellTemplate
              autoSizeRows={false}
              cellType="Cell"
              template={(context: any) => {
                if (!context.item.userId) return <div></div>;
                if (!context.item.endTime) return <div onClick={() => presentEditModal(context.item)}>--:--</div>;
                return <div className="ion-text-center">{format(context.item.endTime, 'h:mm a')}</div>;
              }}
            />
          </FlexGridColumn>
          <FlexGridColumn header="Start Address" binding="startAddress" width={200}>
            <FlexGridCellTemplate
              cellType="Cell"
              template={(context: any) => {
                return (
                  <>
                    <div
                      className="timetracking-grid-visible-overflow"
                      id={`startAddressPopover_${context.item.workLogId}`}
                    >
                      <IonText>{context.item.startAddress}</IonText>
                    </div>
                    <IonPopover
                      mode="ios"
                      triggerAction="click"
                      trigger={`startAddressPopover_${context.item.workLogId}`}
                      showBackdrop={false}
                    >
                      <IonContent className="ion-padding">{context.item.startAddress}</IonContent>
                    </IonPopover>
                  </>
                );
              }}
            />
          </FlexGridColumn>
          <FlexGridColumn header="End Address" binding="endAddress" width={200}>
            <FlexGridCellTemplate
              cellType="Cell"
              template={(context: any) => {
                return (
                  <>
                    <div
                      className="timetracking-grid-visible-overflow"
                      id={`endAddressPopover_${context.item.workLogId}`}
                    >
                      <IonText>{context.item.endAddress}</IonText>
                    </div>
                    <IonPopover
                      mode="ios"
                      triggerAction="click"
                      trigger={`endAddressPopover_${context.item.workLogId}`}
                      showBackdrop={false}
                    >
                      <IonContent className="ion-padding">{context.item.endAddress}</IonContent>
                    </IonPopover>
                  </>
                );
              }}
            />
          </FlexGridColumn>
          <FlexGridColumn
            header="Actions"
            cssClassAll="center-grid-cell"
            binding="actionButton"
            isReadOnly={true}
            width={125}
          >
            <FlexGridCellTemplate
              autoSizeRows={false}
              cellType="Cell"
              template={(context: any) => {
                if (!context.item.userId) return <div></div>;
                return (
                  <div className="ion-text-center">
                    <IonButton
                      color={
                        context.item.endDate && !isAfter(context.item.startDate, context.item.endDate)
                          ? 'primary'
                          : 'danger'
                      }
                      disabled={isWriteAccessRestricted}
                      onClick={() => presentEditModal(context.item)}
                    >
                      Edit
                    </IonButton>
                  </div>
                );
              }}
            />
          </FlexGridColumn>
          <FlexGridColumn header="Total Working Hours" binding="totalWorkingHours" width={225} isReadOnly={true} />
        </FlexGrid>
      </div>
    </>
  );
};

export default TimeTrackingDetailsGrid;
