import { IonItem, IonLabel } from '@ionic/react';
import { PropsWithChildren } from 'react';
import Tooltip from './overlays/Tooltip';

interface BasicSettingProps {
  label: string;
  tooltip?: string | JSX.Element;
  marginTop?: boolean;
}

const BasicSetting: React.FC<PropsWithChildren<BasicSettingProps>> = ({ children, label, tooltip, marginTop }) => {
  return (
    <IonItem lines="none" className={`ion-no-padding ${marginTop ? 'ion-margin-top' : ''}`}>
      <div className="d-flex ion-align-items-center">
        {children}
        <IonLabel>{label}</IonLabel>
        {tooltip && (
          <div className="d-flex ion-align-items-center" style={{ paddingLeft: '0.5rem' }}>
            <Tooltip tooltip={tooltip} />
          </div>
        )}
      </div>
    </IonItem>
  );
};

export default BasicSetting;
