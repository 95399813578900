import { putPeople, PutPeopleResult, PutPersonInput, FailedUser } from '../../peopleApi';
import { PersonRow } from './PeopleImportGrid';
import { mapLanguage } from '../../peopleGridUtils';
import {
  ImportGeneralErrorKey,
  UserImportGeneralError,
  UserImportGeneralErrorKey,
  UserImportSpecificErrorKey,
} from '../../../../constants/constants';
import { useIonLoading } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import { notificationModalState } from '../../../../error/NotificationModal';
import { useHistory } from 'react-router';
import { GenericNotificationKey, useGenericNotifications } from '../../../../error/useGenericNotifications';

interface CompletePersonRow {
  rowId?: string;
  firstName: string;
  lastName: string;
  email: string;
  language?: string;
}

type ImportResults = PutPeopleResult | Pick<PutPeopleResult, 'generalErrors'>;

const useImportPeople = (gridState: PersonRow[], resetGrid: () => void) => {
  const setImportMessage = useSetRecoilState(notificationModalState);

  const [presentLoading, dismissLoading] = useIonLoading();
  const { t } = useTranslation();
  const navigation = useHistory();
  const { getGenericNotification } = useGenericNotifications();

  const importPeople = async () => {
    console.log('Import Begin');

    await presentLoading({
      message: t('loading.message.user.import'),
      spinner: 'crescent',
      showBackdrop: true,
    });

    let numberOfRecordsWithErrors: number = 0;

    const people: PutPersonInput[] = (gridState as CompletePersonRow[]).map((p) => {
      if (!p.email || !p.firstName || !p.firstName) {
        numberOfRecordsWithErrors++;
      }
      const { rowId, ...rest } = p;
      return {
        ...rest,
        language: mapLanguage(rest.language),
      };
    });

    if (numberOfRecordsWithErrors > 0) {
      importFinished({
        generalErrors: [
          {
            errorKey: ImportGeneralErrorKey.INVALID_DATA,
            errorDetail: {
              numberOfRecordsWithErrors,
            },
          },
        ],
      });
      return;
    }

    let duplicateEmails: string[] = [];
    let seenEmails: string[] = [];

    //Check for duplicate emails in just the dataset we are uploading

    people.forEach((p) => {
      if (p.email) {
        if (seenEmails.includes(p.email)) {
          if (!duplicateEmails.includes(p.email)) duplicateEmails.push(p.email);
        } else {
          seenEmails.push(p.email);
        }
      }
    });

    if (duplicateEmails.length > 0) {
      importFinished({
        generalErrors: [
          {
            errorKey: UserImportGeneralErrorKey.DUPLICATE_EMAIL,
            errorDetail: { duplicateEmails },
          },
        ],
      });
      return;
    }

    putPeople(people)
      .then((result) => {
        importFinished(result);
      })
      .catch((error) => {
        console.error('Error submitting create user requests (putPeople)', error);
        importFinished({
          generalErrors: [
            {
              errorKey: ImportGeneralErrorKey.UNKNOWN,
              errorDetail: { error },
            },
          ],
        });
      });
  };

  const importFinished = (results: ImportResults) => {
    console.log('Import Finished', results);

    dismissLoading();

    if (!results) {
      console.error('Importing people had no results!');
      handleGeneralErrors([
        {
          errorKey: ImportGeneralErrorKey.UNKNOWN,
          errorDetail: {
            error: 'No results returned',
          },
        },
      ]);
      return;
    }

    if (results.generalErrors && results.generalErrors.length > 0) {
      handleGeneralErrors(results.generalErrors);
      return;
    }

    if ('failedUsers' in results && results.failedUsers.length > 0) {
      handleFailedUsers(results.failedUsers);
      return;
    }

    const areResultsEmpty =
      (!('createdUsers' in results) || results.createdUsers.length === 0) &&
      (!('editedUsers' in results) || results.editedUsers.length === 0) &&
      (!('reactivatedUsers' in results) || results.reactivatedUsers.length === 0);
    if (areResultsEmpty) {
      console.error('Importing people had empty results!');
      handleGeneralErrors([
        {
          errorKey: ImportGeneralErrorKey.UNKNOWN,
          errorDetail: {
            error: 'Empty results returned',
          },
        },
      ]);
      return;
    }

    handleSuccess(results);
  };

  const handleGeneralErrors = (generalErrors: UserImportGeneralError[]) => {
    console.log('Processing General errors', generalErrors);

    if (generalErrors.length === 0) {
      console.warn('generalErrors was empty');
      setImportMessage(getGenericNotification(GenericNotificationKey.ERROR_TRY_AGAIN));
      return;
    }

    const error = generalErrors[0]; //TODO: process all errors to get a count and then do a single summary message afterward?
    console.log('Processing error in order to give more details', error.errorKey);
    switch (error.errorKey) {
      case UserImportGeneralErrorKey.DUPLICATE_EMAIL:
        console.warn('Duplicate emails found', error.errorDetail);
        const duplicateEmails: string[] = (error.errorDetail as any).duplicateEmails;
        setImportMessage({
          title: t('errorAlert.import.table.duplicates.email.header'),
          message: (
            <>
              {t('errorAlert.import.table.duplicates.email.message1')}
              <ul>
                {duplicateEmails.map((email) => {
                  return <li key={email}>{email}</li>;
                })}
              </ul>
              {t('errorAlert.import.table.duplicates.email.message2')}
            </>
          ),
        });
        break;
      case ImportGeneralErrorKey.INVALID_DATA:
        setImportMessage(getGenericNotification(GenericNotificationKey.ERROR_FIX_THEM));
        break;
      case ImportGeneralErrorKey.TIMEOUT:
      case ImportGeneralErrorKey.BATCH_TOO_LARGE:
        setImportMessage(getGenericNotification(GenericNotificationKey.ERROR_TRY_AGAIN));
        break;
      case ImportGeneralErrorKey.NO_ORGANIZATION:
      case ImportGeneralErrorKey.NO_ORG_SUPERADMIN:
        setImportMessage(getGenericNotification(GenericNotificationKey.ERROR_CONTACT_SUPPORT));
        break;
      default:
        console.warn('Recieved an error key we did not know how to handle', error.errorKey);
        setImportMessage(getGenericNotification(GenericNotificationKey.ERROR_TRY_AGAIN));
    }
  };

  const handleFailedUsers = (failedUsers: FailedUser[]) => {
    console.log('Processing failed users', failedUsers);

    failedUsers.forEach((u: any) => {
      //TODO: highlight errors on the grid
    });

    const startingAccumulator = {
      editingSuperAdmin: 0,
      emailInOtherOrg: 0,
      invalidData: 0,
    };
    const failedUserErrors = failedUsers.reduce((prev, curr) => {
      switch (curr.errorKey) {
        case UserImportSpecificErrorKey.EDITING_SUPER_ADMIN:
          return { ...prev, editingSuperAdmin: prev.editingSuperAdmin + 1 };
        case UserImportSpecificErrorKey.EMAIL_IN_OTHER_ORG:
          return { ...prev, emailInOtherOrg: prev.emailInOtherOrg + 1 };
        case UserImportSpecificErrorKey.MISSING_EMAIL:
        case UserImportSpecificErrorKey.UNSUPPORTED_LANGUAGE:
          return { ...prev, invalidData: prev.invalidData + 1 };
        default:
          console.warn('Recieved an error key we did not know how to handle', curr.errorKey);
          return prev;
      }
    }, startingAccumulator);

    // Since we only show one error message, handle these in order of priority
    if (failedUserErrors.editingSuperAdmin > 0) {
      setImportMessage(getGenericNotification(GenericNotificationKey.ERROR_CONTACT_SUPPORT));
    } else if (failedUserErrors.emailInOtherOrg > 0) {
      const emailsInOtherOrgs: string[] = failedUsers
        .filter((u) => u.errorKey === UserImportSpecificErrorKey.EMAIL_IN_OTHER_ORG)
        .map((u) => u.email);
      setImportMessage({
        title: t('errorAlert.import.table.inUse.email.header'),
        message: (
          <>
            {t('errorAlert.import.table.inUse.email.message1')}
            <ul>
              {emailsInOtherOrgs.map((email) => (
                <li key={email}>{email}</li>
              ))}
            </ul>
            {t('errorAlert.import.table.inUse.email.message2')}
          </>
        ),
      });
    } else if (failedUserErrors.invalidData > 0) {
      setImportMessage(getGenericNotification(GenericNotificationKey.ERROR_FIX_THEM));
    } else {
      setImportMessage(getGenericNotification(GenericNotificationKey.ERROR_TRY_AGAIN));
    }
  };

  const handleSuccess = (results: PutPeopleResult) => {
    resetGrid();

    setImportMessage({
      title: t('feedback.success.withFeeling'),
      message: (
        <>
          {results.createdUsers.length > 0 ? (
            <>
              <div
                dangerouslySetInnerHTML={{
                  __html: t('feedback.person.imported.created.message', { count: results.createdUsers.length }),
                }}
              />
              <br />
            </>
          ) : (
            ''
          )}

          {results.editedUsers.length > 0 ? (
            <div
              dangerouslySetInnerHTML={{
                __html: t('feedback.person.imported.edited.message', { count: results.editedUsers.length }),
              }}
            />
          ) : (
            ''
          )}

          {results.reactivatedUsers.length > 0 ? (
            <div
              dangerouslySetInnerHTML={{
                __html: t('feedback.person.imported.reactivated.message', {
                  count: results.reactivatedUsers.length,
                }),
              }}
            />
          ) : (
            ''
          )}
        </>
      ),
      dismissEvent: () => {
        navigation.push('/people/list', 'replace');
      },
    });
  };

  return {
    importPeople,
  };
};
export default useImportPeople;
