// Main component (TimeTrackingReports.tsx or similar)
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  viewState,
  userShiftDataState,
  selectedEmployeesState,
  showReportState,
  dateRangeState,
} from '../recoil/atoms';
import {
  generateDetailsViewData,
  generateSummaryViewData,
  DetailedShiftData,
  SummaryData,
} from '../../../utils/dataTransformers';
import TimeTrackingDetailsGrid from './TimeTrackingDetailsGrid';
import TimeTrackingSummaryGrid from './TimeTrackingSummaryGrid';
import { Person } from '../../people/peopleApi';
import { getWorkLogsForCurrentUser } from '../api/TimeTrackingAPI';

interface TimeTrackingReportsProps {
  people: Person[];
}

const TimeTrackingReports: React.FC<TimeTrackingReportsProps> = ({ people }) => {
  const view = useRecoilValue(viewState);
  const [userShiftData, setUserShiftData] = useRecoilState(userShiftDataState);
  const selectedEmployees = useRecoilValue(selectedEmployeesState);
  const dateRange = useRecoilValue(dateRangeState);
  const [showReport, setShowReport] = useRecoilState(showReportState);
  const [reportData, setReportData] = useState<DetailedShiftData[] | SummaryData[]>([]);

  useEffect(() => {
    const fetchReportData = async () => {
      const workLogsData = await getWorkLogsForCurrentUser(dateRange, selectedEmployees);
      setUserShiftData(workLogsData);
      setShowReport(true);
    };

    if (selectedEmployees.length > 0) {
      fetchReportData();
    }

    if (selectedEmployees.length === 0) {
      setShowReport(false);
    }

    return () => {
      setShowReport(false);
    };
  }, [dateRange, selectedEmployees]);

  useEffect(() => {
    if (!showReport) return;
    let data;
    if (view === 'details') {
      data = generateDetailsViewData(userShiftData, people);
    } else {
      data = generateSummaryViewData(userShiftData, people);
    }
    setReportData(data);
  }, [showReport, userShiftData, selectedEmployees, view]);

  if (!showReport) return <div></div>;

  return (
    <div className="employee-grid-container ion-margin-top">
      {view === 'details' ? (
        <TimeTrackingDetailsGrid data={reportData as DetailedShiftData[]} />
      ) : (
        <TimeTrackingSummaryGrid data={reportData as SummaryData[]} />
      )}
    </div>
  );
};

export default TimeTrackingReports;
