import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonItem, IonLabel } from '@ionic/react';

import './PeopleImportInstructionPanel.css';

const PeopleImportInstructionPanel: React.FC = (props: any) => {
  return (
    <IonCard className="add-people-instructions">
      <IonCardHeader color="primary">
        <IonCardTitle>How to input your people</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <IonItem lines="none">
          <IonLabel class="font-20 no-margin-bottom">Using Excel...</IonLabel>
        </IonItem>

        <ol className="ion-no-margin">
          <li> Gather your list of people into an Excel spreadsheet.</li>
          <ul>
            <li>Order the Excel's columns like the table aboveL First name, Last name, etc...</li>
            <li>Enter their names and emails into the Excel.</li>
          </ul>

          <li>Select & copy the data from your Excel, then paste it into the table above.</li>
          <li>Fix any errors.</li>
          <li>Submit!</li>
        </ol>
        <IonItem lines="none">
          <IonLabel class="font-20 no-margin-bottom"> Or just type it in...</IonLabel>
        </IonItem>
        <ol className="ion-no-margin">
          <li>Type your people's names and emails into the table above.</li>
          <li>Fix any errors.</li>
          <li>Submit!</li>
        </ol>
      </IonCardContent>
    </IonCard>
  );
};

export default PeopleImportInstructionPanel;
