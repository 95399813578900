import { UserImportSpecificErrorKey, Language, Status, UserImportGeneralError } from '../../constants/constants';
import { graphql } from '../../utils/api/apiUtils';

const UPLOAD_USERS_LIMIT = 50;

export interface Person {
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  language: Language;
  groups: string[];
  status: Status;
  isRegistered: boolean;
}

export interface PutPersonInput {
  //Should we generate UserID client side?
  firstName: string;
  lastName: string;
  email: string;
  language?: Language;
}

export interface FailedUser {
  userId?: string;
  email: string;

  errorKey: UserImportSpecificErrorKey;
  errorMessage?: string;
  errorDetail: object;
}

export interface PutPeopleResult {
  createdUsers: string[];
  editedUsers: string[];
  reactivatedUsers: string[];
  failedUsers: FailedUser[];

  generalErrors: UserImportGeneralError[];
}

type PutPeopleResultSerialized = Omit<PutPeopleResult, 'failedUsers' | 'generalErrors'> & {
  failedUsers: FailedUserSerialized[];
  generalErrors: UserImportGeneralErrorSerialized[];
};

type FailedUserSerialized = Omit<FailedUser, 'errorDetail'> & {
  errorDetail: string;
};

type UserImportGeneralErrorSerialized = Omit<UserImportGeneralError, 'errorDetail'> & {
  errorDetail: string;
};

export const putPeople = async (people: PutPersonInput[]): Promise<PutPeopleResult> => {
  const promisedResponses: Promise<PutPeopleResult>[] = [];
  while (people.length > 0) {
    const peopleBatch = people.splice(0, UPLOAD_USERS_LIMIT);

    promisedResponses.push(putPeopleBatch(peopleBatch));
  }
  const putPeopleResults: PutPeopleResult[] = await Promise.all(promisedResponses);
  const putPeopleResult: PutPeopleResult = putPeopleResults.reduce(
    (previous, current) => {
      return {
        createdUsers: [...(previous.createdUsers ?? []), ...(current.createdUsers ?? [])],
        editedUsers: [...(previous.editedUsers ?? []), ...(current.editedUsers ?? [])],
        reactivatedUsers: [...(previous.reactivatedUsers ?? []), ...(current.reactivatedUsers ?? [])],
        failedUsers: [...(previous.failedUsers ?? []), ...(current.failedUsers ?? [])],

        generalErrors: [...(previous.generalErrors ?? []), ...(current.generalErrors ?? [])],
      };
    },
    {
      createdUsers: [],
      editedUsers: [],
      reactivatedUsers: [],
      failedUsers: [],
      generalErrors: [],
    }
  );
  return putPeopleResult;
};

const putPeopleBatch = async (peopleBatch: PutPersonInput[]): Promise<PutPeopleResult> => {
  const query = `
      mutation putPeopleMutation($people: [PutUserInput!]!) {
        putPeople(people: $people) {
          createdUsers
          editedUsers
          failedUsers {
            userId
            email

            errorKey
            errorDetail
          }

          generalErrors {
            errorKey
            errorDetail
          }
        }
      }
  `;
  const variables = { people: peopleBatch };
  try {
    const putPeopleResultSerialized = await graphql<PutPeopleResultSerialized>(query, variables);

    const putPeopleResult: PutPeopleResult = {
      ...putPeopleResultSerialized,
      failedUsers: putPeopleResultSerialized.failedUsers.map<FailedUser>((failedUser) => ({
        ...failedUser,
        errorDetail: JSON.parse(failedUser.errorDetail),
      })),
      generalErrors: putPeopleResultSerialized.generalErrors.map<UserImportGeneralError>((error) => ({
        ...error,
        errorDetail: JSON.parse(error.errorDetail),
      })),
    };

    return putPeopleResult;
  } catch (e) {
    console.warn('People API call threw exception', e);
  }

  console.warn('peopleAPI is returning a default value');
  return {
    createdUsers: [],
    editedUsers: [],
    reactivatedUsers: [],
    failedUsers: [],
    generalErrors: [],
  };
};

const getAllPeopleQuery = `
  query getAllPeopleQuery {
    getPeopleBase {
      userId
      firstName
      lastName
      email
      language
      groups
      status
      isRegistered
    }
  }
`;

export const getAllPeople = async (): Promise<Person[]> => {
  const people = await graphql<Person[]>(getAllPeopleQuery);
  people.sort((a, b) => {
    const firstNameComparison = a.firstName.localeCompare(b.firstName);
    if (firstNameComparison !== 0) {
      return firstNameComparison;
    }

    // If first names are equal, compare by last name
    return a.lastName.localeCompare(b.lastName);
  });

  return people;
};

export const getActivePeople = async (): Promise<Person[]> => {
  const people = await graphql<Person[]>(getAllPeopleQuery);
  console.log('got Active people', people);
  const activePeople = people.filter((p) => p.status === Status.ACTIVE);
  return activePeople;
};

export interface EditPersonInput {
  userId: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  language?: Language;
  status?: Status;
}

export const editPerson = async (person: EditPersonInput): Promise<Person> => {
  const query = `
      mutation editPersonMutation($person: EditUserInput!) {
        editPerson(person: $person) {
          userId
          firstName
          lastName
          email
          language
          status
        }
      }
  `;
  const variables = { person };
  return graphql<Person>(query, variables);
};

export const deactivatePerson = async (personId: string, newOwnerId?: string) => {
  const query = `
      mutation deactivatePersonMutation($personId: ID!, $newOwnerId: ID) {
        deactivatePerson(personId: $personId, newOwnerId: $newOwnerId)
      }
  `;
  const variables = {
    personId,
    newOwnerId,
  };
  await graphql(query, variables);
};

export const activatePerson = async (personId: string) => {
  const query = `
      mutation activatePersonMutation($personId: ID!) {
        activatePerson(personId: $personId)
      }
  `;
  const variables = { personId };
  await graphql(query, variables);
};

export const resendWelcomeEmails = async (userIds: string[]) => {
  const query = `
    mutation emailsMutation($userIds: [String!]!) {
      resendWelcomeEmails(userIds: $userIds)
    }
  `;
  const variables = { userIds };
  const sentUserIds = await graphql<string[]>(query, variables);
  console.log('welcome emails sent', sentUserIds);
};

export const submitUserDeletionRequest = async (): Promise<Boolean> => {
  const query = `
        query submitUserDeletionRequest {
          sendUserDeletionRequest
        }
    `;

  const success = await graphql<boolean>(query);
  return success;
};
