import { useIonToast } from '@ionic/react';
import { close } from 'ionicons/icons'

const useToast = () => {
  const [toast] = useIonToast();

  const present = (message: string, duration: number, position: 'top' | 'middle' | 'bottom', color: string) => {
    toast({
      message: message,
      duration: duration,
      position: position,
      color: color,
      buttons: [
        {
          side: 'end',
          role: 'cancel',
          icon: close,
        }
      ],
    });
  };

  return [present];
};

export default useToast;
