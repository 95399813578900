import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonImg,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from '@ionic/react';
import logo from '../../assets/images/app-logo.png';
import React, { useEffect } from 'react';

const Unauthorized: React.FC = () => {
  const router = useIonRouter();

  useEffect(() => {
    const timer = setTimeout(() => {
      router.push('/login', 'root');
    }, 10000);

    return () => clearTimeout(timer);
  }, [router]);

  return (
    <IonPage>
      <IonContent>
        <IonGrid class="h-100 ion-padding-horizontal ion-align-items-center">
          <IonRow class="h-100 ion-justify-content-center ion-align-items-center">
            <IonCol size="12" sizeMd="10" sizeLg="6">
              <IonRow class="ion-justify-content-center">
                <IonCol size="12">
                  <IonImg src={logo} />
                </IonCol>
              </IonRow>
              <IonRow
                class="ion-justify-content-center ion-align-items-center"
                style={{ flexDirection: 'column', textAlign: 'center' }}
              >
                <IonCol size="12">
                  <IonText>You must be an administrator to access this site.</IonText>
                </IonCol>
                <IonCol size="12">
                  <IonButton routerLink="/login" style={{ marginTop: '20px' }}>
                    Return to Login
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Unauthorized;
