import { useTranslation } from 'react-i18next';
import UsageMetricsPanel from '../features/usage/components/UsageMetricsPanel/UsageMetricsPanel';
import { useEffect, useState } from 'react';
import { getMetrics, OrganizationMetrics } from '../features/usage/usageApi';
import { useLocation } from 'react-router-dom';
import useErrorMessage from '../error/useErrorMessage';
import Page from '../components/Page';

interface UsageMetricsPageProps {}

const UsageMetricsPage: React.FC<UsageMetricsPageProps> = (props) => {
  const [metrics, setMetrics] = useState<OrganizationMetrics | undefined>();

  const { pathname } = useLocation();
  const { t } = useTranslation();
  const refreshData = () => getMetrics().then(setMetrics);
  const { handleError } = useErrorMessage(refreshData);

  useEffect(() => {
    if (pathname === '/usage') {
      getMetrics()
        .then(setMetrics)
        .catch(() => handleError(true));
    }
  }, [pathname]);

  const title = t('title.usage');

  return (
    <Page title={title}>
      <UsageMetricsPanel metrics={metrics} />
    </Page>
  );
};

export default UsageMetricsPage;
