import './ListOwnersGrid.css';
import { deleteLists, List, transferOwnershipOfLists } from '../../listsApi';
import LoadingMessage from '../../../../components/overlays/LoadingMessage';
import { useTranslation } from 'react-i18next';
import { Person } from '../../../people/peopleApi';
import { IonButton, IonItem, IonLabel, IonModal, IonText } from '@ionic/react';
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { FlexGrid, FlexGridColumn } from '@grapecity/wijmo.react.grid';
import { Row } from '@grapecity/wijmo.grid';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { FlexGridSearch } from '@grapecity/wijmo.react.grid.search';
import { CollectionView, PageChangingEventArgs } from '@grapecity/wijmo';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { writeAccessRestrictedState } from '../../../../App';
import useToast from '../../../../hooks/useToast';
import QRCodePreviewModal from '../../../qrCodes/QRCodePreviewModal';
import { qrCodeListsState } from '../../../qrCodes/ListQrCodesToPrint';
import PersonPicker from '../PersonPicker';
import { CollectionViewNavigator } from '@grapecity/wijmo.react.input';
import useErrorMessage from '../../../../error/useErrorMessage';

interface ListOwnersGridProps {
  lists: List[];
  people: Person[];
  isDataFetched: boolean;
  setIsDataFetched: Dispatch<SetStateAction<boolean>>;
  setUpdateGrid: Dispatch<SetStateAction<boolean>>;
  updateGrid: boolean;
}

interface CombinedData {
  ownerId: string | undefined;
  ownerName: string;
  createdOn: Date;
  listId: string;
  listName: string;
  owner: string;
  sharedWith?: string[];
  sharedWithNames: string;
}

const selectedItemsMapIdAndRow = new Map<string, Row>();

// TODO: Refactor to use the new useGraphQlQuery hook
const ListOwnersGrid: React.FC<ListOwnersGridProps> = ({
  lists,
  people,
  isDataFetched,
  setIsDataFetched,
  setUpdateGrid,
  updateGrid,
}) => {
  const isWriteAccessRestricted = useRecoilValue(writeAccessRestrictedState);
  const setListQrCodes = useSetRecoilState(qrCodeListsState);

  const { t } = useTranslation();
  const [combinedData, setCombinedData] = useState<CollectionView<CombinedData> | null>(null);
  const [pageNumber, setPageNumber] = useState(0);
  const [checkedItems, setCheckedItems] = useState<Row[]>([]);
  const gridRef = useRef<FlexGrid>(null);
  const searchRef = useRef<FlexGridSearch>(null);
  const deleteListModalRef = useRef<HTMLIonModalElement | null>(null);
  const [presentToast] = useToast();
  const checkedItemsRef = useRef<Row[]>([]);
  const { handleError } = useErrorMessage();

  useEffect(() => {
    checkedItemsRef.current = checkedItems;
  }, [checkedItems]);

  useEffect(() => {
    if (updateGrid) {
      forceUpdateGrid();
    }
  }, [updateGrid]);

  //search highlighting stops working if we dont pass the gridRef to the array
  useEffect(() => {
    selectedItemsMapIdAndRow.clear();
    if (gridRef.current && searchRef.current) {
      let theGrid = gridRef.current.control;
      let theSearch = searchRef.current.control;
      theSearch.grid = theGrid;
    }
  }, [gridRef.current]);

  useEffect(() => {
    setListQrCodes(
      checkedItems.map(({ dataItem }) => {
        const { listId, listName } = dataItem as CombinedData;
        return {
          listId,
          listName,
        };
      })
    );
  }, [checkedItems]);

  const forceUpdateGrid = () => {
    setIsDataFetched(false);
    setUpdateGrid(!updateGrid);
  };

  //From testing it seems that setting your state for anything wijmo related is best to do in the initialized prop on flexGrid
  const initGrid = (grid: any) => {
    console.log('initGrid called');
    const combinedDataLocal = combineListsAndOwners();

    setCombinedData(
      new CollectionView(combinedDataLocal, {
        trackChanges: true,
        pageSize: 50,
        pageChanged: (cv: CollectionView<CombinedData>, { newPageIndex }: PageChangingEventArgs) => {
          if (pageNumber !== newPageIndex) setPageNumber(newPageIndex);
        },
      })
    );

    if (!isWriteAccessRestricted) {
      new Selector(grid, {
        itemChecked: (cv: any, e: any) => {
          const selectedRows = grid.rows.filter((row: Row) => row.isSelected);
          const uncheckedRows = grid.rows.filter((row: Row) => !row.isSelected);

          selectedRows.forEach((row: Row) => {
            selectedItemsMapIdAndRow.set((row.dataItem as CombinedData).listId, row);
          });
          uncheckedRows.forEach((row: Row) => {
            selectedItemsMapIdAndRow.delete((row.dataItem as CombinedData).listId);
          });

          setCheckedItems(Array.from(selectedItemsMapIdAndRow.values()));
        },
      });
    }
  };

  const combineListsAndOwners = (): CombinedData[] => {
    const shareWithUserNames = (shareWithIds: string[] | undefined) => {
      if (!shareWithIds) return '';
      const arrWithNames = shareWithIds.map((userId) => {
        const match = people.find((person) => person.userId === userId);
        if (!match) return '';
        const name = `${match.firstName} ${match.lastName}`;
        return name;
      });
      return arrWithNames.join(', ');
    };

    const data = lists.map<CombinedData>((list) => {
      const owner: Person | undefined = people.find((person) => person.userId === list.owner);
      const formatedDate = new Date(Date.parse(list.createdOn));
      const combined: CombinedData = {
        ...list,
        ownerId: owner?.userId,
        ownerName: owner ? `${owner.firstName} ${owner.lastName}` : '',
        sharedWithNames: shareWithUserNames(list.sharedWith),
        createdOn: formatedDate,
      };
      return combined;
    });
    return data;
  };

  const updateListOwner = (newOwnerId: string) => {
    const listsToTransfer = checkedItems.map((row) => {
      return { listId: (row.dataItem as CombinedData).listId, ownerId: newOwnerId };
    });
    transferOwnershipOfLists(listsToTransfer)
      .then(() => {
        setCheckedItems([]);
        setIsDataFetched(false);
        setUpdateGrid((prevState) => !prevState);
        setTimeout(() => {
          presentToast('Transfer successful', 1800, 'bottom', 'dark');
        }, 1000);
      })
      .catch(() => {
        console.error('Failed to transfer ownership of lists');
        handleError(true);
      });
  };

  const removeLists = () => {
    const listsToDelete = checkedItems.map((row) => {
      return (row.dataItem as CombinedData).listId;
    });
    deleteLists(listsToDelete).then(() => {
      setCheckedItems([]);
      setIsDataFetched(false);
      setUpdateGrid((prevState) => !prevState);
      deleteListModalRef.current?.dismiss();
      setTimeout(() => {
        presentToast('Lists were successfully removed', 1800, 'bottom', 'dark');
      }, 1000);
    });
  };

  const updateRowSelection = (cv: any) => {
    const rows = cv.rows;
    rows.forEach((row: Row) => {
      const isChecked = checkedItemsRef.current.some((item) => item.dataItem.listId === row.dataItem.listId);
      row.isSelected = isChecked;
    });
  };

  // Return loading message if data is not fetched
  if (!isDataFetched) return <LoadingMessage />;

  return (
    <>
      {/* Grid Header | Action Menu */}
      <div className="d-flex ion-justify-content-between ion-align-items-center">
        {/* Grid Searchbar */}
        <FlexGridSearch ref={searchRef} placeholder="Search" />

        {/* Action Menu */}
        <div
          style={{ gap: '1rem' }}
          className={`d-flex ion-padding-vertical ion-justify-content-between ion-align-items-center ${
            isWriteAccessRestricted || checkedItems.length === 0 ? 'disabled' : ''
          }`}
        >
          <IonLabel class="font-32 ">{checkedItems.length ?? '0'} Selected</IonLabel>

          {/* Change Owner */}
          {/* TODO: Remove after the the new action button is done */}
          <IonItem lines="none">
            <PersonPicker people={people} setResult={updateListOwner} placeHolderText={t('placeholder.changeOwner')} />
          </IonItem>

          {/* QR Codes */}
          {/* TODO: Remove after the the new action button is done */}
          <div>
            <IonLabel class="font-16 cursor-pointer" color="primary" id="open-qr-codes">
              QR CODES
            </IonLabel>
            <QRCodePreviewModal />
          </div>

          {/*TODO: List Owner New Action Button WIP */}
          {/* <ListOwnersGridActionButton people={people} lists={checkedItems.map((row) => row.dataItem)} /> */}

          {/* Delete Lists */}
          <div>
            <IonLabel class="font-16 cursor-pointer" color="danger" id="delete-lists">
              DELETE LISTS
            </IonLabel>
            <IonModal ref={deleteListModalRef} trigger="delete-lists">
              <div className="ion-padding d-flex flex-direction-column ion-justify-content-between">
                <IonLabel class="font-28 ion-margin-bottom">
                  Delete {checkedItems.length} {checkedItems.length > 1 ? 'Lists' : 'List'}
                </IonLabel>
                <IonText class="ion-margin-bottom">These lists will no longer exist. There is no undo.</IonText>
                <div className="d-flex ion-justify-content-around ion-align-items-center">
                  <IonText color="danger" class="ion-margin-end cursor-pointer" onClick={removeLists}>
                    YES, delete these lists.
                  </IonText>
                  <IonButton onClick={() => deleteListModalRef.current?.dismiss()}>NO, Keep these lists.</IonButton>
                </div>
              </div>
            </IonModal>
          </div>
        </div>
      </div>

      {/* Grid Container */}
      <div className="container-fluid">
        {/* Wijmo Grid */}
        <FlexGrid
          className="ion-margin-bottom"
          itemsSource={combinedData}
          ref={gridRef}
          autoGenerateColumns={false}
          allowSorting={true}
          initialized={(grid: any) => {
            initGrid(grid);
          }}
          loadedRows={updateRowSelection}
        >
          {/* List Name */}
          <FlexGridColumn header="List" binding="listName" width="*" isReadOnly={true}></FlexGridColumn>
          {/*  Owner Name*/}
          <FlexGridColumn header="Owner" binding="ownerName" width="*" isReadOnly={true}></FlexGridColumn>
          {/* Shared With */}
          <FlexGridColumn header="Shared With" binding="sharedWithNames" width="*" isReadOnly={true}></FlexGridColumn>
          {/* Created on */}
          <FlexGridColumn
            header="Created On"
            binding="createdOn"
            width="*"
            isReadOnly={true}
            dataType={'Date'}
            format="M/d/yyyy hh:mm tt"
          ></FlexGridColumn>
        </FlexGrid>

        {/* Pagination Nav */}
        <div className="d-flex ion-justify-content-center">
          <CollectionViewNavigator
            headerFormat="Page {currentPage:n0} of {pageCount:n0}"
            byPage={true}
            cv={combinedData}
          />
        </div>
      </div>
    </>
  );
};
export default ListOwnersGrid;
