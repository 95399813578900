import {
  IonButton,
  IonFooter,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonNote,
  IonSelect,
  IonSelectOption,
  IonTitle,
} from '@ionic/react';
import { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Language } from '../../../../constants/constants';
import { PutPersonInput } from '../../peopleApi';
import '../../../../theme/formClasses.css';
import { closeOutline } from 'ionicons/icons';

interface AddPersonDialogProps {
  dismiss: () => void;
  savePerson: (updatedPerson: PutPersonInput[]) => void;
}

const AddPersonDialog: React.FC<AddPersonDialogProps> = ({ dismiss, savePerson }) => {
  const { t } = useTranslation();
  const [firstName, setFirstName] = useState<any>();
  const [lastName, setLastName] = useState<any>();
  const [email, setEmail] = useState<any>();
  const [language, setLanguage] = useState<Language>(Language.ENGLISH);

  const handleSubmit = (event: MouseEvent | KeyboardEvent | FormEvent | CustomEvent<FocusEvent>) => {
    // event.preventDefault();
    save();
  };

  const save = () => {
    const addPerson: PutPersonInput[] = [
      {
        firstName,
        lastName,
        email,
        language,
      },
    ];
    savePerson(addPerson);
    dismiss();
  };

  return (
    <div className="ion-padding">
      <IonHeader class="ion-no-border d-flex ion-justify-content-between">
        <IonTitle>Add Person</IonTitle>
        <IonIcon class="clickable ion-padding-end" icon={closeOutline} size="large" onClick={dismiss} />
      </IonHeader>
      <div>
        <form onSubmit={handleSubmit} className="custom-form">
          <IonItem lines="none">
            <IonLabel position="stacked">{t('label.user.firstName')}</IonLabel>
            <IonInput
              inputmode="text"
              value={firstName}
              placeholder={t('placeholder.firstName')}
              autocomplete="given-name"
              onIonChange={(event) => setFirstName(event.detail.value ?? undefined)}
              required
            />
            <IonNote slot="error">First name is required</IonNote>
          </IonItem>

          <IonItem lines="none">
            <IonLabel position="stacked">{t('label.user.lastName')}</IonLabel>
            <IonInput
              inputmode="text"
              value={lastName}
              placeholder={t('placeholder.lastName')}
              autocomplete="family-name"
              onIonChange={(event) => setLastName(event.detail.value ?? undefined)}
              required
            />
            <IonNote slot="error">Last name is required</IonNote>
          </IonItem>

          <IonItem lines="none" className={`${email?.length > 1 ? '' : 'ion-invalid'}`}>
            <IonLabel position="stacked">{t('label.user.email')}</IonLabel>
            <IonInput
              inputmode="email"
              type="email"
              value={email}
              placeholder={t('placeholder.email')}
              autocomplete="email"
              onIonChange={(event) => setEmail(event.detail.value ?? '')}
              required
            />
            {/*<IonNote slot="error">Invalid email</IonNote>*/}
          </IonItem>

          <div>
            <IonItem lines="none">
              <IonLabel position="stacked">{t('label.user.language')}</IonLabel>
              <IonSelect value={language} onIonChange={(event) => setLanguage(event.detail.value)}>
                <IonSelectOption value={Language.ENGLISH}>{t(`language.name.${Language.ENGLISH}`)}</IonSelectOption>
                <IonSelectOption value={Language.ESPAÑOL}>{t(`language.name.${Language.ESPAÑOL}`)}</IonSelectOption>
              </IonSelect>
            </IonItem>
          </div>
        </form>
      </div>
      <IonFooter class="ion-no-border">
        <div className="d-flex ion-justify-content-end ion-padding">
          <IonButton onClick={save}>Add</IonButton>
        </div>
      </IonFooter>
    </div>
  );
};

export default AddPersonDialog;
