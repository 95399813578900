import '../../theme/overlayClasses.css';
import { v4 as uuid } from 'uuid';
import { IonContent, IonIcon, IonPopover } from '@ionic/react';
import { informationCircleOutline } from 'ionicons/icons';

interface TooltipProps {
  tooltip: string | JSX.Element;
  tooltipId?: string;
}

const Tooltip: React.FC<TooltipProps> = ({ tooltip, tooltipId }) => {
  const finalTooltipId = tooltipId || uuid();

  return (
    <>
      <IonIcon id={finalTooltipId} color="light" className="font-24 clickable" icon={informationCircleOutline} />
      <IonPopover trigger={finalTooltipId} className="tooltip-popover">
        <IonContent>
          <div style={{ padding: '0.5rem 1rem' }}>{tooltip}</div>
        </IonContent>
      </IonPopover>
    </>
  );
};

export default Tooltip;
