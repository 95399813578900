import { IonContent, IonItem, IonLabel, IonList, IonModal, IonText } from '@ionic/react';
import React, { useRef } from 'react';

import './userMenu.css';
import { submitUserDeletionRequest } from '../../../people/peopleApi';

interface DeleteAccountProps {
  trigger: string;
}

const DeleteAccount: React.FC<DeleteAccountProps> = ({ trigger }) => {
  const deleteAccountRef = useRef<HTMLIonModalElement>(null);

  const closeModal = async () => {
    await deleteAccountRef.current?.dismiss();
  };

  const submitRequestDelete = async () => {
    console.log('User chose deletion.');
    await submitUserDeletionRequest();
    closeModal();
  };

  return (
    <IonModal show-backdrop={true} trigger={trigger} ref={deleteAccountRef} class="delete-account-modal">
      <div className="wrapper ion-padding">
        <IonItem lines="none">
          <IonText>
            <h2>Deleting your account...</h2>
          </IonText>
        </IonItem>
        <IonLabel>If you Delete your account, you will no longer be able to:</IonLabel>
        <IonList class="ion-padding">
          <IonItem lines="none" class="no-min-height">
            &bull; Log in to the account
          </IonItem>
          <IonItem lines="none" class="no-min-height">
            &bull; Access your Lists and Tasks
          </IonItem>
          <IonItem lines="none" class="no-min-height">
            &bull; Use the Otuvy Frontline app
          </IonItem>
        </IonList>
        <IonLabel>
          If you’d like to delete your account, press the “Yes, Delete My Account” button below. This will email your
          admin and our support staff.
        </IonLabel>
        <div className="d-flex flex-direction-column ion-align-items-end ion-margin-top">
          <IonLabel color="primary" class="ion-margin-vertical cursor-pointer" onClick={closeModal}>
            No, Keep My Account
          </IonLabel>
          <IonLabel color="error" class="ion-margin-vertical font-12" onClick={submitRequestDelete}>
            Yes, delete my account
          </IonLabel>
        </div>
      </div>
    </IonModal>
  );
};

export default DeleteAccount;
